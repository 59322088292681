import React, { useEffect } from "react";
import "./App.css";
import Dashboard from "./Pages/Dashboard/Dashboard";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Biodata from "./Pages/Biodata/Biodata";
import Login from "./Pages/Auth/Login";
import Incharge from "./Pages/Biodata/Incharge/Incharge";
import GuestBiodata from "./Pages/Biodata/Guest/GuestBiodata";
import Salary from "./Pages/salary/Salary";
import { useDispatch } from "react-redux";
import User from "./Pages/user/User";
import Pf from "./Pages/PF/Pf";
import Edituser from "./Pages/user/Edituser";
import Adduser from "./Pages/user/Adduser";
import axios from "axios";
import Price from "./Pages/Price/Price";
import Plant from "./Pages/Plant/Plant";
import Work from "./Pages/Work/Work";
import Indiwork from "./Pages/Indiviual/Indiwork";
import InchargeEdituser from "./Pages/Biodata/Incharge/InchargeEdituser";
import Biodataedit from "./Pages/Biodata/Biodataedit";
import GuestEdit from "./Pages/Biodata/Guest/GuestEdit";
import Salaredit from "./Pages/salary/Salaredit";
import Workattend from "./Pages/Nonpfuser/Work_attend";
import Attendence from "./Pages/Attendence/Attendence";
import Workbank from "./Pages/Wbankstatement/Work_Bank";
import Addemployee from "./Pages/Biodata/Addemployee";
import Permission from "./Pages/Permission/Permission";
import Comparison from "./Pages/Attendence/Comparison";

function App() {
  const dispatch = useDispatch();

  const getuser = async (token) => {
    const request = await axios
      .get(
        `https://saizoous.com/zoousbackend/Auth/isLoggedin.php?token=${token}`
      )
      .then((result) => {
        console.log(result.data);
        dispatch({
          type: "user",
          payload: result.data.data,
        });
        // const employees = result.data.employee.filter(
        //   (state) => state.section_name.toUpperCase() === "COMPANY GRADING"
        // );
        dispatch({
          type: "allemployee",
          payload: result.data.employee,
        });
        // dispatch({
        //   type: "attendence",
        //   payload: result.data.attendance,
        // });
        dispatch({
          type: "sections",
          payload: result.data.sections,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    return request;
  };

  const getplants = async () => {
    const request = await axios
      .get("https://saizoous.com/zoousbackend/plant/plants.php")
      .then((result) => {
        dispatch({
          type: "plants",
          payload: result.data.data,
        });
      });
    return request;
  };

  useEffect(() => {
    let token = localStorage.getItem("jwt");
    getplants();
    getuser(token);
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path="/:plant/adduser/"
          element={
            <div className="App">
              <Adduser />
            </div>
          }
        />
        <Route
          path="/:plant/user/:userid"
          element={
            <div className="App">
              <Edituser />
            </div>
          }
        />
        <Route
          path="/:plant/adduser/"
          element={
            <div className="App">
              <Adduser />
            </div>
          }
        />
        <Route
          path="/:plant/Work&BankStatement/"
          element={
            <div className="App">
              <Work />
            </div>
          }
        />
        <Route
          path="/:plant "
          element={
            <div className="App">
              <Indiwork />
            </div>
          }
        />
        <Route
          path="/:plant/indiviualwork"
          element={
            <div className="App">
              <Workbank />
            </div>
          }
        />
        <Route
          path="/:plant/plant"
          element={
            <div className="App">
              <Plant />
            </div>
          }
        />
        <Route
          path="/:plant/price"
          element={
            <div className="App">
              <Price />
            </div>
          }
        />
        <Route
          path="/:plant/user"
          element={
            <div className="App">
              <User />
            </div>
          }
        />
        <Route
          path="/:plant/employee/salary"
          element={
            <div className="App">
              <Salary />
            </div>
          }
        />
        <Route
          path="/:plant/employee/salary/edit/:userid"
          element={
            <div className="App">
              <Salaredit />
            </div>
          }
        />
        <Route
          path="/:plant/employee/pf"
          element={
            <div className="App">
              <Pf />
            </div>
          }
        />
        <Route
          path="/:userid/permissions"
          element={
            <div className="App">
              <Permission />
            </div>
          }
        />

        <Route
          path="/:plant/Biodata/Guest"
          element={
            <div className="App">
              <GuestBiodata />
            </div>
          }
        />
        <Route
          path="/:plant/Biodata/guest/edituser/:userid"
          element={
            <div className="App">
              <GuestEdit />
            </div>
          }
        />
        <Route
          path="/:plant/Biodata/incharge/edituser/:userid"
          element={
            <div className="App">
              <InchargeEdituser />
            </div>
          }
        />
        <Route
          path="/:plant/Biodata/incharge"
          element={
            <div className="App">
              <Incharge />
            </div>
          }
        />
        <Route
          path="/:plant/Biodata/editbio/:userid"
          element={
            <div className="App">
              <Biodataedit />
            </div>
          }
        />
        <Route
          path="/:plant/nonpfuser/"
          element={
            <div className="App">
              <Workattend />
            </div>
          }
        />

        <Route
          path="/:plant/Attendence/"
          element={
            <div className="App">
              <Attendence />
            </div>
          }
        />
        <Route
          path="/:plant/Biodata/"
          element={
            <div className="App">
              <Biodata />
            </div>
          }
        />
        <Route
          path="/:plant/Biodata/addemployee"
          element={
            <div className="App">
              <Addemployee />
            </div>
          }
        />
        <Route
          path="/:plant/attendance/comparsion/:date"
          element={
            <div className="App">
              <Comparison />
            </div>
          }
        />
        <Route
          path="/"
          element={
            <div className="App">
              <Login />
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
