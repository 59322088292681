import React, { useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { useSelector } from "react-redux";
import Logo from "../../assets/image/SAI.png";
import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router";
import {
  handleforminputs,
  handlepermission,
  handleppermission,
} from "./handlepermission";
import Userplant from "./Userplant";

function Edituser() {
  const { userid } = useParams();
  const user = useSelector((state) => state.user);
  const plants = useSelector((state) => state.plants);
  const [users, setUsers] = useState("");
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [useremail, setUseremail] = useState("");
  const [userpassword, setUserpassword] = useState("");
  const [userphone, setUserphone] = useState("");
  const [employeeid, setEmployeeid] = useState("");
  const [modules, setModules] = useState("");
  const [plantaccess, setPlantaccess] = useState("");
  const [plant, setPlant] = useState("");
  const [employeedata, setEmployeedata] = useState(false);
  const [inchargedata, setInchargedata] = useState(false);
  const [guestdata, setGuestdata] = useState(false);
  const [giveattend, setGiveattend] = useState(false);
  const [attend_report, setAttend_report] = useState(false);
  const [workbank, setWorkbank] = useState(false);
  const [indiworkreport, setIndiworkreport] = useState(false);
  const [pf, setPf] = useState(false);
  const [salary, setSalary] = useState(false);
  const [adduser, setAdduser] = useState(false);
  const [searchuser, setSearchuser] = useState(false);
  const [addprice, setAddprice] = useState(false);
  const [addplant, setAddplant] = useState(false);
  const [nonpf, setNonpf] = useState(false);
  const [access, setAccess] = useState("");

  // for plant

  const handlesubmit = async () => {
    const menupermision = await handlepermission();
    const plantpermision = await handleppermission();
    let roleselection = document.getElementById("roleselection");
    let plantselection = document.getElementById("plantselection");

    console.log(plantpermision.join(","));
    if (roleselection.value) {
      const form = new FormData();
      form.append("username", username);
      form.append("email", useremail);
      form.append("id", employeeid);
      form.append("phone", userphone);
      form.append("role", roleselection.value);
      form.append("password", userpassword);
      form.append("plant", plantselection.value);
      form.append("plant_access", plantpermision.join(","));
      form.append("changedBy", user.Email);
      form.append("category", "useradding");
      form.append("detail", `trying to add ${username}`);
      form.append("menu_permission", menupermision.join(","));

      const request = await axios
        .post("https://saizoous.com/zoousbackend/user/adduser.php", form)
        .then(() => {
          window.location.assign("/mudhivarthi/user");
        });

      console.log(request);
    }
  };

  const handleincharge = async () => {
    const request = await axios
      .post("https://saizoous.com/zoousbackend/user/user.php")
      .then((request) => {
        console.log(request);
        const filteruser = request.data.data.filter(
          (state) => state.id == userid
        )[0];
        console.log(filteruser);
        setUsername(filteruser.Name);
        setName(filteruser.Name.split(" ")[0]);
        // setLastname(filteruser.Name.split(" ")[1]);
        setUseremail(filteruser.Email);
        // setUserphone(filteruser.phone);
        setEmployeeid(filteruser.id);
        setUserpassword(filteruser.password);
        setModules(filteruser.menu_access);
        setPlant(filteruser.plant);
        setAccess(filteruser.role);
        setPlantaccess(filteruser.plant_access);
        setEmployeedata(filteruser.menu_access.includes("emp_data"));
        setGuestdata(filteruser.menu_access.includes("guest_data"));
        setInchargedata(filteruser.menu_access.includes("inc_data"));
        setGiveattend(filteruser.menu_access.includes("give_att"));
        setAttend_report(filteruser.menu_access.includes("report_att"));
        setWorkbank(filteruser.menu_access.includes("workbank"));
        setSalary(filteruser.menu_access.includes("salary_menu"));
        setIndiworkreport(filteruser.menu_access.includes("report_indwork"));
        setPf(filteruser.menu_access.includes("pf_menu"));
        setAdduser(filteruser.menu_access.includes("adduser"));
        setSearchuser(filteruser.menu_access.includes("searchuser"));
        setAddprice(filteruser.menu_access.includes("addprice"));
        setPf(filteruser.menu_access.includes("pf"));
        setAdduser(filteruser.menu_access.includes("adduser"));
        setSearchuser(filteruser.menu_access.includes("searchuser"));
        setAddplant(filteruser.menu_access.includes("addplant"));
        setNonpf(filteruser.menu_access.includes("nonpf"));
        setUsers(request.data.data);
      });
  };

  useEffect(() => {
    handleincharge();
  }, []);

  return (
    <div
      className="py-2 min-h-screen flex items-center flex-col"
      style={{
        maxWidth: "100vw",
        zIndex: "100000",
        backgroundColor: "white",
      }}
    >
      <div className="flex items-center h-10">
        <div
          style={{
            width: "80px",
            height: "35px",
          }}
        >
          <img
            src={Logo}
            alt="logo"
            className="font-semibold w-full object-contain h-full"
          />
        </div>
        <h2 className="font-semibold font-[Inter]">ZOOUS Solution</h2>
      </div>
      <div className="w-1/2 h-10 bg-white my-4">
        <div className=" text-2xl font-[Noto_Sans]">Edit user</div>
        <div className="border-b border-solid border-black/20 pb-3 pt-1">
          Mange your users here.
        </div>
        <div className="py-3 px-2">
          <div className="font-semibold text-xs font-[Inter] ">ID Number</div>
          <input
            className="my-2 w-full border border-solid border-black/30 outline-none px-2 rounded"
            style={{
              height: "35px",
            }}
            type="text"
            value={employeeid}
            onChange={(e) => {
              setEmployeeid(e.target.value);
            }}
          />
          <div className="font-semibold text-xs font-[Inter] ">First Name</div>
          <input
            className="my-2 w-full border border-solid border-black/30 outline-none px-2 rounded"
            style={{
              height: "35px",
            }}
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          {/* <div className="font-semibold text-xs font-[Inter] ">Last Name</div>
          <input
            className="my-2 w-full border border-solid border-black/30 outline-none px-2 rounded"
            style={{
              height: "35px",
            }}
            type="text"
            value={lastname}
            onChange={(e) => {
              setLastname(e.target.value);
            }}
          /> */}
          <div className="font-semibold text-xs font-[Inter] ">Email</div>
          <input
            className="my-2 w-full border border-solid border-black/30 outline-none px-2 rounded"
            style={{
              height: "35px",
            }}
            type="text"
            value={useremail}
            onChange={(e) => {
              setUseremail(e.target.value);
            }}
          />
          <div className="font-semibold text-xs font-[Inter] ">User Name</div>
          <input
            className="my-2 w-full border border-solid border-black/30 outline-none px-2 rounded"
            style={{
              height: "35px",
            }}
            type="text"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />

          <div className="font-semibold text-xs font-[Inter] ">Password</div>
          <input
            className="my-2 w-full border border-solid border-black/30 outline-none px-2 rounded"
            style={{
              height: "35px",
            }}
            type="text"
            value={userpassword}
            onChange={(e) => {
              setUserphone(e.target.value);
            }}
          />
          <div className="w-1/2 mt-2 mb-4">
            <div className="text-sm text-black/90 my-2">Plant</div>
            <select
              id="plantselection"
              className="w-full border-solid border border-black/90 outline-none  rounded-md text-sm px-2"
              style={{
                height: "40px",
              }}
            >
              <option value="">Select Plant</option>
              {/* {plants.map((plant) => ( */}
              <option value="Mudhivarthi">Mudhivarthi</option>
              {/* ))} */}
            </select>
          </div>
          <div className="font-semibold text-xs font-[Inter] ">Role</div>
          <select
            name=""
            value={access}
            id="roleselection"
            className="my-1 w-full py-2 border border-solid border-black/40 px-3 rounded-sm"
          >
            <option value="">Select role type</option>
            <option
              value="Admin"
              onClick={() => {
                setAccess("Admin");
              }}
            >
              Admin
            </option>
            <option
              value="HR admin"
              onClick={() => {
                setAccess("HR admin");
              }}
            >
              HR admin
            </option>
            <option
              value="Employee"
              onClick={() => {
                setAccess("Employee");
              }}
            >
              Employee
            </option>
            <option
              value="Accountant"
              onClick={() => {
                setAccess("Accountant");
              }}
            >
              Accountant
            </option>
          </select>
          <div className="my-2 border-y border-solid border-black/20 py-2">
            <div className="text-sm font-semibold font-[Inter]">
              Permissions
            </div>
            <div className="w-full py-2 my-1 rounded-md relative select-none flex items-center flex-wrap text-xs font-semibold text-black border-black">
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input
                  onClick={() => {
                    setEmployeedata(!employeedata);
                  }}
                  checked={employeedata}
                  type="checkbox"
                  id="ebiodata"
                  className="mr-2 w-4 h-4"
                />
                <label htmlFor="ebiodata">EMPLOYEE BIODATA</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input
                  onClick={() => {
                    setInchargedata(!inchargedata);
                  }}
                  checked={inchargedata}
                  type="checkbox"
                  id="inchargebiodata"
                  className="mr-2 w-4 h-4"
                />
                <label htmlFor="inchargebiodata">INCHARGE BIODATA</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input
                  onClick={() => {
                    setGuestdata(!guestdata);
                  }}
                  checked={guestdata}
                  type="checkbox"
                  id="guestbiodata"
                  className="mr-2 w-4 h-4"
                />
                <label htmlFor="guestbiodata">GUEST BIODATA</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input
                  onClick={() => {
                    setGiveattend(!giveattend);
                  }}
                  checked={giveattend}
                  type="checkbox"
                  id="giveattend"
                  className="mr-2 w-4 h-4"
                />
                <label htmlFor="giveattend">GIVE ATTENDANCE</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input
                  onClick={() => {
                    setAttend_report(!attend_report);
                  }}
                  checked={attend_report}
                  type="checkbox"
                  id="reportattend"
                  className="mr-2 w-4 h-4"
                />
                <label htmlFor="reportattend">ATTENDNACE REPORT</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input type="checkbox" id="salary" className="mr-2 w-4 h-4" />
                <label htmlFor="salary">Salary</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input
                  onClick={() => {
                    setWorkbank(!workbank);
                  }}
                  checked={workbank}
                  type="checkbox"
                  id="WBSTATE"
                  className="mr-2 w-4 h-4"
                />
                <label htmlFor="WBSTATE">WORK & BANK STATEMENT</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input
                  onClick={() => {
                    setIndiworkreport(!indiworkreport);
                  }}
                  checked={indiworkreport}
                  type="checkbox"
                  id="iwreport"
                  className="mr-2 w-4 h-4"
                />
                <label htmlFor="iwreport">INDIVIDUAL WORK REPORT</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input
                  onClick={() => {
                    setPf(!pf);
                  }}
                  checked={pf}
                  type="checkbox"
                  id="pf"
                  className="mr-2 w-4 h-4"
                />
                <label htmlFor="pf">PF</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input
                  onClick={() => {
                    setAdduser(!adduser);
                  }}
                  checked={adduser}
                  type="checkbox"
                  id="adduser"
                  className="mr-2 w-4 h-4"
                />
                <label htmlFor="adduser">ADD USER</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input
                  onClick={() => {
                    setSearchuser(!searchuser);
                  }}
                  checked={searchuser}
                  type="checkbox"
                  id="searchuser"
                  className="mr-2 w-4 h-4"
                />
                <label htmlFor="searchuser">SEARCH USER</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input
                  onClick={() => {
                    setAddprice(!addprice);
                  }}
                  checked={addprice}
                  type="checkbox"
                  id="addprice"
                  className="mr-2 w-4 h-4"
                />
                <label htmlFor="addprice">ADD PRICE</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input
                  onClick={() => {
                    setAddplant(!addplant);
                  }}
                  checked={addplant}
                  type="checkbox"
                  id="addplant"
                  className="mr-2 w-4 h-4"
                />
                <label htmlFor="addplant">ADD PLANT</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input
                  onClick={() => {
                    setNonpf(!nonpf);
                  }}
                  checked={nonpf}
                  type="checkbox"
                  id="npfuser"
                  className="mr-2 w-4 h-4"
                />
                <label htmlFor="npfuser"> NON PF USERS</label>
              </div>
            </div>
          </div>
          <div className="my-2 border-b border-solid border-black/20 py-2">
            <div className="text-sm font-semibold font-[Inter]">
              Plant Permissions
            </div>
            <div className="w-full py-2 my-1 rounded-md relative select-none flex items-center flex-wrap text-xs font-semibold text-black border-black">
              {plants.map((plant) => (
                <Userplant plant={plant} access={plantaccess} />
              ))}
            </div>
            <div className="flex items-center justify-end py-2">
              <button
                className="py-2 px-6 bg-red-500 font-semibold rounded-sm"
                onClick={handlesubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edituser;
