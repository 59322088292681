import React from "react";
import Header from "../Home/Header/Header";

function Work_Bank() {
  const handleclick = (e) => {
    const biodataheader = document.getElementsByClassName("biodata-header");

    for (let i = 0; i < biodataheader.length; i++) {
      biodataheader[i].style.backgroundColor = "white";
      biodataheader[i].style.color = "black";
    }
    e.target.style.backgroundColor = "black";
    e.target.style.color = "white";
  };
  return (
    <div>
      <Header />
      <div className="w-full employeecontainer">
        <div className="employee-data-container">
          <div className="e-d-container-top">
            <div
              className="flex flex-col"
              style={{
                fontFamily: `'Ysabeau', sans-serif`,
              }}
            >
              <h1 className="text-2xl capitalize font-semibold">
                Indiviualwork
              </h1>
              <p
                className="text-sm text-black/0.3"
                style={{
                  fontSize: "14px",
                  fontFamily: `'Ysabeau', sans-serif`,
                }}
              >
                Manage your all indiviualwork.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Work_Bank;
