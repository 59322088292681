import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  AiFillDelete,
  AiOutlineClose,
  AiOutlineEdit,
  AiOutlineSearch,
  AiOutlineUserAdd,
} from "react-icons/ai";
import { motion } from "framer-motion";
import { FiDownloadCloud } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import Header from "../Home/Header/Header";
import Edituser from "./Edituser";
import Usercontainer from "./Usercontainer";
import { useSelector } from "react-redux";
import { Avatar } from "@mui/material";

function User() {
  const [users, setUsers] = useState([]);
  const { plant } = useParams();
  const [shareduser, setShareduser] = useState("");
  const [showdetail, setShowdetail] = useState(false);
  const selecteduser = useSelector((state) => state.selectuser);
  const [filtereduser, setFiltereduser] = useState([]);

  const handlefilter = (text) => {
    const filtereduser = users?.filter(
      (state) =>
        state.id.toLowerCase().startsWith(text) ||
        state.Name?.split(" ")[0]?.toLowerCase()?.startsWith(text) ||
        state.Name?.split(" ")[1]?.toLowerCase()?.startsWith(text) ||
        state.plant.startsWith(text)
    );
    console.log(filtereduser);
    setFiltereduser(filtereduser);
  };

  const handleincharge = async () => {
    const request = await axios.post(
      "https://saizoous.com/zoousbackend/user/user.php"
    );
    console.log(request.data.data);
    setUsers(request.data.data);
  };
  useEffect(() => {
    handleincharge();
  }, []);
  return (
    <>
      <div>
        <Header />
        <div className="w-full employeecontainer">
          <div className="pt-6 px-10 z-50 relative">
            <div className="w-full h-12 bg-white rounded-md">
              <div className=" items-center min-w-36 flex rounded-full">
                <div className="flex w-full">
                  <div className="e-d-container-search w-full flex items-center text-sm capitalize rounded-full relative">
                    <AiOutlineSearch
                      fontSize={20}
                      color="rgb(0,0,0,0.8)"
                      className="mx-3"
                    />
                    <input
                      onChange={(e) => {
                        setShareduser(e.target.value);
                        handlefilter(e.target.value.toLowerCase());
                      }}
                      type="text"
                      placeholder="Search...."
                      style={{
                        width: "100%",
                        marginRight: "8px",
                        height: "100%",
                        padding: "0px 0px",
                        borderRadius: "5px",
                        background: "transparent",
                        outline: "none",
                        border: "none",
                        color: "black",
                      }}
                    />

                    {shareduser && (
                      <>
                        {filtereduser.length ? (
                          <>
                            <div
                              style={{
                                top: "50px",
                                maxHeight: "200px",
                                // overflowY: "scroll",
                              }}
                              className="absolute rounded w-full bg-[white] py-2 px-2 border border-solid border-black/20 shadow-lg"
                            >
                              {filtereduser.map((user) => (
                                <div
                                  onClick={() => {
                                    setShareduser("");
                                    // dispatch({
                                    //   type: "selecteduser",
                                    //   payload: user,
                                    // });
                                    setShowdetail(true);
                                  }}
                                  className="w-full py-2 px-2 hover:bg-black/10 cursor-pointer"
                                >
                                  {user.employee_name}
                                </div>
                              ))}
                            </div>
                          </>
                        ) : (
                          <div
                            className="absolute rounded w-full bg-[white] py-2 px-2 border border-solid border-black/40"
                            style={{
                              top: "35px",
                              maxHeight: "200px",
                              // overflowY: "scroll",
                            }}
                          >
                            <div className="text-xs font-semibold font-[Inter]">
                              user not found
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <Link
                    className="w-44 font-medium font-sans text-base capitalize bg-black text-white flex items-center justify-center"
                    to={`/${plant}/adduser/`}
                  >
                    <div
                      style={{
                        fontFamily: "Inter",
                        fontSize: "12px",
                      }}
                      className="w-full h-full flex items-center justify-center"
                    >
                      Add User
                    </div>
                  </Link>
                  <div
                    // onClick={handledownloadxlsx}
                    className="flex items-center text-sm capitalize  py-1.5 px-3.5 w-52 h-12"
                  >
                    <FiDownloadCloud
                      fontSize={20}
                      color="rgb(0,0,0,0.8)"
                      className="mr-1"
                    />
                    <span
                      style={{
                        margin: "0px 8px",
                        color: "rgb(0,0,0,0.8)",
                        fontFamily: "Inter",
                        fontSize: "11px",
                      }}
                    >
                      Download CSV
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="z-50 relative">
            <div className="flex flex-col items-center w-full min-h-20 py-4 z-50">
              <div
                className="min-h-10 border border-solid border-black rounded-lg"
                style={{
                  width: "95%",
                }}
              >
                <div className="w-full min-h-10  bg-white flex items-center px-4 justify-between font-semibold py-3 rounded-t-lg border-b border-solid border-black">
                  <div
                    className="user-name text-black/90"
                    style={{
                      fontSize: "12px",
                      width: "25%",
                    }}
                  >
                    Name
                  </div>
                  <div
                    className="user-email text-black/90"
                    style={{
                      fontSize: "12px",
                      width: "25%",
                    }}
                  >
                    Email
                  </div>
                  <div
                    className="user-roles text-black/90"
                    style={{
                      fontSize: "12px",
                      width: "25%",
                    }}
                  >
                    Role
                  </div>
                  <div
                    className="user-joindate text-black/80"
                    style={{
                      fontSize: "11px",
                      width: "20%",
                    }}
                  >
                    JOIN DATE
                  </div>

                  <div
                    className="action-button"
                    style={{
                      fontSize: "11px",
                      width: "20%",
                    }}
                  >
                    Action
                  </div>
                </div>
                {users?.map((employee) => (
                  <Usercontainer employee={employee} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default User;
