import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

function Piechart() {
  const data = {
    labels: ["Male", "Female"],
    datasets: [
      {
        label: "# of Votes",
        data: [25, 1],
        backgroundColor: ["#03a9f4", " #f4d03f "],
        borderColor: ["#03a9f4", "#f4d03f "],
        borderWidth: 1,
      },
    ],
  };
  return <Pie data={data} />;
}

export default Piechart;
