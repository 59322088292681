import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiFillEye, AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import { BsFilter } from "react-icons/bs";
import { CiExport } from "react-icons/ci";
import { FiDownloadCloud } from "react-icons/fi";
import { FiMoreHorizontal } from "react-icons/fi";
import Header from "../../Home/Header/Header";
import { Avatar } from "@mui/material";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import exportFromJSON from "export-from-json";

function GuestBiodata() {
  const { plant } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [guest, setGuest] = useState([]);
  const selecteduser = useSelector((state) => state.selectuser);
  const [showdetail, setShowdetail] = useState(false);
  const [shareduser, setShareduser] = useState("");
  const [filtereduser, setFiltereduser] = useState([]);

  const handledownloadxlsx = () => {
    const fileName = "guest_biodata";
    exportFromJSON({ data: guest, fileName, exportType: "xls" });
  };

  const handlefilter = (text) => {
    const filtereduser = guest.filter(
      (state) =>
        state?.id?.startsWith(text) ||
        state?.fname?.startsWith(text) ||
        state?.email?.startsWith(text)
    );
    setFiltereduser(filtereduser);
  };

  const handleclick = (e) => {
    const biodataheader = document.getElementsByClassName("biodata-header");

    for (let i = 0; i < biodataheader.length; i++) {
      biodataheader[i].style.backgroundColor = "white";
      biodataheader[i].style.color = "black";
    }
    e.target.style.backgroundColor = "black";
    e.target.style.color = "white";
  };

  const handleincharge = async () => {
    const request = await axios.post(
      "https://saizoous.com/zoousbackend/employee/guest.php"
    );
    console.log(request);
    setGuest(request.data.data);
  };
  useEffect(() => {
    handleincharge();
  }, []);
  return (
    <div>
      <Header />
      <div className="w-full employeecontainer">
        <div className="employee-data-container">
          <div className="w-full pt-4 pb-2">
            <div className="pb-4 text-4xl">BioData</div>
            <div
              className="border border-solid border-black/40 rounded-md flex items-center mx-3"
              style={{
                maxWidth: "390px",
                height: "35px",
              }}
            >
              <div
                onClick={(e) => {
                  handleclick(e);
                  navigate(`/${plant?.toLowerCase()}/Biodata`);
                }}
                className="biodata-header cursor-pointer w-1/3 text-xs flex items-center justify-center  h-full rounded-md"
              >
                Employee Biodata
              </div>
              <div
                onClick={(e) => {
                  handleclick(e);
                  navigate(`/${plant?.toLowerCase()}/Biodata/Incharge`);
                }}
                className="biodata-header cursor-pointer w-1/3 text-xs flex items-center justify-center h-full rounded-md "
              >
                Incharge Biodata
              </div>
              <div
                onClick={(e) => {
                  handleclick(e);
                  navigate(`/${plant?.toLowerCase()}/Biodata/guest`);
                }}
                className="biodata-header cursor-pointer w-1/3 text-xs flex items-center justify-center h-full rounded-md bg-black text-white"
              >
                Guest Biodata
              </div>
            </div>
          </div>
          <div className="e-d-container-top">
            <div
              className="flex flex-col font-[inter]"
              style={{
                fontFamily: `'Ysabeau', sans-serif`,
              }}
            >
              <h1 className="text-2xl capitalize font-semibold">Guest</h1>
              <p
                className="text-xs text-black/0.3"
                style={{
                  fontSize: "14px",
                  fontFamily: `'Ysabeau', sans-serif`,
                }}
              >
                Manage your Guests.
              </p>
            </div>
            <div className="e-d-container-top-right items-center min-w-36 flex rounded-full">
              <div className="flex w-full px-2 py-1">
                <div
                  className="flex items-center text-sm capitalize  border border-solid border-black/60  rounded-full mx-2 relative"
                  style={{
                    width: "250px",
                  }}
                >
                  <AiOutlineSearch
                    fontSize={20}
                    color="rgb(0,0,0,0.8)"
                    className="mx-2"
                  />
                  <input
                    value={shareduser}
                    onChange={(e) => {
                      setShareduser(e.target.value);
                      handlefilter(e.target.value);
                    }}
                    type="text"
                    placeholder="Search...."
                    style={{
                      width: "100%",
                      marginRight: "8px",
                      height: "100%",
                      padding: "0px 0px",
                      borderRadius: "5px",
                      background: "transparent",
                      outline: "none",
                      border: "none",
                      color: "black",
                    }}
                  />

                  {shareduser && (
                    <>
                      {filtereduser.length ? (
                        <>
                          <div
                            style={{
                              top: "35px",
                              maxHeight: "200px",
                              // overflowY: "scroll",
                            }}
                            className="absolute rounded w-full bg-[white] py-2 px-2 border border-solid border-black/40"
                          >
                            {filtereduser.map((user) => (
                              <div
                                onClick={() => {
                                  console.log(user);
                                  dispatch({
                                    type: "selecteduser",
                                    payload: user,
                                  });
                                  setShowdetail(true);
                                }}
                                className="w-full py-2 px-2 hover:bg-black/10 cursor-pointer"
                              >
                                {user.name}
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <div
                          className="absolute rounded w-full bg-[white] py-2 px-2 border border-solid border-black/40"
                          style={{
                            top: "35px",
                            maxHeight: "200px",
                            // overflowY: "scroll",
                          }}
                        >
                          <div className="text-xs font-semibold font-[Inter]">
                            user not found
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div
                  onClick={handledownloadxlsx}
                  style={{
                    borderWidth: "1.5px",
                  }}
                  className="exportbutton flex items-center text-sm capitalize font-bold  border-solid py-1.5 px-3.5 mx-2 border-black/40 rounded-md"
                >
                  <FiDownloadCloud
                    fontSize={18}
                    color="rgb(0,0,0,0.8)"
                    className="mr-1"
                  />
                  <span
                    style={{
                      margin: "0px 8px",
                      color: "rgb(0,0,0,0.8)",
                      fontFamily: "Inter",
                      fontSize: "12px",
                    }}
                  >
                    Download CSV
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center w-full min-h-20 py-4">
            <div
              className="min-h-10 border border-solid border-black rounded-lg "
              style={{
                width: "95%",
              }}
            >
              <div className="w-full border-b border-solid border-black min-h-10  flex items-center px-4 justify-between font-semibold py-3">
                <div
                  className="user-id"
                  style={{
                    fontSize: "11px",
                    width: "130px",
                  }}
                >
                  ID
                </div>
                <div
                  className="user-name"
                  style={{
                    fontSize: "11px",
                    width: "25%",
                  }}
                >
                  Name
                </div>
                <div
                  className="user-phone"
                  style={{
                    fontSize: "11px",
                    width: "25%",
                  }}
                >
                  Phone Number
                </div>
                <div
                  className="user-entry"
                  style={{
                    fontSize: "11px",
                    width: "25%",
                  }}
                >
                  Entry Date
                </div>
                <div
                  className="user-person"
                  style={{
                    fontSize: "11px",
                    width: "25%",
                  }}
                >
                  Person
                </div>
                <div
                  className="user-punch-in"
                  style={{
                    fontSize: "11px",
                    width: "25%",
                  }}
                >
                  Punch In
                </div>

                <div
                  className="user-punch-out"
                  style={{
                    fontSize: "11px",
                    width: "20%",
                  }}
                >
                  Punch Out
                </div>

                <div
                  style={{
                    fontSize: "11px",
                    width: "20%",
                  }}
                >
                  Action
                </div>
              </div>
              {guest?.map((employee) => (
                <div className="w-full min-h-10 flex border-b border-solid border-black/30 items-center px-4 justify-between font-semibold py-2.5">
                  <div
                    className="user-id"
                    style={{
                      fontSize: "11px",
                      width: "130px",
                    }}
                  >
                    {employee?.id}
                  </div>
                  <div
                    className="user-name"
                    style={{
                      display: "flex",
                      fontSize: "12px",
                      width: "25%",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      src={`http://zoous.com/new/api/images/${employee?.image}`}
                      style={{
                        width: "25px",
                        height: "25px",
                      }}
                    />
                    <span className="mx-1 font-[Inter]">{employee?.name}</span>
                  </div>
                  <div
                    className="user-phone"
                    style={{
                      fontSize: "12px",
                      width: "25%",
                    }}
                  >
                    {employee?.phone}
                  </div>
                  <div
                    className="user-entry"
                    style={{
                      fontSize: "12px",
                      width: "25%",
                    }}
                  >
                    {employee?.date}
                  </div>
                  <div
                    className="user-person"
                    style={{
                      fontSize: "12px",
                      width: "25%",
                    }}
                  >
                    {employee?.person}
                  </div>
                  <div
                    className="user-punch-in"
                    style={{
                      fontSize: "12px",
                      width: "25%",
                    }}
                  >
                    {employee?.pin}
                  </div>

                  <div
                    className="user-punch-out"
                    style={{
                      fontSize: "12px",
                      width: "20%",
                    }}
                  >
                    {employee?.pout}
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      width: "20%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      onClick={() => {
                        console.log(employee);
                        dispatch({
                          type: "selecteduser",
                          payload: employee,
                        });
                        setShowdetail(true);
                      }}
                      className="py-2 px-2 bg-blue-500/10 rounded-md mr-2"
                    >
                      <AiFillEye fontSize={16} color="red" />
                    </div>
                    <Link
                      to={`/${plant}/Biodata/guest/edituser/${employee.id}`}
                    >
                      <div className="py-2 px-2 bg-blue-500/10 rounded-md mr-2">
                        <BiPencil fontSize={16} color="royalblue" />
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            {showdetail && (
              <div
                className="fixed w-screen h-screen top-0 bottom-0 right-0 left-0"
                style={{
                  backgroundColor: "rgb(0,0,0,0.3)",
                  zIndex: "100000",
                }}
              >
                <motion.div
                  initial={{
                    x: 100,
                  }}
                  transition={{ type: "tween", duration: 0.1 }}
                  animate={{
                    x: 0,
                  }}
                  className="absolute right-0 top-0"
                  style={{
                    width: "500px",
                    height: "100vh",
                    overflowY: "scroll",
                    background: "white",
                  }}
                >
                  <div
                    onClick={() => {
                      setShowdetail(false);
                    }}
                    className="py-2 px-4 border-b border-solid border-black/10 sticky bg-white top-0 bg-white"
                  >
                    <AiOutlineClose fontSize={20} />
                  </div>
                  <div className="flex items-center flex-col py-2">
                    <div
                      className="flex items-center flex-col border border-solid border-black/10 p-2"
                      style={{
                        width: "85%",
                      }}
                    >
                      <div className="flex items-center relative justify-start py-2 w-full">
                        <Avatar
                          style={{
                            position: "relative",
                            width: "40px",
                            height: "40px",
                          }}
                          src={`http://zoous.com/new/api/images/f1529edd-98ad-4eb9-ae8a-4ee78fd8d98a248434193.jpg`}
                        />
                        <div className="px-2 capitalize text-sm font-[Inter]">
                          <div className="text-xs">ID : {selecteduser?.id}</div>
                          <div className="font-semibold">
                            {selecteduser?.name}
                          </div>
                        </div>
                      </div>
                      <div className=" border-t border-solid border-black/10 w-full mt-2 flex items-center justify-between py-2 px-3">
                        <div className="text-xs pt-2">
                          <div className="font-semibold">Phone number</div>
                          <div className="mt-1">{selecteduser?.phone}</div>
                        </div>
                        <div className="text-xs  pt-2">
                          <div className="font-semibold">Person</div>
                          <div className="mt-1">{selecteduser?.person}</div>
                        </div>
                        <div className="text-xs  pt-2">
                          <div className="font-semibold">Address</div>
                          <div className="mt-1">{selecteduser?.address}</div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="border-solid border py-3 px-3 border-black/10 my-2"
                      style={{
                        width: "85%",
                      }}
                    >
                      <div className="text-sm py-1 font-semibold font-[Inter] border-b border-solid border-black/10">
                        Status
                      </div>
                      {/* <div className="py-2 text-xs px-2 flex justify-between"></div> */}
                      <div className="py-1 text-xs px-2 flex justify-between">
                        <div className="py-2 text-xs pt-2">
                          <div className="font-semibold">Punch IN</div>
                          <div className="mt-1">{selecteduser?.pin}</div>
                        </div>
                        <div className="text-xs pt-2">
                          <div className="font-semibold">Punch Out</div>
                          <div className="mt-1">{selecteduser?.pout}</div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="border-solid border py-3 px-3 border-black/10 my-2"
                      style={{
                        width: "85%",
                      }}
                    >
                      <div className="text-sm py-1 font-semibold font-[Inter] border-b border-solid border-black/10">
                        IDs
                      </div>
                      {/* <div className="py-2 text-xs px-2 flex justify-between"></div> */}
                      <div className="py-1 w-full text-xs px-2 flex justify-between">
                        <div
                          className="py-2 text-xs pt-2"
                          style={{
                            width: "48%",
                          }}
                        >
                          <div className="font-semibold">Image</div>
                          <img
                            src={`http://zoous.com/new/api/images/${selecteduser?.image}`}
                            alt="Guest_image"
                          />
                        </div>
                        <div
                          className="text-xs pt-2"
                          style={{
                            width: "48%",
                          }}
                        >
                          <div className="font-semibold">ID</div>
                          <img
                            src={`http://zoous.com/new/api/images/${selecteduser?.idpic}`}
                            alt="Guest_image"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="border-solid border py-3 px-3 border-black/10 my-2"
                      style={{
                        width: "85%",
                      }}
                    >
                      <div className="text-sm py-1 font-semibold font-[Inter] border-b border-solid border-black/10">
                        Additional detail
                      </div>
                      {/* <div className="py-2 text-xs px-2 flex justify-between"></div> */}
                      <div className="py-1 w-full text-xs px-2 flex justify-between">
                        <div className="py-2 text-xs pt-2 w-1/3">
                          <div className="font-semibold">Head</div>
                          <div className="mt-1">{selecteduser?.head}</div>
                        </div>
                        <div className="text-xs pt-2 w-1/3">
                          <div className="font-semibold">Code</div>
                          <div className="mt-1">{selecteduser?.code}</div>
                        </div>
                        <div className="text-xs pt-2 w-1/3">
                          <div className="font-semibold">Date</div>
                          <div className="mt-1">{selecteduser?.date}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GuestBiodata;
