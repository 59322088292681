import axios from "axios";
import React, { useState } from "react";
import { BiPencil } from "react-icons/bi";
import { FiMoreHorizontal } from "react-icons/fi";
import { useSelector } from "react-redux";
import Header from "../Home/Header/Header";
import { AiFillDelete, AiOutlinePlus } from "react-icons/ai";
import { Form } from "react-router-dom";
import { IoMdArrowDropdown } from "react-icons/io";
import Plantcontainer from "./Plantcontainer";

function Plant() {
  const plants = useSelector((state) => state.plants);
  const [plant_name, setPlant_name] = useState("");
  const [selectedplant, setSelectedplant] = useState([]);
  const [eplant, setEplant] = useState("");
  const [loading, setLoading] = useState(false);
  const [editpanel, setEditpanel] = useState(false);

  const handledelete = async (id) => {
    const request = await axios
      .get(
        `https://saizoous.com/zoousbackend/plant/Deleteplant.php?delete_id=${id}`
      )
      .then(() => {
        window.location.reload();
      });
    return request;
  };

  const handleupdate = async () => {
    const form = new FormData();
    form.append("plant_name", eplant);
    form.append("id", selectedplant.id);
    const request = await axios
      .post("https://saizoous.com/zoousbackend/plant/add_plant.php")
      .then((result) => {
        window.location.reload();
        setEditpanel(false);
      })
      .catch((err) => {
        setEditpanel(false);
      });
    return request;
  };
  const handleedit = async (plant) => {
    setEplant(plant.plant_name);
    setSelectedplant(plant);
  };

  const handleplantrequest = async () => {
    const form = new FormData();
    form.append("plant_name", plant_name);
    setLoading(true);
    const request = await axios
      .post("https://saizoous.com/zoousbackend/plant/add_plant.php", form)
      .then((result) => {
        console.log(result);
        setLoading(false);
        window.location.reload();
      })
      .catch(() => {
        setLoading(false);
      });
    return request;
  };

  return (
    <div className="w-full bg-[#f7f7f7] min-h-screen">
      {editpanel && (
        <div
          className="fixed flex items-center justify-center"
          style={{
            top: "0",
            bottom: "0",
            zIndex: "1000000",
            left: 0,
            right: 0,
            backgroundColor: "rgb(0,0,0,0.5)",
          }}
        >
          <div
            style={{
              width: "400px",
              minHeight: "150px",
              backgroundColor: "white",
              borderRadius: "20px",
              padding: "20px 30px",
            }}
          >
            <div className="font-[Inter] text-base">Plant</div>
            <input
              className="border border-solid border-black/30 w-full mt-2 rounded-sm outline-none"
              style={{
                height: "40px",
                padding: "0px 8px",
              }}
              type="text"
              value={eplant}
              onChange={(e) => {
                setEplant(e.target.value);
              }}
            />
            <div className="flex rounded-sm items-center justify-end text-xs pt-2">
              <button
                onClick={() => {
                  setEditpanel(false);
                }}
                style={{
                  height: "35px",
                  padding: "5px 25px",
                  marginLeft: "10px",
                }}
              >
                Cancel
              </button>
              <button
                onClick={handleupdate}
                className="flex rounded-sm items-center justify-center text-xs"
                style={{
                  height: "35px",
                  padding: "5px 25px",
                  backgroundColor: "royalblue",
                  marginLeft: "10px",
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
      <Header />
      <div className="flex items-start justify-start px-8 relative employeecontainer">
        <div
          style={{
            zIndex: 1000,
          }}
          className="w-1/2 mt-6 rounded  mx-3"
        >
          <div className="w-full h-12 bg-white rounded-sm flex items-center p-1">
            <input
              type="text"
              name=""
              value={plant_name}
              onChange={(e) => {
                setPlant_name(e.target.value);
              }}
              id=""
              className="px-4 w-full h-full bg-transparent text-black text-sm rounded-md outline-none"
              placeholder="Add plant"
            />
            {loading ? (
              <div className="w-12 h-full  text-sm text-white flex items-center justify-center rounded-sm ">
                <div
                  className="loadingspinner"
                  style={{
                    width: "24px",
                    height: "24px",
                    borderRadius: "50%",
                    border: "3px solid black",
                    borderTop: "3px solid rgb(0,0,0,0.12)",
                  }}
                ></div>
              </div>
            ) : (
              <div
                className="w-36 h-full bg-blue-600 text-sm text-white flex items-center justify-center rounded-sm"
                onClick={handleplantrequest}
              >
                Add plant
              </div>
            )}
          </div>

          <div className="w-full bg-white rounded-md flex items-center justify-center font-[Inter] py-2 px-4 bg-black/10 my-2">
            <div
              className="text-xs font-semibold px-2"
              style={{
                width: "10%",
              }}
            >
              ID
            </div>
            <div
              className="flex items-center justify-start text-xs font-semibold px-2"
              style={{
                width: "50%",
              }}
            >
              Branch Name
            </div>
            <div
              className="flex items-center justify-start text-xs font-semibold px-2"
              style={{
                width: "30%",
              }}
            >
              Action
            </div>
          </div>
          {plants.map((plant, index) => (
            <Plantcontainer
              plant={plant}
              index={index}
              handleedit={handleedit}
              handledelete={handledelete}
              setEditpanel={setEditpanel}
            />
          ))}
        </div>
        {/* {loading ? (
          <div
            style={{
              top: "120px",
            }}
            className="rounded w-1/3 sticky border font-[Inter] border-solid border-black/10 px-4 py-4 mx-3 flex items-center justify-center"
          >
            <div
              className="loadingspinner"
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                border: "6px solid black",
                borderTop: "6px solid rgb(0,0,0,0.12)",
              }}
            ></div>
          </div>
        ) : (
          <div
            style={{
              top: "120px",
            }}
            className="rounded w-1/3 sticky border font-[Inter] border-solid border-black/10 px-4 py-4 mx-3"
          >
            <div>Add Plant</div>
            <div className="flex flex-col text-sm py-2">
              Plant Name
              <div className="my-1 w-full">
                <input
                  value={plant_name}
                  onChange={(e) => {
                    setPlant_name(e.target.value);
                  }}
                  type="text"
                  style={{
                    height: "35px",
                  }}
                  className="w-full rounded px-3 oultine-none border border-solid border-black/10"
                />
              </div>
              <button
                onClick={() => {
                  handleplantrequest();
                }}
                className="w-full py-3 text-white bg-blue-500 rounded text-xs"
              >
                Add plant
              </button>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
}

export default Plant;
