import React, { useEffect, useState } from "react";
import Header from "../Home/Header/Header";
import {
  MdDone,
  MdOutlineArrowDropDown,
  MdOutlineDeleteOutline,
} from "react-icons/md";
import { Avatar } from "@mui/material";
import {
  AiFillDelete,
  AiFillEdit,
  AiFillSetting,
  AiOutlineCheck,
  AiOutlineLogout,
  AiOutlinePlus,
  AiOutlineSearch,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { BsFillSunFill } from "react-icons/bs";
import axios from "axios";
import { useSelector } from "react-redux";

function Permission() {
  const [permissions, setPermissions] = useState([]);
  const employee = useSelector((state) => state.employee);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://saizoous.com/zoousbackend/Permission/Permission.php"
      );
      console.log(response);
      setPermissions(response.data);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  const handle_filter_employee = (id) => {
    console.log(id);
    const emp = employee.filter((state) => state.id == id)[0];
    console.log(emp);
    if (emp) {
      return emp.employee_name;
    }
  };

  const handlegivepermission = async (id) => {
    const form = new FormData();
    form.append("id", id);
    const request = await axios.post(
      "https://saizoous.com/zoousbackend/Permission/Permission.php",
      form
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="w-full">
      <Header />
      <div className="w-full flex items-center flex-col  py-6">
        <div
          className="w-11/12 font-semibold text-lg font-sans pb-5"
          style={{
            fontFamily: `'Rubik', sans-serif`,
          }}
        >
          Request and granted permissions
        </div>

        <div className="w-11/12 rounded-md bg-white border border-black/10">
          <div
            className="w-full"
            style={{
              fontFamily: `'Rubik', sans-serif`,
            }}
          >
            <div className="px-4 py-2  flex items-center justify-between w-full">
              <div className="w-full">
                <div className="flex items-center justify-between px-4 w-full py-2 border-b border-b-black/40">
                  <div className="w-1/5 text-xs">Employee Name</div>
                  <div className="w-1/5 text-xs">Attendance Date</div>
                  <div className="w-1/5 text-xs">Changed By</div>
                  <div className="w-1/5 text-xs text-center">
                    Approved Pending By
                  </div>
                  <div className="w-1/5 text-center text-xs">Status</div>
                </div>
                {permissions.map((permission) => (
                  <div className="flex items-center justify-between px-4 w-full py-3">
                    <div className="w-1/5 text-xs">
                      {handle_filter_employee(permission?.employee_id)}
                    </div>
                    <div className="w-1/5 text-xs">
                      {permission?.attendance_date}
                    </div>
                    <div className="w-1/5 text-xs">{permission?.remarks}</div>
                    <div className="w-1/5 text-xs text-center">
                      {permission?.changedBy}
                    </div>
                    <div className="w-1/5 text-center text-xs flex items-center justify-center">
                      {permission?.status == 1 ? (
                        <div>approved</div>
                      ) : (
                        <div
                          onClick={() => handlegivepermission(permission.id)}
                          className="border border-black rounded-full w-6 h-6 flex items-center justify-center bg-slate-400"
                        >
                          <AiOutlineCheck fontSize={13} />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              {/* <table className="w-full">
                <thead className="py-2">
                  <tr className="border-b border-black/20 ">
                    <th className="text-xs text-slate-600 font-medium py-3">
                      Employee Name
                    </th>
                    <th className="text-xs text-slate-600 font-medium py-3">
                      Attendance Date
                    </th>
                    <th className="text-xs text-slate-600 font-medium py-3">
                      ChangeBy
                    </th>
                    <th className="text-xs text-slate-600 font-medium py-3">
                      Remark
                    </th>
                    <th className="text-xs text-slate-600 font-medium py-3">
                      Approval pending
                    </th>
                    <th className="text-xs text-slate-600 font-medium py-3 w-2">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {permissions.map((permission) => (
                    <tr>
                      <td className="text-xs text-center py-3">
                        {handle_filter_employee(permission?.employee_id)}
                      </td>
                      <td className="text-xs text-center py-2">
                        {permission?.attendance_date}
                      </td>
                      <td className="text-xs text-center py-2">
                        {permission?.changedBy}
                      </td>
                      <td className="text-xs text-center py-2">
                        {permission?.remarks}
                      </td>
                      <td className="text-xs text-center py-3 flex items-center justify-center">
                        <div className="w-20 border border-black py-1 flex items-center rounded-3xl">
                          <Avatar
                            style={{
                              height: 9,
                              width: 9 ,
                            }}
                          />{" "}
                          Admin
                        </div>
                      </td>
                      <td className="text-xs text-center py-2 flex items-center justify-center w-20">
                        {permission?.status == 1 ? (
                          <div>approved</div>
                        ) : (
                          <div className="border border-black rounded-full w-6 h-6 flex items-center justify-center">
                            <AiOutlineCheck fontSize={13} />
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table> */}
            </div>
            {/* {permissions?.map((team) => (
              <div className="border-t px-8 py-4 w-full border-t-black/20 flex items-center justify-between">
                <div className="flex items-center w-2/5">
                  <Avatar
                    src={team?.UserName}
                    alt={team?.UserName}
                    style={{
                      width: 35,
                      height: 35,
                      fontSize: 12,
                    }}
                  />
                  <div className="mx-2 capitalize text-sm">
                    {team?.UserName}
                    <div className="text-xs">{team?.UserEmail}</div>
                  </div>
                </div>
                <div className="text-sm w-2/5">{team?.ChangedBy}</div>
                <div className="text-sm w-1/5">{team?.Category}</div>
                <div className="text-sm w-1/5">{team?.Detail}</div>
                <div className="flex items-center w-1/5 justify-center">
                  <div className="p-1.5 mx-1 border border-black/70">
                    <MdDone className="text-blue-500" fontSize={21} />
                  </div>
                </div>
              </div>
            ))} */}
          </div>
        </div>
      </div>
    </div>
    // <div className="w-full">
    //   <Header />
    //   <div className="w-full flex justify-center py-8">
    //     <div className="shadow-lg w-2/3 rounded-md bg-white border border-black/10">
    //       <div
    //         style={{
    //           fontFamily: `'Rubik', sans-serif`,
    //         }}
    //         className="px-4 flex w-full justify-between items-center py-4 "
    //       >
    //         <div className="text-sm">Team Member</div>
    //         <div
    //           className="flex items-center px-4"
    //           style={{
    //             fontFamily: `'Rubik', sans-serif`,
    //           }}
    //         >
    //           <AiOutlinePlus className="text-blue-800" />{" "}
    //           <span className="mx-2 font-medium text-xs text-blue-700">
    //             Add user
    //           </span>
    //         </div>
    //       </div>
    //       <div
    //         className="w-full"
    //         style={{
    //           fontFamily: `'Rubik', sans-serif`,
    //         }}
    //       >
    //         <div className="px-10 py-2 w-full border-t border-t-black/20 flex items-center justify-between">
    //           <div className="text-sm w-2/4">User</div>
    //           <div className="text-sm w-1/4">Role</div>
    //           <div className="w-1/4"></div>
    //         </div>
    //         {Team.map((team) => (
    //           <div className="border-t px-8 py-4 w-full border-t-black/20 flex items-center justify-between">
    //             <div className="flex items-center w-2/4">
    //               <Avatar
    //                 src={team.name}
    //                 alt={team.name}
    //                 style={{
    //                   width: 35,
    //                   height: 35,
    //                   fontSize: 12,
    //                 }}
    //               />
    //               <div className="mx-2 capitalize text-sm">
    //                 {team.name}
    //                 <div className="text-xs">{team.email}</div>
    //               </div>
    //             </div>
    //             <div className="text-sm w-1/4">{team.role}</div>
    //             <div className="flex items-center w-1/4 justify-center">
    //               <div className="p-1 mx-1">
    //                 <MdOutlineDeleteOutline fontSize={18} className="text-red-400" />
    //               </div>
    //               <div className="p-1 mx-1">
    //                 <AiFillEdit className="text-blue-500" fontSize={18}/>
    //               </div>
    //             </div>
    //           </div>
    //         ))}
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default Permission;
