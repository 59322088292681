import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

// import pdfUrl from "./VedicReport12-07-202314-50-42.pdf";

function Comparison() {
  // console.log(pdfUrl);
  const [pdf, setPdf] = useState(null);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  // useEffect(() => {
  //   fetch(pdfUrl)
  //     .then((response) => response.blob())
  //     .then((pdfBlob) => {
  //       console.log(pdfBlob);
  //       setPdf(pdfBlob);
  //     });
  // }, []);

  return (
    <div className="w-full h-full bg-black">
      <div className="w-full flex items-center py-3 px-4 justify-end">
        <AiOutlineClose color="white" fontSize={21} />
      </div>
      <div className=" bg-white w-full h-full rounded-t-md flex ">
        <div className="w-1/2 border-r border-r-black/30 rounded-l-md bg-transparent py-3 px-4">
          <div className="flex items-center justify-between">
            <div></div>
            <div className="text-sm uppercase text-center">
              Worker Daily sheet
            </div>
            <div>
              <div className="text-xs">Date: 07/11/2022 </div>
            </div>
          </div>
          <div className="w-full min-h-0 overflow-x-auto py-5">
            <div className="w-full py-1 flex items-center border-y border-black/30">
              <div
                className="text-xs px-2"
                style={{
                  width: 50,
                }}
              >
                S No.
              </div>
              <div
                className="text-xs px-2"
                style={{
                  width: 80,
                }}
              >
                Roll / ID No
              </div>
              <div
                className="text-xs px-2"
                style={{
                  width: 80,
                }}
              >
                Name
              </div>
              <div
                className="text-xs text-center px-2"
                style={{
                  width: "60%",
                }}
              >
                shift
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/2 bg-black/90 h-screen">
          {/* <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document> */}
        </div>
      </div>
    </div>
  );
}

export default Comparison;
