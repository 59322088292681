import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../Home/Header/Header";
import "./Price.css";

function Price() {
  const plants = useSelector((state) => state.plants);
  const [prices, setPrices] = useState([]);
  const [price, setPrice] = useState("");

  const handlesubmit = async () => {
    let grade = document.getElementById("getgrade").value;
    let getbranch = document.getElementById("getbranch").value;

    if (grade || getbranch) {
      const form = new FormData();
      form.append("grade", grade);
      form.append("branch", getbranch);
      form.append("price", price);
      const request = await axios
        .post("https://saizoous.com/zoousbackend/Price/Addprice.php", form)
        .then(() => {
          window.location.reload();
        })
        .catch(() => {
          window.location.reload();
        });
      return request;
    }
  };

  const getprices = async () => {
    const request = await axios
      .get("https://saizoous.com/zoousbackend/Price/Prices.php")
      .then((result) => {
        console.log(result.data.data);
        setPrices(result.data.data);
      });
    return request;
  };
  useEffect(() => {
    getprices();
  }, []);
  return (
    <div>
      <Header />
      <div className="pt-6 pb-3 w-full flex items-start justify-start px-6 relative price-container">
        <div className=" items-center justify-end mb-2 w-full max-md:flex hidden">
          <button className="bg-red-500 py-2 px-6 text-xs font-semibold rounded-md text-white">
            Add plant
          </button>
        </div>
        <div className="w-2/3 mx-3 border border-solid border-black rounded price-c-container">
          <div className="w-full flex items-center justify-center font-[Inter] py-2.5 px-2 border-b border-solid border-black">
            <div
              className="text-xs font-semibold px-2"
              style={{
                width: "10%",
              }}
            >
              ID
            </div>
            <div
              className="flex items-center justify-start text-xs font-semibold px-2 plant-branch"
              style={{
                width: "45%",
              }}
            >
              Branch Name
            </div>
            <div
              className="flex items-center justify-start text-xs font-semibold px-2 plant-grade"
              style={{
                width: "25%",
              }}
            >
              Grade
            </div>
            <div
              className="text-xs font-semibold px-2 plant-price"
              style={{
                width: "20%",
              }}
            >
              Price
            </div>
          </div>
          {prices.map((price, index) => (
            <div className="w-full flex items-center justify-center font-[Inter] py-4 border-b border-solid border-black/20">
              <div
                className="text-xs font-semibold px-4"
                style={{
                  width: "10%",
                }}
              >
                {index + 1}
              </div>
              <div
                className="flex items-center justify-start text-xs font-semibold px-2 plant-branch"
                style={{
                  width: "45%",
                }}
              >
                {price?.branch_name}
              </div>
              <div
                className="flex items-center justify-start text-xs font-semibold px-2 plant-grade"
                style={{
                  width: "25%",
                }}
              >
                {price?.grade}
              </div>
              <div
                className="text-xs font-semibold px-2 plant-price"
                style={{
                  width: "20%",
                }}
              >
                {price?.price}
              </div>
            </div>
          ))}
        </div>
        <div
          className="w-1/3 mx-3 border border-solid border-black p-3 sticky add-plantcontainer rounded-xl"
          style={{
            top: "120px",
          }}
        >
          <span
            className="text-lg font-bold"
            style={{
              fontFamily: `'Ysabeau', sans-serif`,
            }}
          >
            Add Plant
          </span>
          <div className="px-1 my-2 ">
            <div className="font-[inter]">Grades</div>
            <div className=" px-1 w-full">
              <select
                className="w-full border border-solid border-black/80 rounded-2xl text-sm px-4 py-2 my-1 bg-white"
                id="getgrade"
              >
                <option>Select Grade</option>
                <option value="13/15" className="font-[Inter]">
                  13/15
                </option>
                <option value="16/20" className="font-[Inter]">
                  16/20
                </option>
                <option value="21/25" className="font-[Inter]">
                  21/25
                </option>
                <option value="26/30" className="font-[Inter]">
                  26/30
                </option>
                <option value="31/40" className="font-[Inter]">
                  31/40
                </option>
                <option value="41/50" className="font-[Inter]">
                  41/50
                </option>
                <option value="51/60" className="font-[Inter]">
                  51/60
                </option>
                <option value="61/70" className="font-[Inter]">
                  61/70
                </option>
                <option value="71/90" className="font-[Inter]">
                  71/90
                </option>
                <option value="91/110" className="font-[Inter]">
                  91/110
                </option>
              </select>
            </div>
            <div className="font-[inter] mt-1">Select plant</div>
            <div className=" px-1 w-full">
              <select
                className="w-full border border-solid border-black/80 rounded-2xl  text-sm px-4 py-2 my-1 bg-white"
                id="getbranch"
              >
                <option>Select Plant</option>
                {plants.map((plant) => {
                  return (
                    <option value={plant.plant_name} className="font-[Inter]">
                      {plant.plant_name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="w-full my-1">
              <div className="font-[Inter]">Price</div>
              <div
                className="max-w-full mx-2 my-1"
                style={{
                  height: "35px",
                }}
              >
                <input
                  value={price}
                  onChange={(e) => {
                    setPrice(e.target.value);
                  }}
                  type="text"
                  className="h-full w-full border border-solid border-black/80 rounded-2xl"
                />
              </div>
            </div>
            <div
              className="flex items-center justify-end"
              style={{
                maxWidth: "100%",
                padding: "0px 8px",
              }}
            >
              <button
                onClick={handlesubmit}
                className="bg-black text-white text-sm rounded-full"
                style={{
                  width: "130px",
                  height: "40px",
                  margin: "5px 0px",
                  fontFamily: `Inter`,
                }}
              >
                Add Plant
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Price;
