import axios from "axios";
import React from "react";
import { useState } from "react";
import { AiFillDelete, AiOutlinePlus } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import { IoMdArrowDropdown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";

function Plantcontainer({
  plant,
  index,
  handleedit,
  handledelete,
  setEditpanel,
}) {
  const dispatch = useDispatch();
  const sections = useSelector((state) => state.sections);
  const [showplantdetail, setShowplantdetail] = useState(false);
  const [addsection, setAddsection] = useState(true);
  const [newsection, setNewsection] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch_section_name = async () => {
    if (newsection) {
      const form = new FormData();
      form.append("plant_id", plant.id);
      form.append("section_name", newsection);
      setLoading(true);
      const request = await axios
        .post("https://saizoous.com/zoousbackend/plant/sections.php", form)
        .then((result) => {
          setLoading(false);
          setAddsection(false);
          dispatch({
            type: "sections",
            payload: result.data.sections,
          });
        })
        .catch(() => {
          setLoading(false);
        });

      return request;
    }
  };

  const handlesection = (plant) => {
    const filtersections = sections.filter((state) => state.plant === plant.id);

    return (
      <div className="bg-[#f9f9f9] rounded-md my-1 py-2 w-full flex items-center flex-wrap ">
        {filtersections.map((section) => (
          <div
            className="border border-black/40 py-1 px-4 rounded-xl  m-1"
            style={{
              fontSize: 10,
            }}
          >
            {section?.section_name}
          </div>
        ))}
        {addsection ? (
          <div className="w-full my-2 mx-4">
            <div className="border-dashed border border-black/40 w-full h-8 rounded-full mb-1 px-5 ">
              {" "}
              <input
                type="text"
                value={newsection}
                onChange={(e) => {
                  setNewsection(e.target.value);
                }}
                className="w-full h-full outline-none rounded-full text-xs"
                placeholder="Section name"
              />
            </div>
            <div className="flex items-center justify-end mt-2">
              {loading ? (
                <div className="h-8 w-8 loadingspinner border-3 border-t-black"></div>
              ) : (
                <div
                  className="w-16 h-8 bg-green-400 rounded-full text-xs flex items-center justify-center"
                  onClick={dispatch_section_name}
                >
                  add
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="border-dashed border flex items-center justify-center text-white bg-black border-black/40 py-1 px-4 rounded-xl text-xs m-1">
            <AiOutlinePlus className="mr-1" /> Add section
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="w-full flex bg-white shadow-md rounded-md flex-col my-1 items-center justify-center font-[Inter] py-1 px-1">
      <div className="w-full flex items-center justify-center py-2">
        <div
          className="text-xs font-semibold px-2"
          style={{
            width: "10%",
          }}
        >
          {plant?.id}
        </div>
        <div
          className="flex items-center justify-start text-xs font-semibold px-2"
          style={{
            width: "50%",
          }}
        >
          {plant?.plant_name}
        </div>
        <div
          className="flex items-center justify-start text-xs font-semibold px-2"
          style={{
            width: "30%",
          }}
        >
          <div
            className="py-2 px-2 bg-blue-500/10 rounded-md mr-2"
            onClick={() => {
              setEditpanel(true);
              handleedit(plant);
            }}
          >
            <BiPencil fontSize={16} color="royalblue" />
          </div>
          <div
            className="py-2 px-2 bg-red-500/10 rounded-md mr-2"
            onClick={() => handledelete(plant.id)}
          >
            <AiFillDelete fontSize={16} color="red" />
          </div>
          <div
            className="py-2 px-2 bg-red-500/10 rounded-md mr-2"
            onClick={() => {
              setShowplantdetail(!showplantdetail);
            }}
          >
            <IoMdArrowDropdown />
          </div>
        </div>
      </div>
      {showplantdetail && <>{handlesection(plant)}</>}
    </div>
  );
}

export default Plantcontainer;
