import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export default function Userplant({ plant, access }) {
  const [plantaccess, setPlantaccess] = useState(false);

  useEffect(() => {
    if (access.includes(plant.plant_name)) {
      setPlantaccess(true);
    } else {
      setPlantaccess(false);
    }
  }, [plant.length , access.length]);

  return (
    <div className="flex items-center w-1/2 py-2 px-1 text-sm">
      <input
        onClick={() => {
          setPlantaccess(!plantaccess);
        }}
        checked={plantaccess}
        type="checkbox"
        id={plant.plant_name}
        className="mr-2 w-4 h-4"
      />
      <label htmlFor={plant.plant_name}>{plant.plant_name}</label>
    </div>
  );
}
