import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import { BsFilter } from "react-icons/bs";
import { CiExport } from "react-icons/ci";
import { FiMoreHorizontal } from "react-icons/fi";
import { motion } from "framer-motion";
import { FiDownloadCloud } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Home/Header/Header";
import { Avatar } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import exportFromJSON from "export-from-json";

function Incharge() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { plant } = useParams();
  const [filtereduser, setFiltereduser] = useState([]);
  const selecteduser = useSelector((state) => state.selectinchargeuser);
  const incharge = useSelector((state) => state.incharge);
  const [shareduser, setShareduser] = useState("");
  const [showdetail, setShowdetail] = useState(false);
  // const [incharge, setIncharge] = useState([]);

  const handledownloadxlsx = () => {
    const fileName = "incharge_biodata";
    exportFromJSON({ data: incharge, fileName, exportType: "xls" });
  };

  const handlefilter = (text) => {
    const filtereduser = incharge.filter(
      (state) =>
        state?.id?.startsWith(text) ||
        state?.name?.startsWith(text) ||
        state?.email?.startsWith(text)
    );
    console.log(filtereduser);
    setFiltereduser(filtereduser);
  };

  const handleclick = (e) => {
    const biodataheader = document.getElementsByClassName("biodata-header");

    for (let i = 0; i < biodataheader.length; i++) {
      biodataheader[i].style.backgroundColor = "white";
      biodataheader[i].style.color = "black";
    }
    e.target.style.backgroundColor = "black";
    e.target.style.color = "white";
  };

  const handleincharge = async () => {
    const request = await axios.post(
      "https://saizoous.com/zoousbackend/employee/incharge.php"
    );
    console.log(request.data.data[0]);
    dispatch({
      type: "Incharge",
      payload: request.data.data,
    });
    // setIncharge(request.data.data);
  };
  useEffect(() => {
    handleincharge();
  }, []);

  return (
    <div className="w-full">
      <Header />
      <div className="w-full employeecontainer">
        <div className="employee-data-container">
          <div className="w-full pt-4 pb-2">
            <div className="pb-4 text-4xl">BioData</div>
            <div
              className="border border-solid border-black/40 rounded-md flex items-center mx-3"
              style={{
                maxWidth: "390px",
                height: "35px",
              }}
            >
              <div
                onClick={(e) => {
                  handleclick(e);
                  navigate(`/${plant?.toLowerCase()}/Biodata`);
                }}
                className="biodata-header cursor-pointer w-1/3 text-xs flex items-center justify-center  h-full rounded-md"
              >
                Employee Biodata
              </div>
              <div
                onClick={(e) => {
                  handleclick(e);
                  navigate(`/${plant?.toLowerCase()}/Biodata/Incharge`);
                }}
                className="biodata-header cursor-pointer w-1/3 text-xs flex items-center justify-center h-full rounded-md bg-black text-white"
              >
                Incharge Biodata
              </div>
              <div
                onClick={(e) => {
                  handleclick(e);
                  navigate(`/${plant?.toLowerCase()}/Biodata/guest`);
                }}
                className="biodata-header cursor-pointer w-1/3 text-xs flex items-center justify-center h-full rounded-md"
              >
                Guest Biodata
              </div>
            </div>
          </div>
          <div className="e-d-container-top">
            <div className="flex flex-col font-[inter]">
              <h1>Incharge Employee</h1>
              <p
                className="text-xs text-black/0.3"
                style={{
                  fontSize: "11px",
                  fontFamily: "Noto_Sans",
                }}
              >
                Manage your Incharge Employee.
              </p>
            </div>
            <div className="e-d-container-top-right items-center min-w-36 flex rounded-full">
              <div className="flex w-full px-2 py-1">
                <div
                  className="e-d-container-search flex items-center text-sm capitalize  border border-solid border-black/60  rounded-full mx-2 relative"
                  style={{
                    width: "250px",
                    // background: "#f7f7f7",
                  }}
                >
                  <AiOutlineSearch
                    fontSize={20}
                    color="rgb(0,0,0,0.8)"
                    className="mx-2"
                  />
                  <input
                    value={shareduser}
                    onChange={(e) => {
                      setShareduser(e.target.value);
                      handlefilter(e.target.value);
                    }}
                    type="text"
                    placeholder="Search...."
                    style={{
                      width: "100%",
                      marginRight: "8px",
                      height: "100%",
                      padding: "0px 0px",
                      borderRadius: "5px",
                      background: "transparent",
                      outline: "none",
                      border: "none",
                      color: "black",
                    }}
                  />

                  {shareduser && (
                    <>
                      {filtereduser.length ? (
                        <>
                          <div
                            style={{
                              top: "35px",
                              maxHeight: "200px",
                              // overflowY: "scroll",
                            }}
                            className="absolute rounded w-full bg-[white] py-2 px-2 border border-solid border-black/40"
                          >
                            {filtereduser.map((user) => (
                              <div
                                onClick={() => {
                                  dispatch({
                                    type: "selectedinchargeuser",
                                    payload: user,
                                  });
                                  setShowdetail(true);
                                }}
                                className="w-full py-2 px-2 hover:bg-black/10 cursor-pointer"
                              >
                                {user.name}
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <div
                          className="absolute rounded w-full bg-[white] py-2 px-2 border border-solid border-black/40"
                          style={{
                            top: "35px",
                            maxHeight: "200px",
                            // overflowY: "scroll",
                          }}
                        >
                          <div className="text-xs font-semibold font-[Inter]">
                            user not found
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div
                  onClick={handledownloadxlsx}
                  style={{
                    borderWidth: "1.5px",
                  }}
                  className="exportbutton flex items-center text-sm capitalize font-bold  border-solid py-1.5 px-3.5 mx-2 border-black/40 rounded-md"
                >
                  <FiDownloadCloud
                    fontSize={18}
                    color="rgb(0,0,0,0.8)"
                    className="mr-1"
                  />
                  <span
                    style={{
                      margin: "0px 8px",
                      color: "rgb(0,0,0,0.8)",
                      fontFamily: "Inter",
                      fontSize: "12px",
                    }}
                  >
                    Download CSV
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center w-full min-h-20 py-4">
            <div
              className="min-h-10 border-solid border border-black rounded-xl"
              style={{
                width: "95%",
              }}
            >
              <div className="w-full min-h-10 border-b border-solid border-black flex items-center px-4 justify-between font-semibold py-3">
                <div
                  className="user-id"
                  style={{
                    fontSize: "11px",
                    width: "130px",
                  }}
                >
                  ID
                </div>
                <div
                  className="user-name"
                  style={{
                    fontSize: "11px",
                    width: "25%",
                  }}
                >
                  Name
                </div>
                <div
                  className="user-comision"
                  style={{
                    fontSize: "11px",
                    width: "25%",
                  }}
                >
                  COMISSION
                </div>
                <div
                  className="user-co"
                  style={{
                    fontSize: "11px",
                    width: "25%",
                  }}
                >
                  C/O
                </div>
                <div
                  className="user-residence"
                  style={{
                    fontSize: "11px",
                    width: "25%",
                  }}
                >
                  Address
                </div>
                <div
                  className="user-phone"
                  style={{
                    fontSize: "11px",
                    width: "25%",
                  }}
                >
                  Phone Number
                </div>

                <div
                  className="user-joindate"
                  style={{
                    fontSize: "11px",
                    width: "20%",
                  }}
                >
                  JOIN DATE
                </div>

                <div
                  style={{
                    fontSize: "11px",
                    width: "20%",
                  }}
                >
                  Action
                </div>
              </div>
              {incharge?.map((employee) => (
                <div className="w-full min-h-10 flex  items-center px-4 justify-between font-semibold py-2.5 border-b border-solid border-black/60">
                  <div
                    className="user-id"
                    style={{
                      fontSize: "11px",
                      width: "130px",
                    }}
                  >
                    {employee?.id}
                  </div>
                  <div
                    className="flex items-center user-name"
                    style={{
                      fontSize: "12px",
                      width: "25%",
                    }}
                  >
                    <Avatar
                      style={{
                        position: "relative",
                        width: "30px",
                        height: "30px",
                        marginRight: 7,
                      }}
                      src={`http://zoous.com/new/api/images/${employee?.front}`}
                    />
                    {employee?.name}
                  </div>
                  <div
                    className="user-comision"
                    style={{
                      fontSize: "12px",
                      width: "25%",
                    }}
                  >
                    {employee?.incharge_comission}
                  </div>
                  <div
                    className="user-co"
                    style={{
                      fontSize: "12px",
                      width: "25%",
                    }}
                  >
                    {employee?.care}
                  </div>
                  <div
                    className="user-residence"
                    style={{
                      fontSize: "12px",
                      width: "25%",
                    }}
                  >
                    {employee?.area}
                  </div>
                  <div
                    className="user-phone"
                    style={{
                      fontSize: "12px",
                      width: "25%",
                    }}
                  >
                    {employee?.phone}
                  </div>

                  <div
                    className="user-joindate"
                    style={{
                      fontSize: "12px",
                      width: "20%",
                    }}
                  >
                    {employee?.doj}
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      width: "20%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Link
                      to={`/${plant}/Biodata/incharge/edituser/${employee.id}`}
                    >
                      <div className="py-2 px-2 bg-blue-500/10 rounded-md mr-2">
                        <BiPencil fontSize={16} color="royalblue" />
                      </div>
                    </Link>
                    <div
                      onClick={() => {
                        dispatch({
                          type: "selectedinchargeuser",
                          payload: employee,
                        });
                        setShowdetail(true);
                      }}
                      className="py-2 px-2 bg-red-500/10 rounded-md mr-2"
                    >
                      <FiMoreHorizontal fontSize={16} color="red" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {showdetail && (
            <div
              className="fixed w-screen h-screen top-0 bottom-0 right-0 left-0"
              style={{
                backgroundColor: "rgb(0,0,0,0.3)",
                zIndex: "100000",
              }}
            >
              <motion.div
                initial={{
                  x: 100,
                }}
                transition={{ type: "tween", duration: 0.1 }}
                animate={{
                  x: 0,
                }}
                className="absolute right-0 top-0"
                style={{
                  width: "500px",
                  height: "100vh",
                  overflowY: "scroll",
                  background: "white",
                }}
              >
                <div
                  onClick={() => {
                    setShowdetail(false);
                  }}
                  className="py-2 px-4 border-b border-solid border-black/10 sticky bg-white top-0"
                >
                  <AiOutlineClose fontSize={20} />
                </div>
                <div className="flex items-center flex-col py-2">
                  <div
                    className="flex items-center flex-col border border-solid border-black/10 p-2"
                    style={{
                      width: "85%",
                    }}
                  >
                    <div className="flex items-center relative justify-start py-2 w-full">
                      <Avatar
                        style={{
                          position: "relative",
                          width: "40px",
                          height: "40px",
                        }}
                        src={`http://zoous.com/new/api/images/f1529edd-98ad-4eb9-ae8a-4ee78fd8d98a248434193.jpg`}
                      />
                      <div className="px-2 capitalize text-sm font-[Inter]">
                        <div className="text-xs">ID : {selecteduser?.id}</div>
                        <div className="font-semibold">
                          {selecteduser?.name}
                        </div>
                      </div>
                    </div>
                    <div className=" border-t border-solid border-black/10 w-full mt-2 flex items-center justify-between py-2 px-3">
                      <div className="text-xs pt-2">
                        <div className="font-semibold">Phone number</div>
                        <div className="mt-1">{selecteduser?.phone}</div>
                      </div>
                      <div className="text-xs  pt-2">
                        <div className="font-semibold">Person</div>
                        <div className="mt-1">{selecteduser?.person}</div>
                      </div>
                      <div className="text-xs  pt-2">
                        <div className="font-semibold">Aadhar</div>
                        <div className="mt-1">{selecteduser?.aadhar}</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="border-solid border py-3 px-3 border-black/10 my-2"
                    style={{
                      width: "85%",
                    }}
                  >
                    <div className="text-sm py-1 font-semibold font-[Inter] border-b border-solid border-black/10">
                      Bank detail
                    </div>
                    {/* <div className="py-2 text-xs px-2 flex justify-between"></div> */}
                    <div className="py-1 text-xs px-2 flex justify-between">
                      <div className="py-2 text-xs pt-2">
                        <div className="font-semibold">Account number</div>
                        <div className="mt-1">{selecteduser?.acc}</div>
                      </div>
                      <div className="text-xs pt-2">
                        <div className="font-semibold">Branch Name</div>
                        <div className="mt-1">{selecteduser?.bank}</div>
                      </div>
                      <div className="text-xs  pt-2">
                        <div className="font-semibold">IFSC Code</div>
                        <div className="mt-1">{selecteduser?.ifsc}</div>
                      </div>
                    </div>
                    <div className="text-xs py-1 px-2 font-[Inter] font-semibold">
                      Passbook image
                    </div>
                    <div
                      className="px-2 py-1"
                      style={{
                        height: "200px",
                        width: "200px",
                      }}
                    >
                      <img
                        className="w-full h-full"
                        src={`http://zoous.com/new/api/images/f1529edd-98ad-4eb9-ae8a-4ee78fd8d98a248434193.jpg`}
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="border-solid border py-3 px-3 border-black/10 my-2"
                    style={{
                      width: "85%",
                    }}
                  >
                    <div className="text-sm py-1 font-semibold font-[Inter] border-b border-solid border-black/10">
                      PF and Other
                    </div>
                    {/* <div className="py-2 text-xs px-2 flex justify-between"></div> */}
                    <div className="py-1 w-full text-xs px-2 flex justify-between">
                      <div className="py-2 text-xs pt-2 w-1/3">
                        <div className="font-semibold">PF</div>
                        <div className="mt-1">{selecteduser?.pf}</div>
                      </div>
                      <div className="text-xs pt-2 w-1/3">
                        <div className="font-semibold">ESI</div>
                        <div className="mt-1">{selecteduser?.esi}</div>
                      </div>
                      <div className="text-xs pt-2 w-1/3">
                        <div className="font-semibold">Salary</div>
                        <div className="mt-1">{selecteduser?.salary}</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="border-solid border py-3 px-3 border-black/10 my-2"
                    style={{
                      width: "85%",
                    }}
                  >
                    <div className="text-sm py-1 font-semibold font-[Inter] border-b border-solid border-black/10">
                      Additional detail
                    </div>
                    {/* <div className="py-2 text-xs px-2 flex justify-between"></div> */}
                    <div className="py-1 w-full text-xs px-2 flex justify-between">
                      <div className="py-2 text-xs pt-2 w-1/3">
                        <div className="font-semibold">Contractor</div>
                        <div className="mt-1">{selecteduser?.contractor}</div>
                      </div>
                      <div className="text-xs pt-2 w-1/3">
                        <div className="font-semibold">Department</div>
                        <div className="mt-1">{selecteduser?.dept}</div>
                      </div>
                      <div className="text-xs pt-2 w-1/3">
                        <div className="font-semibold">Role</div>
                        <div className="mt-1">{selecteduser?.role}</div>
                      </div>
                    </div>
                    <div className="py-1 text-xs px-2 flex justify-between">
                      <div className="py-2 text-xs pt-2 w-1/3">
                        <div className="font-semibold">Section</div>
                        <div className="mt-1">{selecteduser?.section}</div>
                      </div>
                      <div className="text-xs pt-2 w-1/3">
                        <div className="font-semibold">Area</div>
                        <div className="mt-1">{selecteduser?.area}</div>
                      </div>
                      <div className="text-xs  pt-2 w-1/3">
                        <div className="font-semibold">Code</div>
                        <div className="mt-1">{selecteduser?.code}</div>
                      </div>
                    </div>
                    <div className="py-1 text-xs px-2 flex justify-between">
                      <div className="py-2 text-xs pt-2 w-1/3">
                        <div className="font-semibold">Code</div>
                        <div className="mt-1">{selecteduser?.code}</div>
                      </div>
                      <div className="text-xs pt-2 w-1/3">
                        <div className="font-semibold">NCP</div>
                        <div className="mt-1">{selecteduser?.ncp}</div>
                      </div>
                      <div className="text-xs  pt-2 w-1/3">
                        <div className="font-semibold">UAN No.</div>
                        <div className="mt-1">{selecteduser?.uan_no}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Incharge;
