import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiFillEye, AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import { CiExport } from "react-icons/ci";
import { FiMoreHorizontal } from "react-icons/fi";
import Header from "../Home/Header/Header";
import { Link, useParams } from "react-router-dom";
import { Avatar } from "@mui/material";
import { motion } from "framer-motion";
import { FiDownloadCloud } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { read, utils, writeFileXLSX } from "xlsx";

function Salary() {
  const dispatch = useDispatch();
  const { plant } = useParams();
  const plants = useSelector((state) => state?.plants);
  const [salary, setSalary] = useState([]);
  const activeplant = useSelector((state) => state.activeplant);
  const employee = useSelector((state) => state.employee);
  const [employeebyplant, setEmployeebyplant] = useState([]);
  const [shareduser, setShareduser] = useState("");
  const [filtereduser, setFiltereduser] = useState([]);
  const [showdetail, setShowdetail] = useState(false);
  const selecteduser = useSelector((state) => state.selectuser);
  const [monthdays, setMonthdays] = useState(0);

  const handlefilter = (text) => {
    if (text.length) {
      const filtereduser = employee?.filter(
        (state) =>
          state.employee_id.toLowerCase().startsWith(text) ||
          state.employee_name?.split(" ")[0]?.toLowerCase()?.startsWith(text) ||
          state.employee_name?.split(" ")[1]?.toLowerCase()?.startsWith(text) ||
          state.Phone_number.startsWith(text) ||
          state.care_of.startsWith(text) ||
          state.Aadhar_number.startsWith(text)
      );
      setEmployeebyplant(filtereduser);
    } else {
      setEmployeebyplant(
        employee.filter(
          (employee) => employee.section_name.toUpperCase() === activeplant
        )
      );
    }
  };
  function getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }
  const handleemployeebysection = async (plant) => {
    dispatch({
      type: "activeplant",
      payload: plant.plant_name,
    });
    setEmployeebyplant(
      employee.filter(
        (employee) =>
          employee.plant_name.toUpperCase() === plant.plant_name.toUpperCase()
      )
    );
  };

  useEffect(() => {
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1;
    const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);
    setMonthdays(daysInCurrentMonth);
  }, []);

  const makexlsxFile = async () => {
    const wb = utils.book_new();
    // console.log(activeplant);
    const employees = employee.filter(
      (employee) => employee.plant_name.toLowerCase() == activeplant
    );

    const header = [
      "S.No",
      "Name",
      "Total",
      "Bank Name",
      "Account No",
      "IFSC Code",
    ];
    const employeeData = employees.map((employee, index) => [
      index + 1,
      employee.employee_name,
      employee.salary,
      employee.bank_name,
      employee.Account_number,
      employee.Ifsc_code,
    ]);
    const totalAmount = employees.reduce((total, value) => {
      return total + parseInt(value.salary);
    }, 0);
    console.log(totalAmount);

    const ws = utils.aoa_to_sheet([
      header,
      ...employeeData,
      ["", "Total", totalAmount, "", "", ""],
    ]);
    utils.book_append_sheet(wb, ws, "Employee Data");

    writeFileXLSX(wb, `${activeplant}.xlsx`);
  };

  useEffect(() => {
    setEmployeebyplant(
      employee.filter(
        (employee) =>
          employee.plant_name.toUpperCase() === activeplant.toUpperCase()
      )
    );
    console.log(
      employee.filter(
        (employee) =>
          employee.plant_name.toUpperCase() === activeplant.toUpperCase()
      )
    );
  }, [employee.length]);
  return (
    <div>
      <Header />
      <div className="w-full employeecontainer">
        <div className="employee-data-container">
          <div className="w-full px-4 pt-4">
            <div
              className="e-d-container-top flex flex-col w-full relative my-2 text-xl"
              style={{
                zIndex: 1000,
                color: "white",
              }}
            >
              <h1 className="text-2xl capitalize font-semibold text-white">
                Salary Statement
              </h1>
            </div>
            <div className="w-full py-2 px-3 bg-white rounded-md z-10 relative shadow-md flex items-center flex-wrap flex-row">
              {plants.map((plant) => {
                return (
                  <div
                    onClick={() => handleemployeebysection(plant)}
                    className={`select-none text-xs py-2 px-4 border capitalize border-black/60 my-1 mx-1 rounded-md ${
                      plant.plant_name.toLowerCase() === activeplant
                        ? "bg-black text-white"
                        : "bg-white text-black"
                    }  hover:text-white hover:bg-black cursor-pointer`}
                  >
                    {plant?.plant_name.toLowerCase()}
                  </div>
                );
              })}
            </div>

            <div className="items-center w-full flex bg-white relative shadow-md rounded-md mt-4">
              <div className="flex w-full h-12">
                <div className="flex items-center text-sm capitalize  rounded-full mx-2 w-full relative h-full">
                  <div
                    className="flex items-center justify-center"
                    style={{
                      width: "40px",
                      height: "100%",
                    }}
                  >
                    <AiOutlineSearch
                      fontSize={20}
                      color="rgb(0,0,0,0.8)"
                      className="mx-1"
                    />
                  </div>
                  <input
                    value={shareduser}
                    onChange={(e) => {
                      setShareduser(e.target.value);
                      handlefilter(e.target.value);
                    }}
                    type="text"
                    placeholder="Search...."
                    style={{
                      width: "100%",
                      marginRight: "8px",
                      height: "100%",
                      padding: "0px 0px",
                      borderRadius: "5px",
                      background: "transparent",
                      outline: "none",
                      border: "none",
                      color: "black",
                    }}
                  />
                </div>
                <div
                  onClick={makexlsxFile}
                  className="w-44 flex items-center justify-center text-sm capitalize bg-black border border-white/40"
                >
                  <FiDownloadCloud
                    fontSize={18}
                    color="white"
                    className="mr-1"
                  />
                  <span
                    style={{
                      margin: "0px 8px",
                      color: "white",
                      fontFamily: "Inter",
                      fontSize: "12px",
                    }}
                  >
                    Download CSV
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center w-full min-h-20 py-4 px-4 relative">
            <div
              className="min-h-10 border border-solid border-black rounded-lg"
              style={{
                width: "100%",
                zIndex: 100,
              }}
            >
              <div className="w-full bg-[#fffafa] min-h-10  rounded-t-lg flex items-center px-4 justify-between font-semibold py-3">
                <div
                  className="user-id"
                  style={{
                    fontSize: "11px",
                    width: "130px",
                  }}
                >
                  ID
                </div>
                <div
                  className="user-name"
                  style={{
                    fontSize: "11px",
                    width: "25%",
                  }}
                >
                  Employee Name
                </div>
                <div
                  className="user-co"
                  style={{
                    fontSize: "11px",
                    width: "25%",
                  }}
                >
                  Amount
                </div>
                <div
                  className="user-residence"
                  style={{
                    fontSize: "11px",
                    width: "25%",
                  }}
                >
                  Bank Name
                </div>
                <div
                  className="user-phone"
                  style={{
                    fontSize: "11px",
                    width: "25%",
                  }}
                >
                  Account Number
                </div>

                <div
                  className="user-joindate"
                  style={{
                    fontSize: "11px",
                    width: "20%",
                  }}
                >
                  IFSC Code
                </div>

                <div
                  style={{
                    fontSize: "11px",
                    width: "20%",
                  }}
                >
                  Branch
                </div>
              </div>
              {employeebyplant?.map((employee) => (
                <div className="w-full min-h-10 flex items-center px-4 justify-between font-semibold py-2.5 border-t border-t-black/30">
                  <div
                    className="user-id"
                    style={{
                      fontSize: "11px",
                      width: "130px",
                    }}
                  >
                    {employee?.employee_id}
                  </div>
                  <div
                    className="flex items-center user-name"
                    style={{
                      fontSize: "12px",
                      width: "25%",
                    }}
                  >
                    <Avatar
                      style={{
                        position: "relative",
                        width: "30px",
                        height: "30px",
                        marginRight: 7,
                      }}
                      src={`http://zoous.com/new/api/images/${employee?.front}`}
                    />

                    {employee?.employee_name}
                  </div>
                  <div
                    className="user-co"
                    style={{
                      fontSize: "12px",
                      width: "25%",
                    }}
                  >
                    {employee?.salary}
                  </div>
                  <div
                    className="user-residence"
                    style={{
                      fontSize: "12px",
                      width: "25%",
                    }}
                  >
                    {employee?.bank_name}
                  </div>
                  <div
                    className="user-phone"
                    style={{
                      fontSize: "12px",
                      width: "25%",
                    }}
                  >
                    {employee?.Account_number}
                  </div>
                  <div
                    className="user-joindate"
                    style={{
                      fontSize: "12px",
                      width: "20%",
                    }}
                  >
                    {employee?.Ifsc_code}
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      width: "20%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Link to={`/${plant}/biodata/editbio/${employee?.id}`}>
                      <div className="py-2 px-2 bg-blue-500/10 rounded-md mr-2">
                        <BiPencil fontSize={16} color="royalblue" />
                      </div>
                    </Link>
                    <div
                      onClick={() => {
                        dispatch({
                          type: "selecteduser",
                          payload: employee,
                        });
                        setShowdetail(true);
                      }}
                      className="py-2 px-2 bg-red-500/10 rounded-md mr-2"
                    >
                      <AiFillEye fontSize={16} color="red" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {showdetail && (
            <div
              className="fixed w-screen h-screen top-0 bottom-0 right-0 left-0"
              style={{
                backgroundColor: "rgb(0,0,0,0.3)",
                zIndex: "100000",
              }}
            >
              <motion.div
                initial={{
                  x: 100,
                }}
                transition={{ type: "tween", duration: 0.1 }}
                animate={{
                  x: 0,
                }}
                className="absolute right-0 top-0"
                style={{
                  width: "500px",
                  height: "100vh",
                  overflowY: "scroll",
                  background: "white",
                }}
              >
                <div className="py-2 px-4 border-b border-solid border-black/10 sticky bg-white top-0 flex items-center justify-between">
                  <div
                    onClick={() => {
                      setShowdetail(false);
                    }}
                  >
                    <AiOutlineClose fontSize={20} />
                  </div>
                </div>
                <div className="flex items-center flex-col py-2">
                  <div
                    className="flex items-center flex-col border border-solid border-black/10 p-2"
                    style={{
                      width: "85%",
                    }}
                  >
                    <div className="flex items-center relative justify-start py-2 w-full">
                      <Avatar
                        style={{
                          position: "relative",
                          width: "40px",
                          height: "40px",
                        }}
                        src={`https://saizoous.com/zoousbackend/employee/Photo/Upload/${selecteduser?.employee_photo}`}
                      />
                      <div className="px-2 capitalize text-sm font-[Inter]">
                        <div className="text-xs">
                          ID : {selecteduser?.employee_id}
                        </div>
                        <div className="font-semibold">
                          {selecteduser?.employee_name}
                        </div>
                      </div>
                    </div>
                    <div className=" border-t border-solid border-black/10 w-full mt-2 flex items-center justify-between py-2 px-3">
                      <div className="text-xs pt-2">
                        <div className="font-semibold">Phone number</div>
                        <div className="mt-1">{selecteduser?.Phone_number}</div>
                      </div>
                      <div className="text-xs  pt-2">
                        <div className="font-semibold">Care of</div>
                        <div className="mt-1">{selecteduser?.care_of}</div>
                      </div>
                      <div className="text-xs  pt-2">
                        <div className="font-semibold">Aadhar</div>
                        <div className="mt-1">
                          {selecteduser?.Aadhar_number}
                        </div>
                      </div>
                    </div>
                    <div className="w-full px-3">
                      <div className="font-semibold text-xs  pt-2.5">
                        Addhar photo
                      </div>
                      <div
                        className="py-1.5 flex items-center"
                        style={{
                          height: "200px",
                          width: "360px",
                        }}
                      >
                        <img
                          className="w-1/2 h-full object-contain object-left mr-2"
                          src={`https://saizoous.com/zoousbackend/employee/Aadhar/Upload/${selecteduser?.aadhar_front}`}
                          alt=""
                        />
                        <img
                          className="w-1/2 h-full object-contain object-left"
                          src={`https://saizoous.com/zoousbackend/employee/Aadhar/Upload/${selecteduser?.aadhar_back}`}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="border-solid border py-3 px-3 border-black/10 my-2"
                    style={{
                      width: "85%",
                    }}
                  >
                    <div className="text-sm py-1 font-semibold font-[Inter] border-b border-solid border-black/10">
                      Bank detail
                    </div>
                    {/* <div className="py-2 text-xs px-2 flex justify-between"></div> */}
                    <div className="py-1 text-xs px-2 flex justify-between">
                      <div className="py-2 text-xs pt-2">
                        <div className="font-semibold">Account number</div>
                        <div className="mt-1">
                          {selecteduser?.Account_number}
                        </div>
                      </div>
                      <div className="text-xs pt-2">
                        <div className="font-semibold">Branch Name</div>
                        <div className="mt-1">{selecteduser?.bank_name}</div>
                      </div>
                      <div className="text-xs  pt-2">
                        <div className="font-semibold">IFSC Code</div>
                        <div className="mt-1">{selecteduser?.Ifsc_code}</div>
                      </div>
                    </div>
                    <div className="text-xs py-1 px-2 font-[Inter] font-semibold">
                      Passbook image
                    </div>
                    <div
                      className="px-2 py-1"
                      style={{
                        height: "200px",
                        width: "200px",
                      }}
                    >
                      <img
                        className="w-full h-full object-contain object-left"
                        src={`https://saizoous.com/zoousbackend/employee/Passbook/Upload/${selecteduser?.Passbook_photo}`}
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="border-solid border py-3 px-3 border-black/10 my-2"
                    style={{
                      width: "85%",
                    }}
                  >
                    <div className="text-sm py-1 font-semibold font-[Inter] border-b border-solid border-black/10">
                      PF and Other
                    </div>
                    {/* <div className="py-2 text-xs px-2 flex justify-between"></div> */}
                    <div className="py-1 w-full text-xs px-2 flex justify-between">
                      <div className="py-2 text-xs pt-2 w-1/3">
                        <div className="font-semibold">PF</div>
                        <div className="mt-1">{selecteduser?.Pf}</div>
                      </div>
                      <div className="text-xs pt-2 w-1/3">
                        <div className="font-semibold">ESI</div>
                        <div className="mt-1">{selecteduser?.esi_number}</div>
                      </div>
                      <div className="text-xs pt-2 w-1/3">
                        <div className="font-semibold">Salary</div>
                        <div className="mt-1">{selecteduser?.salary}</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="border-solid border py-3 px-3 border-black/10 my-2"
                    style={{
                      width: "85%",
                    }}
                  >
                    <div className="text-sm py-1 font-semibold font-[Inter] border-b border-solid border-black/10">
                      Additional detail
                    </div>
                    {/* <div className="py-2 text-xs px-2 flex justify-between"></div> */}
                    <div className="py-1 w-full text-xs px-2 flex justify-between">
                      <div className="py-2 text-xs pt-2 w-1/3">
                        <div className="font-semibold">Date of Joining</div>
                        <div className="mt-1">
                          {selecteduser?.date_of_joining}
                        </div>
                      </div>
                      <div className="text-xs pt-2 w-1/3">
                        <div className="font-semibold">Department</div>
                        <div className="mt-1">{selecteduser?.department}</div>
                      </div>
                      <div className="text-xs pt-2 w-1/3">
                        <div className="font-semibold">Role</div>
                        <div className="mt-1">{selecteduser?.role}</div>
                      </div>
                    </div>
                    <div className="py-1 text-xs px-2 flex justify-between">
                      <div className="py-2 text-xs pt-2 w-1/3">
                        <div className="font-semibold">Section Name</div>
                        <div className="mt-1">{selecteduser?.section_name}</div>
                      </div>
                      <div className="text-xs pt-2 w-1/3">
                        <div className="font-semibold">Plant Name</div>
                        <div className="mt-1">{selecteduser?.plant_name}</div>
                      </div>
                      <div className="text-xs  pt-2 w-1/3">
                        <div className="font-semibold">Code</div>
                        <div className="mt-1">{selecteduser?.code}</div>
                      </div>
                    </div>
                    <div className="py-1 text-xs px-2 flex justify-between">
                      <div className="py-2 text-xs pt-2 w-1/3">
                        <div className="font-semibold">Code</div>
                        <div className="mt-1">{selecteduser?.code}</div>
                      </div>
                      <div className="text-xs pt-2 w-1/3">
                        <div className="font-semibold">NCP</div>
                        <div className="mt-1">{selecteduser?.ncp}</div>
                      </div>
                      <div className="text-xs  pt-2 w-1/3">
                        <div className="font-semibold">UAN No.</div>
                        <div className="mt-1">{selecteduser?.uan_no}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Salary;
