import React, { useEffect, useState } from "react";
import Header from "../Home/Header/Header";
import { useNavigate, useParams } from "react-router";
import Giveattendence from "./Giveattendence";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { read, utils, writeFileXLSX } from "xlsx";
import { FiDownloadCloud } from "react-icons/fi";
import {
  AiOutlineClose,
  AiOutlineLeft,
  AiOutlineLogout,
  AiOutlineMinus,
  AiOutlinePlus,
  AiOutlineRight,
  AiOutlineSearch,
  AiOutlineUser,
} from "react-icons/ai";
import { IoIosNotificationsOutline, IoMdArrowDropdown } from "react-icons/io";
import { BsArrowRight, BsArrowUpRight, BsCurrencyRupee } from "react-icons/bs";
import { TfiExport } from "react-icons/tfi";
import Tablerow from "./Tablerow";

function Attendence() {
  const dispatch = useDispatch();
  const { plant } = useParams();
  const activesection = useSelector((state) => state.activesection);
  const employeedata = useSelector((state) => state.employee);
  const user = useSelector((state) => state?.user);
  const att = useSelector((state) => state.attendence);
  const sections = useSelector((state) => state.sections);
  const [monthdays, setMonthdays] = useState(0);
  const [fontsize, setFontsize] = useState(8);
  const [attendence, setAttendence] = useState(att);
  const [employee, setEmployee] = useState([]);
  const [allemployee, setAllemployee] = useState([]);
  const [employeeId, setEmployeeId] = useState("");
  const [attendanceDate, setAttendanceDate] = useState("");
  const [currentMonth, setCurrentMonth] = useState("");
  const [currentYear, setCurrentYear] = useState("");
  const [shift, setShift] = useState("Day");
  const [clientX, setClientX] = useState(0);
  const [clientY, setClientY] = useState(0);
  const [showoption, setShowoption] = useState(false);
  const [totalpayoff, setTotalpayoff] = useState(0);
  const [totalworkingdays, setTotalworkingdays] = useState(0);
  const [totalworkingdaysoff, setTotalworkingdaysoff] = useState(0);
  const [selecteddetail, setSelectdetail] = useState({});
  const [selectcolor, setSelectcolor] = useState({});

  const handleSubmit = async () => {
    const form = new FormData();
    form.append("employee_id", employeeId);
    form.append("attendance_date", attendanceDate);
    form.append("shift", shift);

    const request = await axios.post(
      "https://saizoous.com/zoousbackend/Attendence/Giveattendance.php",
      form
    );
    return request;
  };

  function getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }

  useEffect(() => {
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1;

    const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);
    setMonthdays(daysInCurrentMonth);
    if (currentMonth < 10) {
      setCurrentMonth(`0${currentMonth}`);
    } else {
      setCurrentMonth(currentMonth);
    }
    setCurrentYear(currentYear);
  }, []);

  const handleemployee = async () => {
    const request = await axios
      .get("https://saizoous.com/zoousbackend/employee/Allemployee.php")
      .then((result) => {
        setAllemployee(result.data.data);
        const employees = result.data.data.filter(
          (state) => state.section_name.toUpperCase() === activesection
        );
        setEmployee(employees);
      });
    return request;
  };

  const handleattendencefilter = (employee, idx) => {
    const filterattendance = attendence.filter(
      (state) =>
        state.employee_id == employee.id &&
        state.attendance_date === `${idx}-${currentMonth}-${currentYear}` &&
        state.shift == "Present"
    );
    if (filterattendance.length) {
      return filterattendance[0];
    } else {
      return { color: "white", shift: false };
    }
  };

  const handleemployeebysection = async (section) => {
    dispatch({
      type: "activesection",
      payload: section.section_name,
    });
    setEmployee(
      allemployee.filter(
        (employee) =>
          employee.section_name.toUpperCase() ===
          section.section_name.toUpperCase()
      )
    );
  };

  const getemployeepresents = (employee) => {
    const filterattendance = attendence.filter(
      (state) =>
        state.employee_id == employee.id &&
        state.attendance_date.split("-")[1] === `${currentMonth}` &&
        state.attendance_date.split("-")[2] === `${currentYear}` &&
        state.shift == "Present"
    );
    return filterattendance.length;
  };

  const handlesearch = (e) => {
    let text = e.target.value;
    if (e.target.value) {
      setEmployee(
        employee.filter(
          (state) =>
            state.employee_id.toLowerCase().startsWith(text) ||
            state.employee_name
              ?.split(" ")[0]
              ?.toLowerCase()
              ?.startsWith(text) ||
            state.employee_name
              ?.split(" ")[1]
              ?.toLowerCase()
              ?.startsWith(text) ||
            state.Phone_number.startsWith(text) ||
            state.care_of.startsWith(text) ||
            state.Aadhar_number.startsWith(text)
        )
      );
    } else {
      setEmployee(
        allemployee.filter(
          (employee) =>
            employee.section_name.toUpperCase() === activesection.toUpperCase()
        )
      );
    }
  };

  const handledispatchattendence = async () => {
    var selectElement = document.getElementById("attendence_type");
    var selectedValue = selectElement.value;
    const formdata = new FormData();
    formdata.append("employee_id", selecteddetail.employee.id);
    formdata.append("Changedby", user.Email.toLowerCase());
    formdata.append("color", selectcolor);
    formdata.append("attendance_date", selecteddetail.date);
    formdata.append("shift", selectedValue);
    const handlerequest = await axios
      .post(
        "https://saizoous.com/zoousbackend/Attendence/Giveattendance.php",
        formdata
      )
      .then((result) => {
        window.location.reload();
        // console.log(result);
        // handlerequest();
      })
      .catch((err) => {
        console.log(err);
      });

    setShowoption(false);
    return handlerequest;
  };

  const handlerequest = async () => {
    const request = await axios
      .get("https://saizoous.com/zoousbackend/Attendence/Attendance.php")
      .then((result) => {
        let totalpayable = 0;
        setTotalworkingdays(
          result.data.attendance.filter(
            (state) =>
              state.shift == "Present" &&
              state.attendance_date.split("-")[1] === `${currentMonth}` &&
              state.attendance_date.split("-")[2] === `${currentYear}`
          ).length
        );
        setTotalworkingdaysoff(
          result.data.attendance.filter(
            (state) =>
              state.shift == "Absent" &&
              state.attendance_date.split("-")[1] === `${currentMonth}` &&
              state.attendance_date.split("-")[2] === `${currentYear}`
          ).length
        );
        result.data.uniqueIds.forEach((item) => {
          let getemployee = employeedata.filter((state) => state.id == item)[0];
          let getAttendance = result.data.attendance.filter(
            (state) =>
              state.employee_id == item &&
              state.attendance_date.split("-")[1] === `${currentMonth}` &&
              state.attendance_date.split("-")[2] === `${currentYear}`
          );
          console.log(getemployee);

          totalpayable +=
            getemployee.Basic_salary * getAttendance.length +
            getemployee.Basic_salary * 4;
        });
        setTotalpayoff(totalpayable);
        setAttendence(result.data.attendance);
      });
    return request;
  };

  const attendSheetforXl = (employee, index) => {
    if (employee.hasOwnProperty("shift") && employee.shift === "Present") {
      return employee.Basic_salary;
    } else {
      return "not present";
    }
  };

  const getemployeeatten = (employee) => {
    let ispresent = Array(monthdays)
      .fill()
      .map((_, i) => attendSheetforXl(employee, i + 1));

    const employeeData = employee.map((employee, index) => [
      index + 1,
      employee.employee_name,
      ...ispresent,
      employee.designation,
      employee.salary,
      employee.Basic_salary,
      getemployeepresents(employee),
      "4",
      getemployeepresents(employee) + 4,
      parseInt((getemployeepresents(employee) + 4) * employee?.Basic_salary),
      "",
      parseInt((getemployeepresents(employee) + 4) * employee?.Basic_salary),

      "",

      employee.Account_number,
      employee.Ifsc_code,
      employee.bank_name,
    ]);
    return employeeData;
  };

  const downloadWorkbook = () => {
    // console.log(allemployee);
    const groupedData = {};
    allemployee.forEach((entry, index) => {
      const companyId = entry.section_name.toUpperCase();

      if (!groupedData[companyId]) {
        groupedData[companyId] = [];
      }
      groupedData[companyId].push(entry);
    });
    const wb = utils.book_new();
    for (const department in groupedData) {
      const employees = groupedData[department];
      let attendence = getemployeeatten(employees);

      let ismonths = Array(monthdays)
        .fill()
        .map((_, i) => `Days_${i + 1}`);

      const header = [
        "Id.No",
        "Name",
        ...ismonths,
        "Designation",
        "Basic_Salary",
        "Salary Per day",
        "No. of Days Present",
        "No. of Days offs",
        "Total Duties",
        "Total Amount",
        "Penalty",
        "Earned Amount",
        "Amount",
        "Account Number",
        "IFSC Code",
        "Branch",
      ];
      const ws = utils.aoa_to_sheet([header, ...attendence]);

      utils.book_append_sheet(wb, ws, department);
    }
    writeFileXLSX(wb, `data.xlsx`);
  };

  const getdatabycompany = () => {};

  useEffect(() => {
    handlerequest();
    getdatabycompany();
  }, [employeedata]);

  useEffect(() => {
    handleemployee();
  }, []);
  return (
    <>
      <div className="w-ful bg-[#f7f7f7]">
        <Header />

        <div className="attendence-container min-h-screen px-8 py-6 relative">
          <div className="flex items-center justify-between z-10 relative">
            <div
              style={{
                fontFamily: `'Rubik', sans-serif`,
              }}
              className="text-white text-2xl"
            >
              Attendance
            </div>
            <div className="flex items-center justify-between">
              <div className="mx-2 text-white text-xs py-2 rounded-lg px-3 border border-white/60">
                Date range
              </div>
              <div className="flex items-center justify-center mx-2 text-white text-xs py-2 rounded-lg px-3 border border-white/60">
                <TfiExport className="mx-1" />
                Export
              </div>
            </div>
          </div>
          <div className="flex items-center justify-start z-10 relative my-3">
            <div className="py-1.5 rounded-md px-3 border border-white/20 flex items-center justify-center">
              <span className="text-white/60 text-xs">Showing:</span>{" "}
              <div className="text-white/90 px-2 text-xs flex items-center">
                This month <IoMdArrowDropdown className="ml-1" fontSize={18} />
              </div>
            </div>
          </div>
          <div
            style={{
              fontFamily: `'Rubik', sans-serif`,
            }}
            className="w-full z-10 relative flex items-center justify-between my-3"
          >
            <div className="flex items-start flex-col py-4 bg-white w-1/4  px-6 mr-3  rounded-md">
              <div className="p-3 my-2 bg-[#ebffed] shadow-sm rounded-md">
                <BsCurrencyRupee />
              </div>
              <div className="text-3xl mx-1 my-1 font-semibold">
                {parseInt(totalpayoff)}
              </div>
              <div className="text-xs text-black/70 mx-1">Total Amount</div>
            </div>
            <div className="flex items-start flex-col py-4 bg-white w-1/4 px-6  mx-3 rounded-md">
              <div className="p-3 my-2 bg-[#cdd2ff] shadow-sm rounded-md">
                <AiOutlineUser />
              </div>
              <div className="text-3xl mx-1 my-1 font-semibold">
                {employee.length}
              </div>
              <div className="text-xs text-black/70 mx-1">Total workers</div>
            </div>
            <div className="flex items-start flex-col py-4 bg-white w-1/4  px-6 mx-3 rounded-md">
              <div className="p-3 my-2 bg-[#feffd3] shadow-sm rounded-md">
                <AiOutlineUser />
              </div>
              <div className="text-3xl mx-1 my-1 font-semibold">
                {totalworkingdays}
              </div>
              <div className="text-xs text-black/70 mx-1">
                Total Working days
              </div>
            </div>
            <div className="flex items-start flex-col py-4 bg-white w-1/4  px-6 ml-3 rounded-md">
              <div className="p-3 my-2 bg-[#eaffcd] shadow-sm rounded-md">
                <AiOutlineUser />
              </div>
              <div className="text-3xl mx-1 my-1 font-semibold">
                {totalworkingdaysoff}
              </div>
              <div className="text-xs text-black/70 mx-1">Total Off days</div>
            </div>
          </div>
          <div
            className="w-full py-2 px-3 bg-white rounded-md z-10 relative flex items-center flex-wrap flex-row"
            style={{
              fontFamily: `'Rubik', sans-serif`,
            }}
          >
            {sections.map((sections) => {
              return (
                <div
                  onClick={() => handleemployeebysection(sections)}
                  className={`select-none text-xs py-2 px-4 border border-black/60 my-1 mx-1 rounded-md ${
                    sections.section_name === activesection
                      ? "bg-black text-white"
                      : "bg-white text-black"
                  }  hover:text-white hover:bg-black cursor-pointer`}
                >
                  {sections?.section_name}
                </div>
              );
            })}
          </div>
          <div
            className="w-full  min-h-10 my-4  z-10 relative rounded-md"
            style={{
              fontFamily: `'Rubik', sans-serif`,
            }}
          >
            {showoption && (
              <div
                className={`bg-[#ebffed] shadow-2xl w-72 justify-between fixed  rounded-full flex items-center p-1.5 border border-black/10`}
                style={{
                  zIndex: 100000,
                  left: `${clientX - 150}px`,
                  top: `${clientY - 60}px`,
                }}
              >
                <div
                  className="bg-[#f7dc6f] mx-0.5 p-2 border border-black/30 rounded-full cursor-pointer"
                  onClick={(e) => {
                    setSelectcolor(
                      e.target.classList[0]
                        .split("[")[1]
                        .slice(
                          0,
                          e.target.classList[0].split("[")[1].length - 1
                        )
                    );
                  }}
                ></div>
                <div
                  className="bg-[#82e0aa] mx-0.5 p-2 border border-black/30 rounded-full cursor-pointer"
                  onClick={(e) => {
                    setSelectcolor(
                      e.target.classList[0]
                        .split("[")[1]
                        .slice(
                          0,
                          e.target.classList[0].split("[")[1].length - 1
                        )
                    );
                  }}
                ></div>
                <div
                  className="bg-[#e74c3c] mx-0.5 p-2 border border-black/30 rounded-full cursor-pointer"
                  onClick={(e) => {
                    setSelectcolor(
                      e.target.classList[0]
                        .split("[")[1]
                        .slice(
                          0,
                          e.target.classList[0].split("[")[1].length - 1
                        )
                    );
                  }}
                ></div>
                <div className="mx-0.5 border border-black/30 rounded-full">
                  <IoMdArrowDropdown />
                </div>
                <div
                  className="text-xs bg-slate-200 flex items-center mx-2 px-2 py-1.5 justify-center rounded-md relative"
                  style={{
                    fontSize: 11,
                  }}
                >
                  <select
                    id="attendence_type"
                    className="relative w-full h-full"
                  >
                    <option value="Present">Present</option>
                    <option value="Absent">Absent</option>
                    <option value="Half day">Half day</option>
                  </select>
                </div>
                <div
                  className="text-xs bg-slate-200 mx-1 p-1 rounded-full"
                  onClick={() => {
                    setShowoption(false);
                  }}
                >
                  <AiOutlineClose />
                </div>{" "}
                <div
                  onClick={handledispatchattendence}
                  className="mx-2 hover:bg-slate-200 px-2.5 py-1 cursor-pointer rounded-full"
                  style={{
                    fontSize: 11,
                  }}
                >
                  Done
                </div>
              </div>
            )}
            <div className="mb-3 bg-white rounded-md flex items-center justify-between shadow-lg">
              <div className="flex items-center w-full">
                <div className="h-12 w-full flex items-center bg-white mx-4">
                  <div className="pl-2">
                    <AiOutlineSearch color="black" fontSize={20} />
                  </div>
                  <input
                    onChange={handlesearch}
                    type="text"
                    placeholder="Search"
                    className="w-full items-center h-full bg-transparent  text-black text-sm outline-none px-2"
                  />
                </div>
                <div className="mx-2 flex items-center border border-black/30">
                  <div
                    onClick={() => {
                      setFontsize(fontsize - 2);
                    }}
                    className="py-1 px-2 border-r border-r-black "
                  >
                    <AiOutlineMinus fontSize={12} />
                  </div>
                  <div className="px-3 text-xs">{fontsize}</div>
                  <div
                    onClick={() => {
                      setFontsize(fontsize + 2);
                    }}
                    className="py-1 px-2  border-l border-l-black"
                  >
                    <AiOutlinePlus fontSize={12} />
                  </div>
                </div>
                <div
                  onClick={downloadWorkbook}
                  className="flex items-center text-sm capitalize justify-center  py-1.5 px-3.5 w-52 h-12 bg-black text-white"
                >
                  <FiDownloadCloud
                    fontSize={20}
                    color="white"
                    className="mr-1"
                  />
                  <span
                    style={{
                      fontFamily: "Inter",
                      fontSize: "11px",
                    }}
                  >
                    Download CSV
                  </span>
                </div>
              </div>
            </div>
            <div className="px-4 bg-white py-4 rounded-md">
              <div className="w-full min-h-0 overflow-x-auto">
                <table className="w-full attendace-table">
                  <thead className="border-y border-black/60">
                    <tr>
                      <th
                        className="text-xs px-3"
                        style={{
                          width: 50,
                        }}
                      >
                        ID. NO.
                      </th>
                      <th
                        className="text-xs px-3"
                        style={{
                          width: 100,
                        }}
                      >
                        Name
                      </th>
                      {Array(monthdays)
                        .fill()
                        .map((_, i) => (
                          <th
                            onClick={() => {
                              window.location.assign(
                                `${plant}/attendance/comparsion/${i + 1}`
                              );
                            }}
                            className="cursor-pointer date-series"
                            style={{
                              fontSize: fontsize,
                            }}
                          >
                            {i + 1}
                            <div className="date-arrow">
                              <BsArrowUpRight />
                            </div>
                          </th>
                        ))}
                      <th
                        className="text-xs px-3 font-medium"
                        style={{
                          fontSize: 10,
                          width: 90,
                        }}
                      >
                        Desgination
                      </th>
                      <th
                        className="text-xs px-3 font-medium"
                        style={{
                          fontSize: 10,
                          width: 90,
                        }}
                      >
                        Basic Salary
                      </th>
                      <th
                        className="text-xs px-3 font-medium"
                        style={{
                          fontSize: 10,
                          width: 90,
                        }}
                      >
                        Salary Per day
                      </th>
                      <th
                        className="text-xs px-3 font-medium"
                        style={{
                          fontSize: 10,
                          width: 120,
                        }}
                      >
                        No. of Days Present
                      </th>
                      <th
                        className="text-xs px-3 font-medium"
                        style={{
                          fontSize: 10,
                          width: 120,
                        }}
                      >
                        No. of Days offs{" "}
                      </th>
                      <th
                        className="text-xs px-3 font-medium"
                        style={{
                          fontSize: 10,
                          width: 120,
                        }}
                      >
                        Total Duties{" "}
                      </th>
                      <th
                        className="text-xs px-3 font-medium"
                        style={{
                          fontSize: 10,
                          width: 120,
                        }}
                      >
                        Total Amount
                      </th>
                      <th
                        className="text-xs px-3 font-medium"
                        style={{
                          fontSize: 10,
                          width: 120,
                        }}
                      >
                        Penalty
                      </th>
                      <th
                        className="text-xs px-3 font-medium"
                        style={{
                          fontSize: 10,
                          width: 120,
                        }}
                      >
                        Earned Amount
                      </th>
                      <th
                        className="text-xs px-3 font-medium"
                        style={{
                          fontSize: 10,
                          width: 120,
                        }}
                      >
                        Amount
                      </th>
                      <th
                        className="text-xs px-3 font-medium"
                        style={{
                          fontSize: 10,
                          width: 120,
                        }}
                      >
                        Bank Name
                      </th>
                      <th
                        className="text-xs px-3 font-medium"
                        style={{
                          fontSize: 10,
                          width: 120,
                        }}
                      >
                        Account Number
                      </th>
                      <th
                        className="text-xs px-3 font-medium"
                        style={{
                          fontSize: 10,
                          width: 120,
                        }}
                      >
                        Ifsc Code
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {employee.map((employee, index) => (
                      <tr
                      // className="border-y border-y-black"
                      >
                        <td
                          className=" font-light px-2"
                          style={{
                            fontSize: 9,
                          }}
                        >
                          {employee.employee_id}
                        </td>
                        <td
                          className="text-xs font-light"
                          style={{
                            fontSize: fontsize + 2,
                          }}
                        >
                          {employee.employee_name.slice(0, 14)}
                        </td>
                        {Array(monthdays)
                          .fill()
                          .map((_, i) => (
                            <td
                              onClick={(e) => {
                                // console.log(e, i, employee);
                                setShowoption(true);
                                setClientX(e.clientX);
                                setClientY(e.clientY);
                                setSelectdetail({
                                  date: `${
                                    i + 1
                                  }-${currentMonth}-${currentYear}`,
                                  employee: employee,
                                });
                                // console.log({
                                //   date: `${i + 1}-07-2023`,
                                //   employee: employee,
                                // });
                              }}
                              className={`text-xs font-light relative`}
                              style={{
                                backgroundColor: handleattendencefilter(
                                  employee,
                                  i + 1
                                )?.color,
                                fontSize: fontsize,
                              }}
                            >
                              {handleattendencefilter(employee, i + 1)?.shift &&
                                employee.Basic_salary.slice(0, 3)}
                            </td>
                          ))}
                        <td
                          className="text-xs px-3 font-medium"
                          style={{
                            fontSize: 10,
                            width: 90,
                          }}
                        >
                          {employee?.designation}
                        </td>
                        <td
                          className="text-xs px-3 font-medium"
                          style={{
                            fontSize: 10,
                            width: 90,
                          }}
                        >
                          {employee?.salary}
                        </td>
                        <td
                          className="text-xs px-3 font-medium"
                          style={{
                            fontSize: 10,
                            width: 90,
                          }}
                        >
                          {employee?.Basic_salary}
                        </td>
                        <td
                          className="text-xs px-3 font-medium"
                          style={{
                            fontSize: 10,
                            width: 120,
                          }}
                        >
                          {getemployeepresents(employee)}
                        </td>
                        <td
                          className="text-xs px-3 font-medium"
                          style={{
                            fontSize: 10,
                            width: 120,
                          }}
                        >
                          4
                        </td>
                        <td
                          className="text-xs px-3 font-medium"
                          style={{
                            fontSize: 10,
                            width: 120,
                          }}
                        >
                          {getemployeepresents(employee) + 4}
                        </td>
                        <td
                          className="text-xs px-3 font-medium"
                          style={{
                            fontSize: 10,
                            width: 120,
                          }}
                        >
                          {parseInt(
                            (getemployeepresents(employee) + 4) *
                              employee?.Basic_salary
                          )}
                        </td>
                        <td
                          className="text-xs px-3 font-medium"
                          style={{
                            fontSize: 10,
                            width: 120,
                          }}
                        ></td>
                        <td
                          className="text-xs px-3 font-medium"
                          style={{
                            fontSize: 10,
                            width: 120,
                          }}
                        >
                          {parseInt(
                            (getemployeepresents(employee) + 4) *
                              employee?.Basic_salary
                          )}
                        </td>
                        <td
                          className="text-xs px-3 font-medium"
                          style={{
                            fontSize: 10,
                            width: 120,
                          }}
                        ></td>
                        <td
                          className="text-xs px-3 font-medium"
                          style={{
                            fontSize: 10,
                            width: 120,
                          }}
                        >
                          {employee.bank_name}
                        </td>
                        <td
                          className="text-xs px-3 font-medium"
                          style={{
                            fontSize: 10,
                            width: 120,
                          }}
                        >
                          {parseInt(
                            (getemployeepresents(employee) + 4) *
                              employee?.Basic_salary
                          )}
                        </td>
                        <td
                          className="text-xs px-3 font-medium"
                          style={{
                            fontSize: 10,
                            width: 120,
                          }}
                        ></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Attendence;
