import React from "react";
import Header from "../../Home/Header/Header";
import { useSelector } from "react-redux";
import Logo from "../../../assets/image/SAI.png";
import { useState } from "react";
import validator from "validator";
import axios from "axios";
import { useEffect } from "react";
import { Navigate, useParams } from "react-router";

function InchargeEdituser() {
  const { userid } = useParams();
  const [incharge, setIncharge] = useState([]);
  const [loading, setLoading] = useState(false);
  const plants = useSelector((state) => state?.plants);
  const [name, setName] = useState("");
  const [useremail, setUseremail] = useState("");
  const [usergender, setUsergender] = useState("");
  const [userphone, setUserphone] = useState("");
  const [person, setPerson] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [address, setAddress] = useState("");
  const [dateofjoin, setDateofjoin] = useState("");
  const [dateofexit, setDateofexit] = useState("");
  const [careof, setCareof] = useState("");
  const [account, setAccount] = useState("");
  const [userBranch, setUserBranch] = useState("");
  const [userifsc, setUserifsc] = useState("");
  const [useresi, setUseresi] = useState("");
  const [usersalary, setUsersalary] = useState("");
  const [usercomission, setUsercomission] = useState("");
  const [section, setSection] = useState("");
  const [contractor, setContractor] = useState("");
  const [department, setDepartment] = useState("");
  const [pf, setPf] = useState("");
  const [employeeid, setEmployeeid] = useState("");
  const [validemail, setValidemail] = useState(true);
  const [role, setRole] = useState("");

  const handlesubmit = async () => {
    const form = new FormData();
    form.append("name", name);
    form.append("id", employeeid);
    form.append("phone", userphone);
    form.append("gender", usergender);
    form.append("address", address);
    form.append("aadhar", aadhar);
    form.append("doj", dateofjoin);
    form.append("doe", dateofexit);
    form.append("c_o", careof);
    form.append("role", role);
    form.append("dept", department);
    form.append("section", section);
    form.append("bank", userBranch);
    form.append("ifsc", userifsc);
    form.append("acc", account);
    form.append("pf", pf);
    form.append("esi", useresi);
    form.append("salary", usersalary);
    form.append("comission", usercomission);
    setLoading(true);
    const request = await axios.post(
      "https://saizoous.com/zoousbackend/employee/update_incharge.php",
      form
    );
    console.log(request);
    setLoading(false);
    window.location.assign("/mudhivarthi/Biodata/incharge/");
    return request;
  };
  const handleincharge = async () => {
    const request = await axios
      .post("https://saizoous.com/zoousbackend/employee/incharge.php")
      .then((request) => {
        console.log(request.data.data);
        const handlefilteruser = request.data.data.filter(
          (state) => state.id == userid
        )[0];
        setName(handlefilteruser.name);
        setUsergender(handlefilteruser.gender);
        setName(handlefilteruser.name);
        setUseremail(handlefilteruser.person);
        setEmployeeid(handlefilteruser.id);
        setPerson(handlefilteruser.person);
        setUsergender(handlefilteruser.gender);
        setUserphone(handlefilteruser.phone);
        setAadhar(handlefilteruser.aadhar);
        setAddress(handlefilteruser.address);
        setAccount(handlefilteruser.acc);
        setDateofjoin(handlefilteruser.doj);
        setDateofexit(handlefilteruser.doe);
        setCareof(handlefilteruser.care);
        setPf(handlefilteruser.pf);
        setUserBranch(handlefilteruser.bank);
        setUserifsc(handlefilteruser.ifsc);
        setUseresi(handlefilteruser.esi);
        setSection(handlefilteruser.section);
        setUsersalary(handlefilteruser.salary);
        setUsercomission(handlefilteruser.incharge_comission);
        setContractor(handlefilteruser.contractor);
        setDepartment(handlefilteruser.dept);
        setRole(handlefilteruser.role);
        setIncharge(request.data.data);
        // return request;
      });
    // return request;
  };
  useEffect(() => {
    handleincharge();
  }, []);
  return (
    <>
      {loading ? (
        <div className="w-screen h-screen flex items-center justify-center">
          <div
            className="loadingspinner"
            style={{
              width: "80px",
              height: "80px",
              borderRadius: "50%",
              border: "8px solid black",
              borderTopColor: "rgb(0,0,0,0.12)",
            }}
          ></div>
        </div>
      ) : (
        <div
          className="py-2 min-h-screen flex items-center flex-col"
          style={{
            maxWidth: "100vw",
            zIndex: "100000",
            backgroundColor: "white",
          }}
        >
          <div className="flex items-center h-10">
            <div
              style={{
                width: "80px",
                height: "35px",
              }}
            >
              <img
                src={Logo}
                alt="logo"
                className="font-semibold w-full object-contain h-full"
              />
            </div>
            <h2 className="font-semibold font-[Inter]">ZOOUS Solution</h2>
          </div>
          <div className="w-1/2 h-10 bg-white my-4">
            <div className="font-thin text-2xl font-[Noto_Sans]">Edit user</div>
            <div className="border-b border-solid border-black/20 pb-3 pt-1">
              Mange your users here.
            </div>
            <div className="w-full px-3 py-4">
              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                Name
              </div>
              <input
                type="text"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
            "
                style={{
                  height: "40px",
                }}
              />
              {/* <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
            Person
          </div>
          <input
            onChange={async (e) => {
              setUseremail(e.target.value);
              let vemail = await validator.isEmail(e.target.value);
              if (vemail) {
                setValidemail(true);
              } else {
                setValidemail(false);
              }
            }}
            value={useremail}
            type="text"
            style={{
              height: "40px",

              border: `${
                validemail ? " 1px solid rgb(0,0,0,0.4)" : "1.5px solid red"
              }`,
            }}
            className="w-full border-solid border  outline-none  rounded-md text-sm px-2
            "
          /> */}
              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                Phone Number
              </div>
              <input
                value={userphone}
                onChange={(e) => {
                  setUserphone(e.target.value);
                }}
                type="text"
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
            "
                style={{
                  height: "40px",
                }}
              />
              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                Gender
              </div>
              <input
                onChange={(e) => {
                  setUsergender(e.target.value);
                }}
                value={usergender}
                type="text"
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
            "
                style={{
                  height: "40px",
                }}
              />
              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                Date of Join
              </div>
              <input
                value={dateofjoin}
                onChange={(e) => {
                  setDateofjoin(e.target.value);
                }}
                type="text"
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
            "
                style={{
                  height: "40px",
                }}
              />
              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                Date of Exit
              </div>
              <input
                value={dateofexit}
                onChange={(e) => {
                  setDateofexit(e.target.value);
                }}
                type="text"
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
            "
                style={{
                  height: "40px",
                }}
              />
              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                C/O
              </div>
              <input
                value={careof}
                onChange={(e) => {
                  setCareof(e.target.value);
                }}
                type="text"
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
            "
                style={{
                  height: "40px",
                }}
              />

              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                Address Name
              </div>
              <input
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                value={address}
                type="text"
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
            "
                style={{
                  height: "40px",
                }}
              />
              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                Aadhar Number
              </div>
              <input
                onChange={(e) => {
                  setAadhar(e.target.value);
                }}
                value={aadhar}
                type="text"
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
            "
                style={{
                  height: "40px",
                }}
              />
              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                Account Number
              </div>
              <input
                value={account}
                onChange={(e) => {
                  setAccount(e.target.value);
                }}
                type="text"
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
            "
                style={{
                  height: "40px",
                }}
              />
              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                Bank Name
              </div>
              <input
                value={userBranch}
                onChange={(e) => {
                  setUserBranch(e.target.value);
                }}
                type="text"
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
            "
                style={{
                  height: "40px",
                }}
              />
              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                IFSC No.
              </div>
              <input
                value={userifsc}
                onChange={(e) => {
                  setUserifsc(e.target.value);
                }}
                type="text"
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
            "
                style={{
                  height: "40px",
                }}
              />

              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                Section
              </div>
              <input
                value={section}
                onChange={(e) => {
                  setSection(e.target.value);
                }}
                type="text"
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
            "
                style={{
                  height: "40px",
                }}
              />

              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                Role
              </div>
              <input
                value={role}
                onChange={(e) => {
                  setRole(e.target.value);
                }}
                type="text"
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
            "
                style={{
                  height: "40px",
                }}
              />
              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                EPF Account Number
              </div>
              <input
                value={pf}
                onChange={(e) => {
                  setPf(e.target.value);
                }}
                type="text"
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
            "
                style={{
                  height: "40px",
                }}
              />
              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                ESIC Account Number
              </div>
              <input
                value={useresi}
                onChange={(e) => {
                  setUseresi(e.target.value);
                }}
                type="text"
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
            "
                style={{
                  height: "40px",
                }}
              />
              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                Basic Salary
              </div>
              <input
                value={usersalary}
                onChange={(e) => {
                  setUsersalary(e.target.value);
                }}
                type="text"
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
            "
                style={{
                  height: "40px",
                }}
              />
              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                Comission
              </div>
              <input
                value={usercomission}
                onChange={(e) => {
                  setUsercomission(e.target.value);
                }}
                type="text"
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
            "
                style={{
                  height: "40px",
                }}
              />

              <div className="flex items-center justify-end py-2">
                <button
                  onClick={handlesubmit}
                  className="py-2 px-6 bg-red-500 font-semibold rounded-sm"
                  style={{
                    fontSize: "12px",
                    color: "white",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default InchargeEdituser;
