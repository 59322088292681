export const initialstate = {
  employee: [],
  count: 0,
  user: {},
  plants: [],
  incharge: [],
  showuseredit: false,
  selectinchargeuser: {},
  selectuser: {},
  giveattendence: false,
  attendence: [],
  sections: [],
  activesection: "COMPANY GRADING",
  activeplant: "mudhivarthi",
};

export const reducer = (state = initialstate, action) => {
  switch (action.type) {
    case "allemployee":
      return {
        ...state,
        employee: action.payload,
      };
    case "user":
      return {
        ...state,
        user: action.payload,
      };
    case "counting":
      return {
        ...state,
        count: action.payload,
      };
    case "showeditmode":
      return {
        ...state,
        showuseredit: action.payload,
      };
    case "selecteduser":
      return {
        ...state,
        selectuser: action.payload,
      };
    case "selectedinchargeuser":
      return {
        ...state,
        selectinchargeuser: action.payload,
      };
    case "plants":
      return {
        ...state,
        plants: action.payload,
      };
    case "Incharge":
      return {
        ...state,
        incharge: action.payload,
      };
    case "attendence":
      return {
        ...state,
        attendence: action.payload,
      };
    case "sections":
      return {
        ...state,
        sections: action.payload,
      };
    case "activesection":
      return {
        ...state,
        activesection: action.payload,
      };
    case "activeplant":
      return {
        ...state,
        activeplant: action.payload,
      };
    default:
      return state;
  }
};
