import React, { useState } from "react";
import Logo from "../../assets/image/SAI.png";
import { useSelector } from "react-redux";
import validator from "validator";
import { useParams } from "react-router";
import { useEffect } from "react";
import axios from "axios";

function Biodataedit() {
  const { userid } = useParams();
  const [photourl, setPhotourl] = useState(null);
  const [employee_name, setEmployee_name] = useState("");
  const [employee_id, setEmployee_id] = useState("");
  const [employee_photo, setEmployee_photo] = useState(null);
  const [care_of, setCare_of] = useState("");
  const [address, setAddress] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [aadhar_number, setAadhar_number] = useState("");
  const [aadhar_front, setAadhar_front] = useState(null);
  const [aadhar_back, setAadhar_back] = useState(null);
  const [gender, setGender] = useState("");
  const [basic_salary, setBasic_salary] = useState("");
  const [date_of_joining, setDate_of_joining] = useState("");
  const [date_of_exit, setDate_of_exit] = useState("");
  const [department, setDepartment] = useState("");
  const [section_name, setSection_name] = useState("");
  const [plant, setPlant] = useState("");
  const [bank_name, setBank_name] = useState("");
  const [ifsc_code, setIfsc_code] = useState("");
  const [account_number, setAccount_number] = useState("");
  const [passbook_photo, setPassbook_photo] = useState(null);
  const [salary, setSalary] = useState("");
  const [pf, setPf] = useState("");
  const [esi_number, setEsi_number] = useState("");
  const [uan_number, setUan_number] = useState("");
  const [allowance, setAllowance] = useState("");
  const [ncp, setNcp] = useState("");
  const [incharge_commision, setIncharge_commision] = useState("");
  const sections = useSelector((state) => state.sections);

  const handleemployeepassbook = async (e) => {
    const form = new FormData();
    console.log(e.target.files[0]);
    form.append("image", e.target.files[0]);
    const employeephoto = await axios
      .post(
        "https://saizoous.com/zoousbackend/employee/Passbook/Passbook.php",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((result) => {
        console.log(result.data.image);
        setPassbook_photo(result.data.image);
      });
    console.log(employeephoto);
  };

  const handleemployeefrontaadhar = async (e) => {
    const form = new FormData();
    console.log(e.target.files[0]);
    form.append("image", e.target.files[0]);
    const employeephoto = await axios
      .post(
        "https://saizoous.com/zoousbackend/employee/Aadhar/EmployeeAadhar.php",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((result) => {
        console.log(result.data.image);
        setAadhar_front(result.data.image);
      });
    console.log(employeephoto);
  };

  const handleemployeebackaadhar = async (e) => {
    const form = new FormData();
    form.append("image", e.target.files[0]);
    const employeephoto = await axios
      .post(
        "https://saizoous.com/zoousbackend/employee/Aadhar/EmployeeAadhar.php",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((result) => {
        console.log(result.data.image);
        setAadhar_back(result.data.image);
      });
    console.log(employeephoto);
  };

  const handleemployeephoto = async (e) => {
    const form = new FormData();
    form.append("image", e.target.files[0]);
    const employeephoto = await axios
      .post(
        "https://saizoous.com/zoousbackend/employee/Photo/EmployeePhoto.php",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((result) => {
        console.log(result.data.image);
        setEmployee_photo(result.data.image);
        setPhotourl(result.data.image);
      });
    console.log(employeephoto);
  };

  const fetchemploy = async () => {
    const request = await axios
      .get("https://saizoous.com/zoousbackend/employee/Allemployee.php")
      .then((result) => {
        console.log(result.data.data);
        let filteruser = result.data.data.filter(
          (state) => state.id == userid
        )[0];

        if (filteruser) {
          setEmployee_id(filteruser.employee_id);
          setEmployee_name(filteruser.employee_name);
          setPhotourl(filteruser.photourl);
          setCare_of(filteruser.care_of);
          setAddress(filteruser.Address);
          setPhone_number(filteruser.phone_number);
          setAadhar_number(filteruser.Aadhar_number);
          setAadhar_front(filteruser.aadhar_front);
          setAadhar_back(filteruser.aadhar_back);
          setGender(filteruser.gender);
          setBasic_salary(filteruser.basic_salary);
          setDate_of_exit(filteruser.date_of_exit);
          setDate_of_joining(filteruser.date_of_joining);
          setDepartment(filteruser.department);
          setSection_name(filteruser.section_name);
          setPlant(filteruser.plant_name);
          setBank_name(filteruser.bank_name);
          setIfsc_code(filteruser.ifsc_code);
          setAccount_number(filteruser.Account_number);
          setPassbook_photo(filteruser.Passbook_photo);
          setSalary(filteruser.salary);
          setPf(filteruser.pf);
          setEsi_number(filteruser.esi_number);
          setUan_number(filteruser.uan_number);
          setAllowance(filteruser.allowance);
          setNcp(filteruser.ncp);
          setIncharge_commision(filteruser.incharge_commision);
        }
        return result;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
    return request;
  };
  useEffect(() => {
    fetchemploy();
  }, []);

  const handlerequest = async () => {};

  return (
    <div
      className=" min-h-screen flex items-center flex-col "
      style={{
        maxWidth: "100vw",
        zIndex: "100000",
        backgroundColor: "white",
        fontFamily: `'Rubik', sans-serif`,
        paddingBottom: 30,
      }}
    >
      <div className="w-2/3">
        <div className="flex items-center justify-between w-full border-b border-b-black/30 py-4 px-2 sticky top-0 bg-white">
          <div
            className="text-lg"
            style={{
              fontFamily: `'Bruno Ace SC', cursive`,
            }}
          >
            Zoous
          </div>
          <div style={{}}>Logout</div>
        </div>
        <div className="px-20">
          <div className=" py-4 text-xl capitalize">Add your employee</div>
          <div className="text-sm text-black/80">Employee Photo</div>
          <div className="py-3 px-2 flex items-center">
            <div
              className="w-16 h-16 bg-cover rounded-md"
              style={{
                backgroundImage: `url(https://saizoous.com/zoousbackend/employee/Photo/Upload/${photourl})`,
              }}
            ></div>
            <div className="mx-3">
              <div className="relative py-1 px-2 flex items-center justify-center border border-black/20 rounded-md text-sm w-32">
                Upload Image
                <input
                  type="file"
                  onChange={handleemployeephoto}
                  className="absolute left-0 right-0 top-0 bottom-0 opacity-0"
                />
              </div>
              <div className="text-xs text-black/70 mt-1 mx-1">
                .png ,.jpg file require{" "}
              </div>
            </div>
          </div>
          <div className="py-4">
            <div className="text-sm text-black/90 my-2">Employee Name</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                value={employee_name}
                onChange={(e) => {
                  setEmployee_name(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Employee ID</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                value={employee_id}
                onChange={(e) => {
                  setEmployee_id(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Phone Number</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                value={phone_number}
                onChange={(e) => {
                  setPhone_number(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Gender</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <select
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                className="text-sm w-full h-full  border-none bg-transparent  px-2 rounded-sm outline-none"
              >
                <option value="Male" className="font-serif">
                  Male
                </option>
                <option value="Female" className="font-serif">
                  Female
                </option>
                <option value="Other" className="font-serif">
                  Other
                </option>
              </select>
            </div>
            <div className="text-sm text-black/90 my-2">Care of</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                value={care_of}
                onChange={(e) => {
                  setCare_of(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Address</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Aadhar Number</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md  flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                value={aadhar_number}
                onChange={(e) => {
                  setAadhar_number(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Aadhar Photo</div>
            <div className="text-sm  w-3/3 rounded-md flex">
              <div className="py-3 px-2 flex items-center mx-8">
                <div
                  className="w-16 h-16 bg-cover rounded-md"
                  style={{
                    backgroundImage: `url(https://saizoous.com/zoousbackend/employee/Aadhar/Upload/${aadhar_front})`,
                  }}
                ></div>
                <div className="mx-3">
                  <div className="relative py-1 px-2 flex items-center justify-center border border-black/20 rounded-md text-sm w-32">
                    Aadhar front
                    <input
                      type="file"
                      onChange={handleemployeefrontaadhar}
                      className="absolute left-0 right-0 top-0 bottom-0 opacity-0"
                    />
                  </div>
                  <div className="text-xs text-black/70 mt-1 mx-1">
                    .png ,.jpg file require{" "}
                  </div>
                </div>
              </div>
              <div className="py-3 px-2 flex items-center mx-8">
                <div
                  className="w-16 h-16 bg-cover rounded-md"
                  style={{
                    backgroundImage: `url(https://saizoous.com/zoousbackend/employee/Aadhar/Upload/${aadhar_back})`,
                  }}
                ></div>
                <div className="mx-3">
                  <div className="relative py-1 px-2 flex items-center justify-center border border-black/20 rounded-md text-sm w-32">
                    Aadhar back
                    <input
                      type="file"
                      onChange={handleemployeebackaadhar}
                      className="absolute left-0 right-0 top-0 bottom-0 opacity-0"
                    />
                  </div>
                  <div className="text-xs text-black/70 mt-1 mx-1">
                    .png ,.jpg file require{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-sm text-black/90 mb-2 mt-3">
              Date of Joining
            </div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                value={date_of_joining}
                onChange={(e) => {
                  setDate_of_joining(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Date of Exit</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                value={date_of_exit}
                onChange={(e) => {
                  setDate_of_exit(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Department</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                value={department}
                onChange={(e) => {
                  setDepartment(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>

            <div className="text-sm text-black/90 my-2">Plant Name</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                value={plant}
                onChange={(e) => {
                  setPlant(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Section Name</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <select
                value={section_name}
                onChange={(e) => {
                  console.log(e.target.value);
                  setSection_name(e.target.value);
                }}
                className="text-sm w-full h-full  border-none bg-transparent  px-2 rounded-sm outline-none"
              >
                {sections.map((sections) => {
                  return (
                    <option
                      value={sections?.section_name}
                      // onClick={() => handleemployeebysection(sections)}
                      className={`
                    font-serif select-none text-sm py-2 px-4 border border-black/60 my-1 mx-1 rounded-md hover:text-white hover:bg-black cursor-pointer`}
                    >
                      {sections?.section_name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="text-sm text-black/90 my-2">Basic salary</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                value={basic_salary}
                onChange={(e) => {
                  setBasic_salary(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Account Number</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                value={account_number}
                onChange={(e) => {
                  setAccount_number(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/80 mt-2">Passbook Photo</div>
            <div className="py-3 px-2 flex items-center">
              <div
                className="w-16 h-16 bg-cover rounded-md"
                style={{
                  backgroundImage: `url(https://saizoous.com/zoousbackend/employee/Passbook/Upload/${passbook_photo})`,
                }}
              ></div>
              <div className="mx-3">
                <div className="relative py-1 px-2 flex items-center justify-center border border-black/20 rounded-md text-sm w-32">
                  Upload Image
                  <input
                    type="file"
                    onChange={handleemployeepassbook}
                    className="w-full h-full absolute left-0 right-0 top-0 bottom-0 opacity-0"
                  />
                </div>
                <div className="text-xs text-black/70 mt-1 mx-1">
                  .png ,.jpg file require{" "}
                </div>
              </div>
            </div>
            <div className="text-sm text-black/90 my-2">Bank Name</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                value={bank_name}
                onChange={(e) => {
                  setBank_name(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">IFSC Code</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                value={ifsc_code}
                onChange={(e) => {
                  setIfsc_code(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Salary</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                value={salary}
                onChange={(e) => {
                  setSalary(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">PF</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                value={pf}
                onChange={(e) => {
                  setPf(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">ESI Number</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                value={esi_number}
                onChange={(e) => {
                  setEsi_number(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">UAN Number</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                value={uan_number}
                onChange={(e) => {
                  setUan_number(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Allowance</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                value={allowance}
                onChange={(e) => {
                  setAllowance(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">NCP</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                value={ncp}
                onChange={(e) => {
                  setNcp(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Incharge commision</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                value={incharge_commision}
                onChange={(e) => {
                  setIncharge_commision(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
          </div>
          <div className="flex items-center justify-end">
            <div
              onClick={handlerequest}
              className="py-2 px-4 bg-black text-white text-sm rounded-md"
            >
              Save changes
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div
    //   className="py-2 min-h-screen flex items-center flex-col"
    //   style={{
    //     maxWidth: "100vw",
    //     zIndex: "100000",
    //     backgroundColor: "white",
    //   }}
    // >
    //   <div className="flex items-center h-10">
    //     <div
    //       style={{
    //         width: "80px",
    //         height: "35px",
    //       }}
    //     >
    //       <img
    //         src={Logo}
    //         alt="logo"
    //         className="font-semibold w-full object-contain h-full"
    //       />
    //     </div>
    //     <h2 className="font-semibold font-[Inter]">ZOOUS Solution</h2>
    //   </div>
    //   <div className="w-1/2 h-10 bg-white my-4">
    //     <div className="font-thin text-2xl font-[Noto_Sans]">Edit Biodata</div>
    //     <div className="border-b border-solid border-black/20 pb-3 pt-1">
    //       Mange your user biodata here.
    //     </div>
    //     <div className="w-full px-3 py-4">
    //       <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
    //         Name
    //       </div>
    //       <input
    //         type="text"
    //         onChange={(e) => {
    //           setName(e.target.value);
    //         }}
    //         value={name}
    //         className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
    //     "
    //         style={{
    //           height: "40px",
    //         }}
    //       />
    //       <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
    //         Gender
    //       </div>
    //       <input
    //         onChange={(e) => {
    //           setUsergender(e.target.value);
    //         }}
    //         value={usergender}
    //         type="text"
    //         style={{
    //           height: "40px",

    //           border: `${
    //             validemail ? " 1px solid rgb(0,0,0,0.4)" : "1.5px solid red"
    //           }`,
    //         }}
    //         className="w-full border-solid border  outline-none  rounded-md text-sm px-2
    //     "
    //       />
    //       <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
    //         Person
    //       </div>
    //       <input
    //         onChange={async (e) => {
    //           setPerson(e.target.value);
    //         }}
    //         value={person}
    //         type="text"
    //         style={{
    //           height: "40px",

    //           border: `${
    //             validemail ? " 1px solid rgb(0,0,0,0.4)" : "1.5px solid red"
    //           }`,
    //         }}
    //         className="w-full border-solid border  outline-none  rounded-md text-sm px-2
    //     "
    //       />
    //       <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
    //         Phone Number
    //       </div>
    //       <input
    //         value={userphone}
    //         onChange={(e) => {
    //           setUserphone(e.target.value);
    //         }}
    //         type="text"
    //         className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
    //     "
    //         style={{
    //           height: "40px",
    //         }}
    //       />
    //       <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
    //         Aadhar
    //       </div>
    //       <input
    //         onChange={(e) => {
    //           setAadhar(e.target.value);
    //         }}
    //         value={aadhar}
    //         type="text"
    //         className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
    //     "
    //         style={{
    //           height: "40px",
    //         }}
    //       />
    //       <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
    //         Account Number
    //       </div>
    //       <input
    //         value={userphone}
    //         onChange={(e) => {
    //           setUserphone(e.target.value);
    //         }}
    //         type="text"
    //         className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
    //     "
    //         style={{
    //           height: "40px",
    //         }}
    //       />
    //       <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
    //         Branch Name
    //       </div>
    //       <input
    //         value={employeeid}
    //         onChange={(e) => {
    //           setEmployeeid(e.target.value);
    //         }}
    //         type="text"
    //         className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
    //     "
    //         style={{
    //           height: "40px",
    //         }}
    //       />
    //       <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
    //         IFSC No.
    //       </div>
    //       <input
    //         value={userphone}
    //         onChange={(e) => {
    //           setUserphone(e.target.value);
    //         }}
    //         type="text"
    //         className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
    //     "
    //         style={{
    //           height: "40px",
    //         }}
    //       />
    //       <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
    //         PF
    //       </div>
    //       <input
    //         value={employeeid}
    //         onChange={(e) => {
    //           setEmployeeid(e.target.value);
    //         }}
    //         type="text"
    //         className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
    //     "
    //         style={{
    //           height: "40px",
    //         }}
    //       />
    //       <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
    //         ESI
    //       </div>
    //       <input
    //         onChange={(e) => {
    //           setPasswordconfirm(e.target.value);
    //         }}
    //         value={passwordconfirm}
    //         type="text"
    //         className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
    //     "
    //         style={{
    //           height: "40px",
    //         }}
    //       />
    //       <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
    //         Salary
    //       </div>
    //       <input
    //         value={userphone}
    //         onChange={(e) => {
    //           setUserphone(e.target.value);
    //         }}
    //         type="text"
    //         className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
    //     "
    //         style={{
    //           height: "40px",
    //         }}
    //       />
    //       <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
    //         Contractor
    //       </div>
    //       <input
    //         value={employeeid}
    //         onChange={(e) => {
    //           setEmployeeid(e.target.value);
    //         }}
    //         type="text"
    //         className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
    //     "
    //         style={{
    //           height: "40px",
    //         }}
    //       />
    //       <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
    //         Department
    //       </div>
    //       <input
    //         value={userphone}
    //         onChange={(e) => {
    //           setUserphone(e.target.value);
    //         }}
    //         type="text"
    //         className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
    //     "
    //         style={{
    //           height: "40px",
    //         }}
    //       />
    //       <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
    //         Role
    //       </div>
    //       <input
    //         value={employeeid}
    //         onChange={(e) => {
    //           setEmployeeid(e.target.value);
    //         }}
    //         type="text"
    //         className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
    //     "
    //         style={{
    //           height: "40px",
    //         }}
    //       />
    //       <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
    //         Section
    //       </div>
    //       <input
    //         onChange={(e) => {
    //           setPasswordconfirm(e.target.value);
    //         }}
    //         value={passwordconfirm}
    //         type="text"
    //         className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
    //     "
    //         style={{
    //           height: "40px",
    //         }}
    //       />
    //       <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
    //         Area
    //       </div>
    //       <input
    //         value={userphone}
    //         onChange={(e) => {
    //           setUserphone(e.target.value);
    //         }}
    //         type="text"
    //         className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
    //     "
    //         style={{
    //           height: "40px",
    //         }}
    //       />
    //       <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
    //         Code
    //       </div>
    //       <input
    //         value={employeeid}
    //         onChange={(e) => {
    //           setEmployeeid(e.target.value);
    //         }}
    //         type="text"
    //         className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
    //     "
    //         style={{
    //           height: "40px",
    //         }}
    //       />
    //       <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
    //         NCP
    //       </div>
    //       <input
    //         value={userphone}
    //         onChange={(e) => {
    //           setUserphone(e.target.value);
    //         }}
    //         type="text"
    //         className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
    //     "
    //         style={{
    //           height: "40px",
    //         }}
    //       />
    //       <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
    //         UAN No.
    //       </div>
    //       <input
    //         value={employeeid}
    //         onChange={(e) => {
    //           setEmployeeid(e.target.value);
    //         }}
    //         type="text"
    //         className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
    //     "
    //         style={{
    //           height: "40px",
    //         }}
    //       />
    //       <div className="flex items-center justify-end py-2">
    //         <button
    //           className="py-2 px-6 bg-red-500 font-semibold rounded-sm"
    //           style={{
    //             fontSize: "12px",
    //             color: "white",
    //           }}
    //         >
    //           Submit
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default Biodataedit;
