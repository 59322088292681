import React, { useState } from "react";
import Logo from "../../../assets/image/SAI.png";
import {
  AiOutlineSearch,
  AiFillSetting,
  AiOutlineLogout,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { Avatar } from "@mui/material";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import { motion } from "framer-motion";
import { useRef } from "react";
import { BsFillSunFill } from "react-icons/bs";
import { IoMdArrowDropdown } from "react-icons/io";

function Header() {
  const menuref = useRef(null);
  const navigate = useNavigate();
  const { plant } = useParams();
  const dispatch = useDispatch();
  const [showplant, setShowplant] = useState(false);
  const user = useSelector((state) => state?.user);
  const plants = useSelector((state) => state?.plants);
  const employee = useSelector((state) => state.employee);
  const [showplants, setShowplants] = useState(false);
  const [employeefilter, setEmployeefilter] = useState([]);
  // const [menuaccess, setMenuaccess] = useState(user?.menu_access?.split(","));
  const [employeeaccess, setEmployeeaccess] = useState(false);
  const [attendenceaccess, setAttendenceaccess] = useState(false);
  // const [salaryaccess, setSalaryaccess] = useState(false);
  const [useraccess, setUseraccess] = useState(false);
  // const [workreportaccess, setWorkreportaccess] = useState(false);
  // const [pfaccess, setPfaccess] = useState(false);
  // const [priceaccess, setPriceaccess] = useState(false);
  // const [plantaccess, setPlantaccess] = useState(false);

  const handleemploysearch = (e) => {
    console.log(e.target.value);
  };

  useEffect(() => {
    if (
      user?.menu_access?.includes("emp_data") ||
      user?.menu_access?.includes("inc_data") ||
      user?.menu_access?.includes("guest_data")
    ) {
      setEmployeeaccess(true);
    }
    if (
      user?.menu_access?.includes("give_att") ||
      user?.menu_access?.includes("report_att")
    ) {
      setAttendenceaccess(true);
      // console.log("yes here");
    }
    if (
      user?.menu_access?.includes("adduser") ||
      user?.menu_access?.includes("searchuser")
    ) {
      setUseraccess(true);
    }
  }, [user.length]);

  useEffect(() => {
    document.addEventListener("mousedown", (e) => {
      if (showplants) {
        if (menuref) {
          if (!menuref?.current?.contains(e.target)) {
            setShowplants(false);
          }
        }
      }
    });
  }, []);

  const handleclick = (e) => {
    let headeroption = document.getElementsByClassName("rp-urls-container");

    for (let i = 0; i < headeroption.length; i++) {
      headeroption[i].style.backgroundColor = "white";
      headeroption[i].style.color = "black";
    }
    e.target.style.backgroundColor = "white";
    e.target.style.color = "black";
  };

  return (
    <div className="w-full header">
      <div className="flex items-center justify-between py-3 bg-[white] w-full px-8">
        <div className="flex items-center">
          <div
            className="text-black text-lg"
            style={{
              fontFamily: `'Bruno Ace SC', cursive`,
            }}
          >
            Zoous
          </div>
        </div>
        <div
          className="flex items-center"
          style={{
            fontFamily: `'Rubik', sans-serif`,
          }}
        >
          <Link to={`/${user?.id}/permissions`}>
            <div
              className="text-sm text-black mx-2"
              style={{
                fontFamily: `'Rubik', sans-serif`,
              }}
            >
              Permissions
            </div>
          </Link>
          <Link to={`/${plant}/user`}>
            <div
              className="text-sm text-black mx-8"
              style={{
                fontFamily: `'Rubik', sans-serif`,
              }}
            >
              Access manager
            </div>
          </Link>
          <div
            className="text-black text-sm mx-6 flex items-center justify-center w-32 relative"
            onClick={() => {
              setShowplant(!showplant);
            }}
          >
            Mudhivarti <IoMdArrowDropdown className="mx-2" />
            {/* <div className="absolute top-8 w-full min-h-0 z-10 bg-white">
              {plants.map((plant) => {
                return <div className="w-full">{plant.plant_name}</div>;
              })}
            </div> */}
          </div>
          <div
            className="border border-black/80 rounded-full"
            style={{
              padding: "6px 18px",
            }}
          >
            <div className="text-black text-xs">
              {user?.Name?.split(" ")[0]}
            </div>
          </div>
        </div>
      </div>
      <div
        className=" bg-[black] border-b border-b-white/20 shadow-md px-2  flex items-center justify-start"
        style={{
          fontFamily: `'Rubik', sans-serif`,
        }}
      >
        <Link to={`/${user?.plant?.toLowerCase()}/Biodata`}>
          <div className="text-sm text-white/80 hover:text-black hover:bg-white py-2.5 px-6 cursor-pointer">
            Biodata
          </div>
        </Link>
        <Link to={`/${user?.plant?.toLowerCase()}/Attendence/`}>
          <div className="text-xs text-white/80 hover:text-black hover:bg-white py-3 px-6 cursor-pointer">
            Attendance
          </div>
        </Link>
        <Link to={`/${user?.plant?.toLowerCase()}/Employee/salary`}>
          <div className="text-xs text-white/80 hover:text-black hover:bg-white py-3 px-6 cursor-pointer">
            Salary
          </div>
        </Link>
        {/* <Link to={`/${user?.plant?.toLowerCase()}/user`}>
          <div className="text-xs text-white/80 hover:text-black hover:bg-white py-3 px-6 cursor-pointer">
            User
          </div>
        </Link> */}
        <Link to={`/${user?.plant?.toLowerCase()}/price`}>
          <div className="text-xs text-white/80 hover:text-black hover:bg-white py-3 px-6 cursor-pointer">
            Prices
          </div>
        </Link>
        <Link to={`/${user?.plant?.toLowerCase()}/plant`}>
          <div className="text-xs text-white/80 hover:text-black hover:bg-white py-3 px-6 cursor-pointer">
            Plant
          </div>
        </Link>
        <div className="text-xs text-white/80 hover:text-black hover:bg-white py-3 px-6 cursor-pointer">
          Work & Bank Statement
        </div>
        <div className="text-xs text-white/80 hover:text-black hover:bg-white py-3 px-6 cursor-pointer">
          Individual Work Report
        </div>
        <div className="text-xs text-white/80 hover:text-black hover:bg-white py-3 px-6 cursor-pointer">
          Non pf user
        </div>
      </div>
      {/* <div className=" w-full flex items-center justify-between px-4 pt-2">
        <div className="siderowheader flex items-center">
          <div
            onClick={(e) => {
              setShowburger(true);
            }}
            className="siderow-burger flex flex-col justify-between mr-4"
          >
            <div className="burger-div bg-black"></div>
            <div className="burger-div bg-black"></div>
            <div className="burger-div bg-black"></div>
          </div>
          <div
            className="text-lg"
            style={{
              fontFamily: `'Bruno Ace SC', cursive`,
            }}
          >
            Zoous
          </div>
          <div className="header-option-plant flex relative bg-white">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => {
                setShowplants(!showplants);
              }}
            >
              <span className="mx-2 capitalize">
                {user?.plant?.toLowerCase()}
              </span>
              <MdOutlineArrowDropDown fontSize={25} />
            </div>
            {showplants && (
              <>
                <div
                  className="shadow-md w-full absolute min-h-10 top-0  bg-white rounded-lg border border-solid border-black "
                  style={{
                    zIndex: "100000",
                  }}
                  ref={menuref}
                >
                  {plants.map((plant) => (
                    <>
                      <div
                        className="w-full px-2 py-3 flex items-center hover:bg-black/10"
                        onClick={() => {
                          setShowplants(false);
                        }}
                      >
                        <span className="mx-2 capitalize">
                          {plant?.plant_name?.toLowerCase()}
                        </span>
                      </div>
                    </>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>

        <div className="header-search flex relative  bg-[#f1f1f1]">
          <input
            type="text"
            placeholder="Search for employee"
            onChange={handleemploysearch}
            className="text-sm"
          />
          <div className="mx-1 flex items-center searchicon">
            <AiOutlineSearch className="mx-1" />
            <span className="text-black ">Search</span>
          </div>
        </div>

        <div className="flex items-center">
          <div className="search-icon">
            <AiOutlineSearch className="mx-1" fontSize={20} />
          </div>

          <div className="mx-4 flex w-9 h-9 rounded-full border border-black/70 items-center justify-center">
            <BsFillSunFill fontSize={16} />
          </div>
          <Link to={`/${user._id}/setting`}>
            <div className="mx-4 flex w-9 h-9 rounded-full border border-black/70 items-center justify-center">
              <AiFillSetting />
            </div>
          </Link>

          <Link to="/">
            <div className="mx-4 p-2 rounded-full transition hover:bg-[rgb(0,0,0,0.09)] cursor-pointer">
              <AiOutlineLogout fontSize={23} />
            </div>
          </Link>
          <div className="ml-3 cursor-pointer">
            <Avatar
              src="/"
              alt={user?.plant}
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#fb2750",
              }}
            />
          </div>
        </div>
      </div>

      <div className="px-2 py-2 bg-[black] mt-3 flex items-center rp-urls header-option-list">
        {employeeaccess && (
          <div
            onClick={(e) => {
              handleclick(e);
              navigate(`/${user?.plant?.toLowerCase()}/Biodata`);
            }}
            className="flex items-center justify-center px-3 py-2 mx-2 font-medium cursor-pointer rounded-sm rp-urls-container  relative"
          >
            BioData
          </div>
        )}
        {attendenceaccess && (
          <div
            onClick={(e) => {
              handleclick(e);
              navigate(`/${user?.plant?.toLowerCase()}/Attendence/`);
            }}
            className="flex items-center justify-center px-3 py-2 mx-2  font-medium cursor-pointer rounded-sm rp-urls-container relative"
          >
            Attendence
          </div>
        )}
        {user?.menu_access?.includes("salary_menu") && (
          <div
            onClick={(e) => {
              handleclick(e);
              navigate(`/${user?.plant?.toLowerCase()}/Employee/salary`);
            }}
            className="flex items-center justify-center px-3 py-1.5 mx-2  font-medium cursor-pointer rounded-sm rp-urls-container relative"
          >
            Salary
          </div>
        )}
        {useraccess && (
          <div
            onClick={async (e) => {
              await handleclick(e);
              navigate(`/${user?.plant?.toLowerCase()}/user`);
            }}
            className="flex items-center justify-center px-3 py-1.5 font-medium cursor-pointer rounded-sm rp-urls-container relative"
          >
            User
          </div>
        )}
        <div
          onClick={(e) => {
            handleclick(e);
            navigate(`/${user?.plant?.toLowerCase()}/price`);
          }}
          className="flex items-center justify-center px-3 py-1.5 mx-2  font-medium cursor-pointer rounded-sm rp-urls-container relative"
        >
          Prices
        </div>
        {user?.menu_access?.includes("pf_menu") && (
          <div
            onClick={(e) => {
              handleclick(e);
              navigate(`/${user?.plant?.toLowerCase()}/Employee/PF`);
            }}
            className="flex items-center justify-center px-3 py-1.5 mx-2  font-medium cursor-pointer rounded-sm rp-urls-container relative"
          >
            PF
          </div>
        )}
        {user?.menu_access?.includes("workbank") && (
          <div
            onClick={(e) => {
              handleclick(e);
              navigate(`/${user?.plant?.toLowerCase()}/Work&BankStatement/`);
            }}
            className="flex items-center justify-center px-3 py-1.5 mx-2  font-medium cursor-pointer rounded-sm rp-urls-container relative"
          >
            Work & Bank Statement
          </div>
        )}
        <div
          onClick={(e) => {
            handleclick(e);
            navigate(`/${user?.plant?.toLowerCase()}/plant`);
          }}
          className="flex items-center justify-center px-3 py-1.5 mx-2  font-medium cursor-pointer rounded-sm rp-urls-container relative"
        >
          plant
        </div>
        {user?.menu_access?.includes("report_indwork") && (
          <div
            onClick={(e) => {
              handleclick(e);
              navigate(`/${user?.plant?.toLowerCase()}/indiviualwork`);
            }}
            className="flex items-center justify-center px-3 py-1.5 mx-2  font-medium cursor-pointer rounded-sm rp-urls-container relative"
          >
            Individual Work Report
          </div>
        )}
        {user?.menu_access?.includes("nonpf") && (
          <div
            onClick={(e) => {
              handleclick(e);
              navigate(`/${user?.plant?.toLowerCase()}/nonpfuser/`);
            }}
            className="flex items-center justify-center px-3 py-2 mx-2  font-medium cursor-pointer rounded-sm rp-urls-container relative"
          >
            Non pf user
          </div>
        )}
      </div>
      {showburger && (
        <motion.div
          initial={{
            x: -100,
          }}
          animate={{
            x: 0,
          }}
          className="border-y border-y-black/10 p-1 mt-3 flex items-center rp-urls mobile-header-option-list"
        >
          <div
            onClick={(e) => {
              setShowburger(false);
            }}
            className="pb-3"
            style={{
              width: "90%",
            }}
          >
            <AiOutlineCloseCircle fontSize={30} />
          </div>

          <div className="px-6 py-1.5 font-semibold  cursor-pointer rounded-sm mobile-rp-urls-container">
            Dashboard
          </div>
          <div className="flex items-center justify-center px-3 py-1.5  font-medium cursor-pointer rounded-sm mobile-rp-urls-container relative">
            <div
              className="flex flex-row"
              onClick={() => {
                navigate(`/${user?.plant?.toLowerCase()}/Biodata`);
              }}
            >
              BioData
            </div>
          </div>
          <div className="flex items-center justify-center px-3 py-2 mx-2  font-medium cursor-pointer rounded-sm mobile-rp-urls-container relative">
            <div
              className="flex flex-row"
              onClick={() => {
                navigate(`/${user?.plant?.toLowerCase()}/Attendence/`);
              }}
            >
              Attendence
            </div>
          </div>
          <div className="mobile-rp-urls-container">
            <Link to={`/${user?.plant?.toLowerCase()}/Employee/salary`}>
              <div className="flex items-center justify-center  font-medium cursor-pointer rounded-sm relative">
                Salary
              </div>
            </Link>
          </div>
          <div className="mobile-rp-urls-container">
            <Link to={`/${user?.plant?.toLowerCase()}/user`}>
              <div className="flex items-center justify-center  font-medium cursor-pointer rounded-sm relative">
                User
              </div>
            </Link>
          </div>
          <div className="mobile-rp-urls-container">
            <Link to={`/${user?.plant?.toLowerCase()}/price`}>
              <div className="flex items-center justify-center   font-medium cursor-pointer rounded-sm relative">
                Prices
              </div>
            </Link>
          </div>
          <div className="mobile-rp-urls-container">
            <Link to={`/${user?.plant?.toLowerCase()}/Employee/PF`}>
              <div className="flex items-center justify-center font-medium cursor-pointer rounded-sm relative">
                PF
              </div>
            </Link>
          </div>
          <div className="mobile-rp-urls-container">
            <Link to={`/${user?.plant?.toLowerCase()}/Work&BankStatement/`}>
              <div className="flex items-center justify-center font-medium cursor-pointer rounded-sm relative">
                Work & Bank Statement
              </div>
            </Link>
          </div>
          <div className="mobile-rp-urls-container">
            <Link to={`/${user?.plant?.toLowerCase()}/plant`}>
              <div className="flex items-center justify-center font-medium cursor-pointer rounded-sm relative">
                plant
              </div>
            </Link>
          </div>
          <div className="mobile-rp-urls-container">
            <Link to={`/${user?.plant?.toLowerCase()}/indiviualwork`}>
              <div className="flex items-center justify-center  font-medium cursor-pointer rounded-sm relative">
                Individual Work Report
              </div>
            </Link>
          </div>
          <div className="flex items-center justify-center  font-medium cursor-pointer rounded-sm mobile-rp-urls-container relative">
            <div
              className="flex flex-row"
              onClick={(e) => {
                navigate(`/${user?.plant?.toLowerCase()}/nonpfuser/`);
              }}
            >
              {" "}
              Non pf user
            
            </div>{" "}
           
          </div>
        </motion.div>
      )} */}
    </div>
  );
}

export default Header;
