import React, { useEffect, useState } from "react";
import Logo from "../../../assets/image/SAI.png";
import axios from "axios";
import { useParams } from "react-router";

function GuestEdit() {
  const { userid } = useParams();
  const [name, setName] = useState("");
  const [guest, setGuest] = useState([]);
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [person, setPerson] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [pin, setPin] = useState("");
  const [pout, setPout] = useState("");

  const handlesubmit = async () => {
    const form = new FormData();
    form.append("name", name);
    form.append("phone", phone);
    form.append("id", id);
    form.append("address", address);
    form.append("person", person);
    form.append("pin", pin);
    form.append("pout", pout);
    setLoading(true);
    const request = await axios.post(
      "https://saizoous.com/zoousbackend/employee/Updateguest.php",
      form
    );
    setLoading(false);
    window.location.assign("/mudhivarthi/Biodata/guest/");
    return request;
  };

  const handleincharge = async () => {
    const request = await axios.post(
      "https://saizoous.com/zoousbackend/employee/guest.php"
    );
    setGuest(request.data.data);
    const guestfilter = request.data.data.filter(
      (state) => state.id == userid
    )[0];
    const { name, id, person, address, phone, pin, pout } = guestfilter;
    setName(name);
    setId(id);
    setAddress(address);
    setPerson(person);
    setPin(pin);
    setPout(pout);
    setPhone(phone);
  };
  useEffect(() => {
    handleincharge();
  }, []);
  return (
    <>
      {loading ? (
        <div className="w-screen h-screen flex items-center justify-center">
          <div
            className="loadingspinner"
            style={{
              width: "80px",
              height: "80px",
              borderRadius: "50%",
              border: "8px solid black",
              borderTopColor: "rgb(0,0,0,0.12)",
            }}
          ></div>
        </div>
      ) : (
        <div
          className="py-2 min-h-screen flex items-center flex-col"
          style={{
            maxWidth: "100vw",
            zIndex: "100000",
            backgroundColor: "white",
          }}
        >
          <div className="flex items-center h-10">
            <div
              style={{
                width: "80px",
                height: "35px",
              }}
            >
              <img
                src={Logo}
                alt="logo"
                className="font-semibold w-full object-contain h-full"
              />
            </div>
            <h2 className="font-semibold font-[Inter]">ZOOUS Solution</h2>
          </div>
          <div className="w-1/2 h-10 bg-white my-4">
            <div className="font-thin text-2xl font-[Noto_Sans]">Edit user</div>
            <div className="border-b border-solid border-black/20 pb-3 pt-1">
              Mange your users here.
            </div>
            <div className="w-full px-3 py-4">
              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                Name
              </div>
              <input
                type="text"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
          "
                style={{
                  height: "40px",
                }}
              />
              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                ID
              </div>
              <input
                value={id}
                onChange={(e) => {
                  setId(e.target.value);
                }}
                type="text"
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
          "
                style={{
                  height: "40px",
                }}
              />
              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                Phone
              </div>
              <input
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                value={phone}
                type="text"
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
          "
                style={{
                  height: "40px",
                }}
              />
              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                Address
              </div>
              <input
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                value={address}
                type="text"
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
          "
                style={{
                  height: "40px",
                }}
              />
              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                Punch In
              </div>
              <input
                value={pin}
                onChange={(e) => {
                  setPin(e.target.value);
                }}
                type="text"
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
          "
                style={{
                  height: "40px",
                }}
              />
              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                Punch Out
              </div>
              <input
                value={pout}
                onChange={(e) => {
                  setPout(e.target.value);
                }}
                type="text"
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
          "
                style={{
                  height: "40px",
                }}
              />

              <div className="flex items-center justify-end py-2">
                <button
                  onClick={handlesubmit}
                  className="py-2 px-6 bg-red-500 font-semibold rounded-sm"
                  style={{
                    fontSize: "12px",
                    color: "white",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default GuestEdit;
