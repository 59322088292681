import React from "react";
import { useState } from "react";
import { useParams } from "react-router";
import Logo from "../../assets/image/SAI.png";
import axios from "axios";
import { useEffect } from "react";

function Salaredit() {
  const { userid, plant } = useParams();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [amount, setAmount] = useState(" ");
  const [bank, setBank] = useState(" ");
  const [ifsc, setIfsc] = useState(" ");
  const [account, setAccount] = useState(" ");

  const handlesubmit = async () => {
    const form = new FormData();
    form.append("name", name);
    form.append("amount", amount);
    form.append("id", id);
    form.append("bank", bank);
    form.append("ifsc", ifsc);
    form.append("acc", account);

    const request = await axios
      .post("https://saizoous.com/zoousbackend/salary/Update_salary.php", form)
      .then(() => {
        window.location.assign(`/${plant}/Employee/salary`);
      });

    return request;
  };

  const handleincharge = async () => {
    const request = await axios.post(
      "https://saizoous.com/zoousbackend/salary/Salary.php"
    );
    const userfilter = request.data.data.filter(
      (state) => state.id == userid
    )[0];

    const { id, name, bank, salary, ifsc, acc } = userfilter;
    setId(id);
    setName(name);
    setAmount(salary);
    setBank(bank);
    setIfsc(ifsc);
    setAccount(acc);
  };
  useEffect(() => {
    handleincharge();
  }, []);

  return (
    <>
      {loading ? (
        <div className="w-screen h-screen flex items-center justify-center">
          <div
            className="loadingspinner"
            style={{
              width: "80px",
              height: "80px",
              borderRadius: "50%",
              border: "8px solid black",
              borderTopColor: "rgb(0,0,0,0.12)",
            }}
          ></div>
        </div>
      ) : (
        <div
          className="py-2 min-h-screen flex items-center flex-col"
          style={{
            maxWidth: "100vw",
            zIndex: "100000",
            backgroundColor: "white",
          }}
        >
          <div className="flex items-center h-10">
            <div
              style={{
                width: "80px",
                height: "35px",
              }}
            >
              <img
                src={Logo}
                alt="logo"
                className="font-semibold w-full object-contain h-full"
              />
            </div>
            <h2 className="font-semibold font-[Inter]">ZOOUS Solution</h2>
          </div>
          <div className="w-1/2 h-10 bg-white my-4">
            <div className="font-thin text-2xl font-[Noto_Sans]">Edit user</div>
            <div className="border-b border-solid border-black/20 pb-3 pt-1">
              Mange your users here.
            </div>
            <div className="w-full px-3 py-4">
              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                Name
              </div>
              <input
                type="text"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
      "
                style={{
                  height: "40px",
                }}
              />
              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                Amount
              </div>
              <input
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
                value={amount}
                type="text"
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
      "
                style={{
                  height: "40px",
                }}
              />
              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                Bank Name
              </div>
              <input
                onChange={(e) => {
                  setBank(e.target.value);
                }}
                value={bank}
                type="text"
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
      "
                style={{
                  height: "40px",
                }}
              />
              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                IFSC Code
              </div>
              <input
                value={ifsc}
                onChange={(e) => {
                  setIfsc(e.target.value);
                }}
                type="text"
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
      "
                style={{
                  height: "40px",
                }}
              />
              <div className="mt-2 mb-1 font-[Noto_Sans] text-sm font-semibold">
                Account Number
              </div>
              <input
                value={account}
                onChange={(e) => {
                  setAccount(e.target.value);
                }}
                type="text"
                className="w-full border-solid border border-black/40 outline-none  rounded-md text-sm px-2
      "
                style={{
                  height: "40px",
                }}
              />
              <div className="flex items-center justify-end py-2">
                <button
                  onClick={handlesubmit}
                  className="py-2 px-6 bg-red-500 font-semibold rounded-sm"
                  style={{
                    fontSize: "12px",
                    color: "white",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Salaredit;
