import React from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { CiExport } from "react-icons/ci";
import Header from "../Home/Header/Header";

function Work() {
  return (
    <div
      style={{
        maxWidth: "100%",
      }}
    >
      <Header />
      <div className="max-w-full px-8 py-2">
        <div className="flex items-center justify-between">
          <div>
            <div className="px-1 text-sm font-semibold font-[Inter]">
              Work and Bank Statement
            </div>
            <div className="py-1 px-1 text-xs font-[Inter]">Employee List</div>
          </div>
          <div className="flex items-center">
            <div className="p-2 border border-black/30 border-solid rounded-full mx-1">
              <AiOutlineLeft />
            </div>
            <div className="p-2 border border-black/30 border-solid rounded-full mx-1">
              <AiOutlineRight />
            </div>
            <div className="flex items-center text-sm capitalize font-bold border border-solid py-1.5 px-3.5 mx-2 border-black/30 rounded-md">
              <CiExport fontSize={18} color="rgb(0,0,0,0.8)" className="mr-1" />
              <span
                style={{
                  margin: "0px 8px",
                  color: "rgb(0,0,0,0.8)",
                  fontFamily: "Noto_Sans",
                  fontSize: "12px",
                }}
              >
                EXPORT
              </span>
            </div>
          </div>
        </div>
        <div className="border-solid border border-black/10 mt-5">
          <div className="w-full min-h-10 shadow-md bg-[#f9f9f9] flex items-center px-4 justify-between font-semibold py-2.5">
            <div
              style={{
                fontSize: "11px",
                width: "130px",
              }}
            >
              Date
            </div>
            <div
              style={{
                fontSize: "11px",
                width: "25%",
              }}
            >
              ID
            </div>
            <div
              style={{
                fontSize: "11px",
                width: "25%",
              }}
            >
              NAME
            </div>
            <div
              style={{
                fontSize: "11px",
                width: "25%",
              }}
            >
              PLANT
            </div>
            <div
              style={{
                fontSize: "11px",
                width: "25%",
              }}
            >
              INCHARGE
            </div>
            <div
              style={{
                fontSize: "11px",
                width: "25%",
              }}
            >
              TOTAL KG
            </div>
            <div
              style={{
                fontSize: "11px",
                width: "25%",
              }}
            >
              TOTAL AMOUNT
            </div>
            <div
              style={{
                fontSize: "11px",
                width: "25%",
              }}
            >
              INCHARGE COMMISSION
            </div>
            <div
              style={{
                fontSize: "11px",
                width: "25%",
              }}
            >
              TOTAL PAYABLE
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Work;
