import React, { useState } from "react";
import { AiFillDelete, AiOutlineClose, AiOutlineEdit } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import { FiMoreHorizontal } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Avatar } from "@mui/material";

function Usercontainer({ employee }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);
  const [showdetail, setShowdetail] = useState(false);
  const selecteduser = useSelector((state) => state.selectuser);
  const [showeditoption, setShoweditoption] = useState(false);
  return (
    <div className="w-full min-h-10 flex border-b border-solid border-black/30 bg-white items-center px-4 justify-between font-semibold py-3">
      <div
        className="user-name"
        style={{
          fontSize: "12px",
          width: "25%",
        }}
      >
        {employee?.Name}
      </div>
      <div
        className="user-email"
        style={{
          fontSize: "12px",
          width: "25%",
        }}
      >
        {employee?.Email}
      </div>
      <div
        className="user-roles"
        style={{
          fontSize: "12px",
          width: "25%",
        }}
      >
        {employee?.role}
      </div>
      <div
        className="user-joindate"
        style={{
          fontSize: "12px",
          width: "20%",
        }}
      >
        {employee?.CreatedAt.split(" ")[0]}
      </div>
      <div
        className="action-button"
        style={{
          fontSize: "12px",
          width: "20%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="w-8 h-8 flex items-center justify-center  rounded-md mr-2 relative">
          {/* {showeditoption ? (
            <div
              className="w-full h-full bg-red-500/10 rounded-md flex items-center justify-center"
              onClick={() => {
                setShoweditoption(false);
              }}
            >
              <AiOutlineClose fontSize={16} color="red" />
            </div>
          ) : ( */}
          <Link to={`/${user?.plant?.toLowerCase()}/user/${employee.id}`}>
            <div
              className="w-full h-full bg-blue-500/10 rounded-md flex items-center justify-center"
              onClick={() => {
                setShoweditoption(true);
              }}
            >
              <BiPencil fontSize={16} color="royalblue" />
            </div>
          </Link>

          {showeditoption && (
            <div className="w-28 min-h-30 shadow-md z-10 absolute px-2 py-2 rounded bg-white top-9 right-0 flex flex-col items-start justify-start">
              <Link to={`/${user?.plant?.toLowerCase()}/user/${employee.id}`}>
                <div className="flex items-center my-1 hover:bg-black/10 w-full cursor-pointer rounded-md">
                  <div className="p-2 bg-green-100 rounded-md">
                    <AiOutlineEdit fontSize={18} />
                  </div>
                  <span className="mx-2">Edit</span>
                </div>
              </Link>
              <div className="flex items-center my-1 hover:bg-black/10 cursor-pointer w-full rounded-md">
                <div className="p-2 bg-red-100 rounded-md">
                  <AiFillDelete fontSize={18} />
                </div>
                <span className="mx-2">Delete</span>
              </div>
            </div>
          )}
        </div>
        {/* <div
          className="py-2 px-2 bg-red-500/10 rounded-md mr-2"
          onClick={() => {
            dispatch({
              type: "selecteduser",
              payload: employee,
            });
            setShowdetail(true);
          }}
        >
          <FiMoreHorizontal fontSize={16} color="red" />
        </div> */}
      </div>
      {showdetail && (
        <div
          className="fixed w-screen h-screen top-0 bottom-0 right-0 left-0"
          style={{
            backgroundColor: "rgb(0,0,0,0.3)",
            zIndex: "100000",
          }}
        >
          <motion.div
            initial={{
              x: 100,
            }}
            transition={{ type: "tween", duration: 0.1 }}
            animate={{
              x: 0,
            }}
            className="absolute right-0 top-0"
            style={{
              width: "500px",
              height: "100vh",
              overflowY: "scroll",
              background: "white",
            }}
          >
            <div
              onClick={() => {
                setShowdetail(false);
              }}
              className="py-2 px-4 border-b border-solid border-black/10 sticky bg-white top-0"
            >
              <AiOutlineClose fontSize={20} />
            </div>
            <div className="flex items-center flex-col py-2">
              <div
                className="flex items-center flex-col border border-solid border-black/10 p-2"
                style={{
                  width: "85%",
                }}
              >
                <div className="flex items-center relative justify-start py-2 w-full">
                  <Avatar
                    style={{
                      position: "relative",
                      width: "40px",
                      height: "40px",
                    }}
                    src={`http://zoous.com/new/api/images/${selecteduser?.front}`}
                  />
                  <div className="px-2 capitalize text-sm font-[Inter]">
                    <div className="text-xs">ID : {selecteduser?.id}</div>
                    <div className="font-semibold">{selecteduser?.name}</div>
                  </div>
                </div>
                <div className=" border-t border-solid border-black/10 w-full mt-2 flex items-center justify-between py-2 px-3">
                  <div className="text-xs pt-2">
                    <div className="font-semibold">Phone number</div>
                    <div className="mt-1">{selecteduser?.phone}</div>
                  </div>
                  <div className="text-xs  pt-2">
                    <div className="font-semibold">Person</div>
                    <div className="mt-1">{selecteduser?.person}</div>
                  </div>
                  <div className="text-xs  pt-2">
                    <div className="font-semibold">Aadhar</div>
                    <div className="mt-1">{selecteduser?.aadhar}</div>
                  </div>
                </div>
              </div>
              <div
                className="border-solid border py-3 px-3 border-black/10 my-2"
                style={{
                  width: "85%",
                }}
              >
                <div className="text-sm py-1 font-semibold font-[Inter] border-b border-solid border-black/10">
                  Bank detail
                </div>
                {/* <div className="py-2 text-xs px-2 flex justify-between"></div> */}
                <div className="py-1 text-xs px-2 flex justify-between">
                  <div className="py-2 text-xs pt-2">
                    <div className="font-semibold">Account number</div>
                    <div className="mt-1">{selecteduser?.acc}</div>
                  </div>
                  <div className="text-xs pt-2">
                    <div className="font-semibold">Branch Name</div>
                    <div className="mt-1">{selecteduser?.bank}</div>
                  </div>
                  <div className="text-xs  pt-2">
                    <div className="font-semibold">IFSC Code</div>
                    <div className="mt-1">{selecteduser?.ifsc}</div>
                  </div>
                </div>
                <div className="text-xs py-1 px-2 font-[Inter] font-semibold">
                  Passbook image
                </div>
                <div
                  className="px-2 py-1"
                  style={{
                    height: "200px",
                    width: "200px",
                  }}
                >
                  <img
                    className="w-full h-full"
                    src={`http://zoous.com/new/api/images/${selecteduser?.passbook}`}
                    alt=""
                  />
                </div>
              </div>
              <div
                className="border-solid border py-3 px-3 border-black/10 my-2"
                style={{
                  width: "85%",
                }}
              >
                <div className="text-sm py-1 font-semibold font-[Inter] border-b border-solid border-black/10">
                  PF and Other
                </div>
                {/* <div className="py-2 text-xs px-2 flex justify-between"></div> */}
                <div className="py-1 w-full text-xs px-2 flex justify-between">
                  <div className="py-2 text-xs pt-2 w-1/3">
                    <div className="font-semibold">PF</div>
                    <div className="mt-1">{selecteduser?.pf}</div>
                  </div>
                  <div className="text-xs pt-2 w-1/3">
                    <div className="font-semibold">ESI</div>
                    <div className="mt-1">{selecteduser?.esi}</div>
                  </div>
                  <div className="text-xs pt-2 w-1/3">
                    <div className="font-semibold">Salary</div>
                    <div className="mt-1">{selecteduser?.salary}</div>
                  </div>
                </div>
              </div>
              <div
                className="border-solid border py-3 px-3 border-black/10 my-2"
                style={{
                  width: "85%",
                }}
              >
                <div className="text-sm py-1 font-semibold font-[Inter] border-b border-solid border-black/10">
                  Additional detail
                </div>
                {/* <div className="py-2 text-xs px-2 flex justify-between"></div> */}
                <div className="py-1 w-full text-xs px-2 flex justify-between">
                  <div className="py-2 text-xs pt-2 w-1/3">
                    <div className="font-semibold">Contractor</div>
                    <div className="mt-1">{selecteduser?.contractor}</div>
                  </div>
                  <div className="text-xs pt-2 w-1/3">
                    <div className="font-semibold">Department</div>
                    <div className="mt-1">{selecteduser?.dept}</div>
                  </div>
                  <div className="text-xs pt-2 w-1/3">
                    <div className="font-semibold">Role</div>
                    <div className="mt-1">{selecteduser?.role}</div>
                  </div>
                </div>
                <div className="py-1 text-xs px-2 flex justify-between">
                  <div className="py-2 text-xs pt-2 w-1/3">
                    <div className="font-semibold">Section</div>
                    <div className="mt-1">{selecteduser?.section}</div>
                  </div>
                  <div className="text-xs pt-2 w-1/3">
                    <div className="font-semibold">Area</div>
                    <div className="mt-1">{selecteduser?.area}</div>
                  </div>
                  <div className="text-xs  pt-2 w-1/3">
                    <div className="font-semibold">Code</div>
                    <div className="mt-1">{selecteduser?.code}</div>
                  </div>
                </div>
                <div className="py-1 text-xs px-2 flex justify-between">
                  <div className="py-2 text-xs pt-2 w-1/3">
                    <div className="font-semibold">Code</div>
                    <div className="mt-1">{selecteduser?.code}</div>
                  </div>
                  <div className="text-xs pt-2 w-1/3">
                    <div className="font-semibold">NCP</div>
                    <div className="mt-1">{selecteduser?.ncp}</div>
                  </div>
                  <div className="text-xs  pt-2 w-1/3">
                    <div className="font-semibold">UAN No.</div>
                    <div className="mt-1">{selecteduser?.uan_no}</div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
}

export default Usercontainer;
