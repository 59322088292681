import React, { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import { CiExport } from "react-icons/ci";
import { FiMoreHorizontal } from "react-icons/fi";
import { FiDownloadCloud } from "react-icons/fi";
import Header from "../Home/Header/Header";

function Pf() {
  const [pfemployee, setPfemployee] = useState([]);

  return (
    <div>
      <Header />
      <div className="w-full employeecontainer">
        <div className="employee-data-container">
          <div className="e-d-container-top">
            <div className="flex flex-col font-[inter]">
              <h1>All PF employee</h1>
              <p
                className="text-xs text-black/0.3"
                style={{
                  fontSize: "11px",
                  fontFamily: "Noto_Sans",
                }}
              >
                Manage your PF Employee.
              </p>
            </div>
            <div className="e-d-container-top-right items-center min-w-36 flex rounded-full">
              <div className="flex w-full px-2 py-1">
                <div
                  className="flex items-center text-sm capitalize  border border-solid border-black/60 rounded-full mx-2"
                  style={{
                    width: "250px",
                    // background: "#f7f7f7",
                  }}
                >
                  <AiOutlineSearch
                    fontSize={20}
                    color="rgb(0,0,0,0.8)"
                    className="mx-2"
                  />
                  <input
                    type="text"
                    placeholder="Search...."
                    style={{
                      width: "100%",
                      marginRight: "8px",
                      height: "100%",
                      padding: "0px 0px",
                      borderRadius: "5px",
                      background: "transparent",
                      outline: "none",
                      border: "none",
                      color: "black",
                    }}
                  />
                </div>

                {/* <div className="flex items-center justify-center text-sm  font-bold  border border-solid py-1.5 px-3.5 rounded-md bg-[#4169e1] text-white relative">
                  <BsFilter fontSize={18} color="white" className="mr-1" />
                  <span
                    style={{
                      margin: "0px 2px",
                      color: "white",
                      fontFamily: "Noto_Sans",
                      fontSize: "11px",
                    }}
                  >
                    SORT
                  </span>
                  <div className="absolute w-32 min-h-18 sortingdropdown text-black shadow-xl border border-solid border-black/20 rounded-md">
                    <div className="text-sm">ASC BY ID</div>
                    <div className="text-sm">DESC BY ID</div>
                  </div>
                </div> */}
                <div
                  style={{
                    borderWidth: "1.5px",
                  }}
                  className="exportbutton flex items-center text-sm capitalize font-bold  border-solid py-1.5 px-3.5 mx-2 border-black/40 rounded-md"
                >
                  <FiDownloadCloud
                    fontSize={18}
                    color="rgb(0,0,0,0.8)"
                    className="mr-1"
                  />
                  <span
                    style={{
                      margin: "0px 8px",
                      color: "rgb(0,0,0,0.8)",
                      fontFamily: "Inter",
                      fontSize: "12px",
                    }}
                  >
                    Download CSV
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center w-full min-h-20 py-4">
            <div
              className="min-h-10 border border-solid border-black rounded-xl "
              style={{
                width: "95%",
              }}
            >
              <div className="w-full min-h-10 flex items-center px-4 justify-between font-semibold py-2.5">
                <div
                  style={{
                    fontSize: "11px",
                    width: "130px",
                  }}
                >
                  ID
                </div>
                <div
                  style={{
                    fontSize: "11px",
                    width: "25%",
                  }}
                >
                  UAN_NO
                </div>
                <div
                  style={{
                    fontSize: "11px",
                    width: "25%",
                  }}
                >
                  Name
                </div>
                <div
                  style={{
                    fontSize: "11px",
                    width: "25%",
                  }}
                >
                  Basic Salary
                </div>
                <div
                  style={{
                    fontSize: "11px",
                    width: "25%",
                  }}
                >
                  Actual Salary
                </div>
                <div
                  style={{
                    fontSize: "11px",
                    width: "25%",
                  }}
                >
                  PF
                </div>

                <div
                  style={{
                    fontSize: "11px",
                    width: "20%",
                  }}
                >
                  Basic Earned
                </div>

                <div
                  style={{
                    fontSize: "11px",
                    width: "20%",
                  }}
                >
                  Action
                </div>
              </div>
              {pfemployee?.map((employee) => (
                <div className="w-full min-h-10 flex shadow-md bg-[#f9f9f9] items-center px-4 justify-between font-semibold py-3 mt-2">
                  <div
                    style={{
                      fontSize: "11px",
                      width: "130px",
                    }}
                  >
                    {employee?.id}
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      width: "25%",
                    }}
                  >
                    {employee?.name}
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      width: "25%",
                    }}
                  >
                    {employee?.incharge_comission}
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      width: "25%",
                    }}
                  >
                    {employee?.care}
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      width: "25%",
                    }}
                  >
                    {employee?.area}
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      width: "25%",
                    }}
                  >
                    {employee?.phone}
                  </div>

                  <div
                    style={{
                      fontSize: "12px",
                      width: "20%",
                    }}
                  >
                    {employee?.doj}
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      width: "20%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="py-2 px-2 bg-blue-500/10 rounded-md mr-2">
                      <BiPencil fontSize={16} color="royalblue" />
                    </div>
                    <div className="py-2 px-2 bg-red-500/10 rounded-md mr-2">
                      <FiMoreHorizontal fontSize={16} color="red" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pf;
