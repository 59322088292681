import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Header from "../Home/Header/Header";
import axios from "axios";
import { motion } from "framer-motion";
import { FiDownloadCloud } from "react-icons/fi";
import { AiFillEye, AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import exportFromJSON from "export-from-json";

function Biodata() {
  const dispatch = useDispatch();
  const { plant } = useParams();
  const activesection = useSelector((state) => state.activesection);
  const sections = useSelector((state) => state.sections);
  const user = useSelector((state) => state.user);
  const allemployee = useSelector((state) => state.employee);
  const [employee, setEmployee] = useState([]);
  const [showdetail, setShowdetail] = useState(false);
  const selecteduser = useSelector((state) => state.selectuser);
  const [shareduser, setShareduser] = useState("");
  const [filtereduser, setFiltereduser] = useState([]);

  const handleemployeebysection = async (section) => {
    dispatch({
      type: "activesection",
      payload: section.section_name,
    });
    setEmployee(
      allemployee.filter(
        (employee) =>
          employee.section_name.toUpperCase() ===
          section.section_name.toUpperCase()
      )
    );
  };

  const handledownloadxlsx = () => {
    const fileName = "Employee_biodata";
    exportFromJSON({ data: allemployee, fileName, exportType: "xls" });
  };

  const handlefilter = (text) => {
    if (text.length) {
      const filtereduser = allemployee?.filter(
        (state) =>
          state.employee_id.toLowerCase().startsWith(text) ||
          state.employee_name?.split(" ")[0]?.toLowerCase()?.startsWith(text) ||
          state.employee_name?.split(" ")[1]?.toLowerCase()?.startsWith(text) ||
          state.Phone_number.startsWith(text) ||
          state.care_of.startsWith(text) ||
          state.Aadhar_number.startsWith(text) ||
          state.designation.startsWith(text)
      );
      console.log(filtereduser);
      setEmployee(filtereduser);
    } else {
      setEmployee(
        allemployee.filter(
          (employee) => employee.section_name.toUpperCase() === activesection
        )
      );
    }
  };

  const handleclick = (e) => {
    const biodataheader = document.getElementsByClassName("biodata-header");

    for (let i = 0; i < biodataheader.length; i++) {
      biodataheader[i].style.backgroundColor = "white";
      biodataheader[i].style.color = "black";
    }
    e.target.style.backgroundColor = "black";
    e.target.style.color = "white";
  };

  const fetchemploy = async () => {
    const request = await axios
      .get("https://saizoous.com/zoousbackend/employee/Allemployee.php")
      .then((result) => {
        dispatch({
          type: "allemployee",
          payload: result.data.data,
        });
        return result;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
    return request;
  };

  useEffect(() => {
    setEmployee(
      allemployee.filter(
        (employee) => employee.section_name.toUpperCase() === activesection
      )
    );
  }, [allemployee.length]);
  useEffect(() => {
    fetchemploy();
  }, []);

  return (
    <div>
      <Header />
      <div className="w-full employeecontainer bg-[#f7f7f7] px-4 relative">
        <div className="employee-data-container">
          <div className="w-full flex items-start flex-col">
            <div
              className="pt-4 pb-2"
              style={{
                zIndex: 1000000,
              }}
            >
              <div className="pb-4 text-4xl text-white  z-10">BioData</div>
              {/* <div
                className="border border-solid border-black/40 rounded-md flex items-center mx-3 justify-start"
                style={{
                  height: "35px",
                }}
              >
                {user?.menu_access?.includes("emp_data") && (
                  <div
                    onClick={(e) => {
                      handleclick(e);
                      navigate(`/${plant?.toLowerCase()}/Biodata`);
                    }}
                    style={{
                      width: 200,
                    }}
                    className="biodata-header cursor-pointer  text-xs flex items-center justify-center  h-full rounded-md  bg-black text-white"
                  >
                    Employee Biodata
                  </div>
                )}
                {user?.menu_access?.includes("inc_data") && (
                  <div
                    onClick={(e) => {
                      handleclick(e);
                      navigate(`/${plant?.toLowerCase()}/Biodata/Incharge`);
                    }}
                    style={{
                      width: 200,
                    }}
                    className="biodata-header cursor-pointer text-xs flex items-center justify-center h-full rounded-md"
                  >
                    Incharge Biodata
                  </div>
                )}
                {user?.menu_access?.includes("guest_data") && (
                  <div
                    onClick={(e) => {
                      handleclick(e);
                      navigate(`/${plant?.toLowerCase()}/Biodata/guest`);
                    }}
                    style={{
                      width: 200,
                    }}
                    className="biodata-header cursor-pointer text-xs flex items-center justify-center h-full rounded-md"
                  >
                    Guest Biodata
                  </div>
                )}
              </div> */}
            </div>
          </div>
          <div
            className="w-full py-2 px-3 bg-white rounded-md z-10 relative flex items-center flex-wrap flex-row"
            style={{
              fontFamily: `'Rubik', sans-serif`,
            }}
          >
            {sections.map((sections) => {
              return (
                <div
                  onClick={() => handleemployeebysection(sections)}
                  className={`select-none text-xs py-2 px-4 border border-black/60 my-1 mx-1 rounded-md ${
                    sections.section_name === activesection
                      ? "bg-black text-white"
                      : "bg-white text-black"
                  }  hover:text-white hover:bg-black cursor-pointer`}
                >
                  {sections?.section_name}
                </div>
              );
            })}
          </div>
          <div className="w-full bg-white shadow-lg my-3 rounded-md ">
            <div className=" items-center min-w-36 flex rounded-full">
              <div className="flex w-full">
                <div className="e-d-container-search w-full flex items-center text-sm capitalize rounded-full relative">
                  <AiOutlineSearch
                    fontSize={20}
                    color="rgb(0,0,0,0.8)"
                    className="mx-3"
                  />
                  <input
                    onChange={(e) => {
                      setShareduser(e.target.value);
                      handlefilter(e.target.value.toLowerCase());
                    }}
                    type="text"
                    placeholder="Search...."
                    style={{
                      width: "100%",
                      marginRight: "8px",
                      height: "100%",
                      padding: "0px 0px",
                      borderRadius: "5px",
                      background: "transparent",
                      outline: "none",
                      border: "none",
                      color: "black",
                    }}
                  />
                </div>
                <Link
                  className="w-44 font-medium font-sans text-base capitalize bg-black text-white flex items-center justify-center"
                  to={`/${plant}/Biodata/addemployee`}
                >
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontSize: "12px",
                    }}
                    className="w-full h-full flex items-center justify-center"
                  >
                    Add employee
                  </div>
                </Link>
                <div
                  onClick={handledownloadxlsx}
                  className="flex items-center text-sm capitalize  py-1.5 px-3.5 w-52 h-12"
                >
                  <FiDownloadCloud
                    fontSize={20}
                    color="rgb(0,0,0,0.8)"
                    className="mr-1"
                  />
                  <span
                    style={{
                      margin: "0px 8px",
                      color: "rgb(0,0,0,0.8)",
                      fontFamily: "Inter",
                      fontSize: "11px",
                    }}
                  >
                    Download CSV
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center w-full min-h-20 my-2 bg-white rounded-md">
            <div
              className="min-h-10 border border-solid border-black rounded-lg"
              style={{
                width: "100%",
              }}
            >
              <div className="w-full bg-[#fffafa] min-h-10  rounded-t-lg flex items-center px-4 justify-between font-semibold py-3">
                <div
                  className="user-id"
                  style={{
                    fontSize: "11px",
                    width: "130px",
                  }}
                >
                  ID
                </div>
                <div
                  className="user-name"
                  style={{
                    fontSize: "11px",
                    width: "25%",
                  }}
                >
                  Name
                </div>
                <div
                  className="user-co"
                  style={{
                    fontSize: "11px",
                    width: "25%",
                  }}
                >
                  C/O
                </div>
                <div
                  className="user-residence"
                  style={{
                    fontSize: "11px",
                    width: "25%",
                  }}
                >
                  Residence
                </div>
                <div
                  className="user-phone"
                  style={{
                    fontSize: "11px",
                    width: "25%",
                  }}
                >
                  Phone Number
                </div>

                <div
                  className="user-joindate"
                  style={{
                    fontSize: "11px",
                    width: "20%",
                  }}
                >
                  JOIN DATE
                </div>

                <div
                  style={{
                    fontSize: "11px",
                    width: "20%",
                  }}
                >
                  Action
                </div>
              </div>
              {employee?.map((employee) => (
                <div className="w-full min-h-10 flex items-center px-4 justify-between font-semibold py-2.5 border-t border-t-black/30">
                  <div
                    className="user-id"
                    style={{
                      fontSize: "11px",
                      width: "130px",
                    }}
                  >
                    {employee?.employee_id}
                  </div>
                  <div
                    className="flex items-center user-name"
                    style={{
                      fontSize: "12px",
                      width: "25%",
                    }}
                  >
                    <Avatar
                      style={{
                        position: "relative",
                        width: "30px",
                        height: "30px",
                        marginRight: 7,
                      }}
                      src={`https://saizoous.com/zoousbackend/employee/Photo/Upload/${employee?.employee_photo}`}
                    />

                    {employee?.employee_name}
                  </div>
                  <div
                    className="user-co"
                    style={{
                      fontSize: "12px",
                      width: "25%",
                    }}
                  >
                    {employee?.care_of}
                  </div>
                  <div
                    className="user-residence"
                    style={{
                      fontSize: "12px",
                      width: "25%",
                    }}
                  >
                    {employee?.Address}
                  </div>
                  <div
                    className="user-phone"
                    style={{
                      fontSize: "12px",
                      width: "25%",
                    }}
                  >
                    {employee?.Phone_number}
                  </div>
                  <div
                    className="user-joindate"
                    style={{
                      fontSize: "12px",
                      width: "20%",
                    }}
                  >
                    {employee?.date_of_joining}
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      width: "20%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Link to={`/${plant}/biodata/editbio/${employee?.id}`}>
                      <div className="py-2 px-2 bg-blue-500/10 rounded-md mr-2">
                        <BiPencil fontSize={16} color="royalblue" />
                      </div>
                    </Link>
                    <div
                      onClick={() => {
                        dispatch({
                          type: "selecteduser",
                          payload: employee,
                        });
                        setShowdetail(true);
                      }}
                      className="py-2 px-2 bg-red-500/10 rounded-md mr-2"
                    >
                      <AiFillEye fontSize={16} color="red" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {showdetail && (
              <div
                className="fixed w-screen h-screen top-0 bottom-0 right-0 left-0"
                style={{
                  backgroundColor: "rgb(0,0,0,0.3)",
                  zIndex: "100000",
                }}
              >
                <motion.div
                  initial={{
                    x: 100,
                  }}
                  transition={{ type: "tween", duration: 0.1 }}
                  animate={{
                    x: 0,
                  }}
                  className="absolute right-0 top-0"
                  style={{
                    width: "500px",
                    height: "100vh",
                    overflowY: "scroll",
                    background: "white",
                  }}
                >
                  <div className="py-2 px-4 border-b border-solid border-black/10 sticky bg-white top-0 flex items-center justify-between">
                    <div
                      onClick={() => {
                        setShowdetail(false);
                      }}
                    >
                      <AiOutlineClose fontSize={20} />
                    </div>
                  </div>
                  <div className="flex items-center flex-col py-2">
                    <div
                      className="flex items-center flex-col border border-solid border-black/10 p-2"
                      style={{
                        width: "85%",
                      }}
                    >
                      <div className="flex items-center relative justify-start py-2 w-full">
                        <Avatar
                          style={{
                            position: "relative",
                            width: "40px",
                            height: "40px",
                          }}
                          src={`https://saizoous.com/zoousbackend/employee/Photo/Upload/${selecteduser?.employee_photo}`}
                        />
                        <div className="px-2 capitalize text-sm font-[Inter]">
                          <div className="text-xs">
                            ID : {selecteduser?.employee_id}
                          </div>
                          <div className="font-semibold">
                            {selecteduser?.employee_name}
                          </div>
                        </div>
                      </div>
                      <div className=" border-t border-solid border-black/10 w-full mt-2 flex items-center justify-between py-2 px-3">
                        <div className="text-xs pt-2">
                          <div className="font-semibold">Phone number</div>
                          <div className="mt-1">
                            {selecteduser?.Phone_number}
                          </div>
                        </div>
                        <div className="text-xs  pt-2">
                          <div className="font-semibold">Care of</div>
                          <div className="mt-1">{selecteduser?.care_of}</div>
                        </div>
                        <div className="text-xs  pt-2">
                          <div className="font-semibold">Aadhar</div>
                          <div className="mt-1">
                            {selecteduser?.Aadhar_number}
                          </div>
                        </div>
                      </div>
                      <div className="w-full px-3">
                        <div className="font-semibold text-xs  pt-2.5">
                          Addhar photo
                        </div>
                        <div
                          className="py-1.5 flex items-center"
                          style={{
                            height: "200px",
                            width: "360px",
                          }}
                        >
                          <img
                            className="w-1/2 h-full object-contain object-left mr-2"
                            src={`https://saizoous.com/zoousbackend/employee/Aadhar/Upload/${selecteduser?.aadhar_front}`}
                            alt=""
                          />
                          <img
                            className="w-1/2 h-full object-contain object-left"
                            src={`https://saizoous.com/zoousbackend/employee/Aadhar/Upload/${selecteduser?.aadhar_back}`}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="border-solid border py-3 px-3 border-black/10 my-2"
                      style={{
                        width: "85%",
                      }}
                    >
                      <div className="text-sm py-1 font-semibold font-[Inter] border-b border-solid border-black/10">
                        Bank detail
                      </div>
                      {/* <div className="py-2 text-xs px-2 flex justify-between"></div> */}
                      <div className="py-1 text-xs px-2 flex justify-between">
                        <div className="py-2 text-xs pt-2">
                          <div className="font-semibold">Account number</div>
                          <div className="mt-1">
                            {selecteduser?.Account_number}
                          </div>
                        </div>
                        <div className="text-xs pt-2">
                          <div className="font-semibold">Branch Name</div>
                          <div className="mt-1">{selecteduser?.bank_name}</div>
                        </div>
                        <div className="text-xs  pt-2">
                          <div className="font-semibold">IFSC Code</div>
                          <div className="mt-1">{selecteduser?.Ifsc_code}</div>
                        </div>
                      </div>
                      <div className="text-xs py-1 px-2 font-[Inter] font-semibold">
                        Passbook image
                      </div>
                      <div
                        className="px-2 py-1"
                        style={{
                          height: "200px",
                          width: "200px",
                        }}
                      >
                        <img
                          className="w-full h-full object-contain object-left"
                          src={`https://saizoous.com/zoousbackend/employee/Passbook/Upload/${selecteduser?.Passbook_photo}`}
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      className="border-solid border py-3 px-3 border-black/10 my-2"
                      style={{
                        width: "85%",
                      }}
                    >
                      <div className="text-sm py-1 font-semibold font-[Inter] border-b border-solid border-black/10">
                        PF and Other
                      </div>
                      {/* <div className="py-2 text-xs px-2 flex justify-between"></div> */}
                      <div className="py-1 w-full text-xs px-2 flex justify-between">
                        <div className="py-2 text-xs pt-2 w-1/3">
                          <div className="font-semibold">PF</div>
                          <div className="mt-1">{selecteduser?.Pf}</div>
                        </div>
                        <div className="text-xs pt-2 w-1/3">
                          <div className="font-semibold">ESI</div>
                          <div className="mt-1">{selecteduser?.esi_number}</div>
                        </div>
                        <div className="text-xs pt-2 w-1/3">
                          <div className="font-semibold">Salary</div>
                          <div className="mt-1">{selecteduser?.salary}</div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="border-solid border py-3 px-3 border-black/10 my-2"
                      style={{
                        width: "85%",
                      }}
                    >
                      <div className="text-sm py-1 font-semibold font-[Inter] border-b border-solid border-black/10">
                        Additional detail
                      </div>
                      {/* <div className="py-2 text-xs px-2 flex justify-between"></div> */}
                      <div className="py-1 w-full text-xs px-2 flex justify-between">
                        <div className="py-2 text-xs pt-2 w-1/3">
                          <div className="font-semibold">Date of Joining</div>
                          <div className="mt-1">
                            {selecteduser?.date_of_joining}
                          </div>
                        </div>
                        <div className="text-xs pt-2 w-1/3">
                          <div className="font-semibold">Department</div>
                          <div className="mt-1">{selecteduser?.department}</div>
                        </div>
                        <div className="text-xs pt-2 w-1/3">
                          <div className="font-semibold">Role</div>
                          <div className="mt-1">{selecteduser?.role}</div>
                        </div>
                      </div>
                      <div className="py-1 text-xs px-2 flex justify-between">
                        <div className="py-2 text-xs pt-2 w-1/3">
                          <div className="font-semibold">Section Name</div>
                          <div className="mt-1">
                            {selecteduser?.section_name}
                          </div>
                        </div>
                        <div className="text-xs pt-2 w-1/3">
                          <div className="font-semibold">Plant Name</div>
                          <div className="mt-1">{selecteduser?.plant_name}</div>
                        </div>
                        <div className="text-xs  pt-2 w-1/3">
                          <div className="font-semibold">Code</div>
                          <div className="mt-1">{selecteduser?.code}</div>
                        </div>
                      </div>
                      <div className="py-1 text-xs px-2 flex justify-between">
                        <div className="py-2 text-xs pt-2 w-1/3">
                          <div className="font-semibold">Code</div>
                          <div className="mt-1">{selecteduser?.code}</div>
                        </div>
                        <div className="text-xs pt-2 w-1/3">
                          <div className="font-semibold">NCP</div>
                          <div className="mt-1">{selecteduser?.ncp}</div>
                        </div>
                        <div className="text-xs  pt-2 w-1/3">
                          <div className="font-semibold">UAN No.</div>
                          <div className="mt-1">{selecteduser?.uan_no}</div>
                        </div>
                      </div>
                      <div className="py-1 text-xs px-2 flex justify-between">
                        <div className="py-2 text-xs pt-2 w-1/3">
                          <div className="font-semibold">Designation</div>
                          <div className="mt-1">
                            {selecteduser?.designation}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Biodata;
