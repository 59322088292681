export const handlepermission = async () => {
  return new Promise((resolve, reject) => {
    let array = [];
    let ebio = document.querySelector("input[id='ebiodata']:checked");
    let inchargebiodata = document.querySelector(
      "input[id='inchargebiodata']:checked"
    );
    let guestbiodata = document.querySelector(
      "input[id='guestbiodata']:checked"
    );
    let giveattend = document.querySelector("input[id='giveattend']:checked");
    let reportattend = document.querySelector(
      "input[id='reportattend']:checked"
    );
    let wbstate = document.querySelector("input[id='WBSTATE']:checked");
    let salary = document.querySelector("input[id='salary']:checked");
    let iwreport = document.querySelector("input[id='iwreport']:checked");
    let pf = document.querySelector("input[id='pf']:checked");
    let adduser = document.querySelector("input[id='adduser']:checked");
    let searchuser = document.querySelector("input[id='searchuser']:checked");
    let addprice = document.querySelector("input[id='addprice']:checked");
    let addplant = document.querySelector("input[id='addplant']:checked");
    let npfuser = document.querySelector("input[id='npfuser']:checked");

    if (ebio) {
      array.push("emp_data");
    }
    if (inchargebiodata) {
      array.push("inc_data");
    }
    if (guestbiodata) {
      array.push("guest_data");
    }
    if (salary) {
      array.push("salary_menu");
    }
    if (giveattend) {
      array.push("give_att");
    }
    if (reportattend) {
      array.push("report_att");
    }
    if (wbstate) {
      array.push("workbank");
    }
    if (iwreport) {
      array.push("report_indwork");
    }
    if (pf) {
      array.push("pf_menu");
    }
    if (adduser) {
      array.push("adduser");
    }
    if (searchuser) {
      array.push("searchuser");
    }
    if (addprice) {
      array.push("addprice");
    }
    if (addplant) {
      array.push("addplant");
    }
    if (npfuser) {
      array.push("nonpf");
    }

    resolve(array);
  });
};

export const handleforminputs = async (
  username,
  useremail,
  userphone,
  userpassword,
  employeeid
  //   plantpermission,
  //   plant
  //   modulepermission
) => {
  return new Promise((resolve, reject) => {
    console.log(username, useremail, userphone, userpassword , employeeid);
    if (!username || !useremail || !userphone || !userpassword || !employeeid) {
      return reject(false);
    } else {
      return resolve(true);
    }
  });
};

export const handleppermission = async () => {
  return new Promise((resolve, reject) => {
    let array = [];
    let mudhi = document.querySelector("input[id='MUDHIVARTHI']:checked");
    let krish = document.querySelector("input[id='Krishnapurama']:checked");
    let iska = document.querySelector("input[id='Iskapalli']:checked");
    let vizag = document.querySelector("input[id='Vizag']:checked");

    if (mudhi) {
      array.push("MUDHIVARTHI");
    }
    if (krish) {
      array.push("Krishnapuram");
    }
    if (iska) {
      array.push("Iskapalli");
    }
    if (vizag) {
      array.push("Vizag");
    }
    resolve(array);
  });
};
