import React from "react";
import Header from "../Home/Header/Header";

function Workattend() {
  const handleclick = (e) => {
    const biodataheader = document.getElementsByClassName("biodata-header");

    for (let i = 0; i < biodataheader.length; i++) {
      biodataheader[i].style.backgroundColor = "white";
      biodataheader[i].style.color = "black";
    }
    e.target.style.backgroundColor = "black";
    e.target.style.color = "white";
  };
  return (
    <div>
      <Header />
      <div className=" px-10 ">
        <div className="w-full pt-4 pb-2">
          <div className="pb-4 text-4xl">Non pf user</div>
          <div
            className="border border-solid border-black/40 rounded-md flex items-center mx-3"
            style={{
              maxWidth: "290px",
              height: "35px",
            }}
          >
            <div
              onClick={(e) => {
                handleclick(e);
                // navigate(`/${plant?.toLowerCase()}/Biodata`);
              }}
              className="biodata-header cursor-pointer w-1/2 text-xs flex items-center justify-center  h-full rounded-md  bg-black text-white"
            >
              Work & Attendance
            </div>
            <div
              onClick={(e) => {
                handleclick(e);
                // navigate(`/${plant?.toLowerCase()}/Biodata/Incharge`);
              }}
              className="biodata-header cursor-pointer w-1/2 text-xs flex items-center justify-center h-full rounded-md"
            >
              Monthly Report
            </div>
          </div>
        </div>
        <div className="flex items-center py-3 px-3 justify-between">
          Employee List
          <div className="flex items-center">
            <div className="py-1 px-2 font-semibold text-xs font-[Inter] border-solid border rounded-md border-black/20 mx-1">
              This month
            </div>
            <div className="py-1 px-2 font-semibold text-xs font-[Inter] border-solid border rounded-md border-black/20 mx-1">
              From range
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Workattend;
