import axios from "axios";
import React, { useEffect, useState } from "react";
import "./Login.css";
import ReactLoading from "react-loading";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { BiErrorCircle } from "react-icons/bi";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const plants = useSelector((state) => state?.plants);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);
  const [code, setCode] = useState("");

  const handlelogin = async () => {
    if (!email || !password) {
      return;
    } else {
      const form = new FormData();
      form.append("email", email);
      form.append("password", password);
      form.append("code", code);
      setLoading(true);
      const request = await axios
        .post("https://saizoous.com/zoousbackend/Auth/Login.php", form)
        .then((result) => {
          setLoading(false);
          // console.log(result);
          // localStorage.setItem("userdata", JSON.stringify(result.data.data[0]));
          localStorage.setItem("jwt", result.data.jwt);
          dispatch({
            type: "user",
            payload: result.data.data[0],
          });
          window.location.assign(
            `${result.data.data[0].plant.toLowerCase()}/biodata`
          );
          console.log(result);
        })
        .catch((err) => {
          // console.log("got errir");
          setError({
            error: true,
            errormessage:
              "Incorrect email or password or please try again later.",
          });
          setTimeout(() => {
            setError({
              error: false,
            });
          }, 2500);
          setLoading(false);
        });
      return request;
    }
  };

  const handleselect = (e, plant) => {
    let component = document.getElementsByClassName("plantselection");

    for (let i = 0; i < component.length; i++) {
      component[i].style.border = "1.5px solid black";
    }

    setCode(plant.plant_name);
    e.target.style.border = "1.5px solid red";
  };

  useEffect(() => {
    if (user) {
      console.log(user);
      // window.location.assign(`/${user?.plant?.toLowerCase()}/Biodata`);
    }
  }, [user?.length]);

  return (
    <div className="login">
      <div className="logincontainer">
        {error.error && (
          <div className="w-72 h-16 flex items-center bg-white shadow-2xl rounded-lg border border-black/40 absolute top-10 right-10">
            <div className="w-16 flex justify-center items-center h-full">
              <BiErrorCircle color="red" fontSize={21} />
            </div>
            <div className="text-xs">{error?.errormessage}</div>
          </div>
        )}
        <div className="loginlogo"></div>
        <div className="loginsystem">
          <span className="loginsystemtitle">Log in to your account</span>
          <p>Welcome back</p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <label htmlFor="Email">Email</label>
            <input
              type="text"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <label htmlFor="Password">Password</label>
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <div className="my-1">
              <label htmlFor="select Plant">Select Plant</label>
              <div className="flex my-1 w-full flex-wrap">
                {plants?.map((plant) => (
                  <div
                    onClick={(e) => {
                      handleselect(e, plant);
                    }}
                    className="plantselection py-2 px-4 mb-1 mr-1.5 border border-solid border-black/60 cursor-pointer font-[Noto_Sans] rounded-md"
                    style={{
                      fontSize: "12px",
                      border: "1.5px solid black",
                      fontWeight: "600",
                      userSelect: "none",
                    }}
                  >
                    {plant?.plant_name}
                  </div>
                ))}
              </div>
            </div>
            {loading ? (
              <div
                className="w-full flex items-center justify-center"
                style={{
                  minHeight: "25px",
                  borderRadius: "8px",
                  border: "1.5px solid black",
                }}
              >
                <ReactLoading
                  type="balls"
                  color="black"
                  height={"20%"}
                  width={"10%"}
                />
              </div>
            ) : (
              <button onClick={handlelogin}>Login</button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
