import React from "react";
import Logo from "../../assets/image/SAI.png";
import Header from "../Home/Header/Header";
import { Avatar } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

function Addemployee() {
  const navigate = useNavigate();
  const { plant } = useParams();
  const [photourl, setPhotourl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [employee_name, setEmployee_name] = useState("");
  const [employee_id, setEmployee_id] = useState("");
  const [designation, setDesignation] = useState("");
  const [employee_photo, setEmployee_photo] = useState(null);
  const [care_of, setCare_of] = useState("");
  const [address, setAddress] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [aadhar_number, setAadhar_number] = useState("");
  const [aadhar_front, setAadhar_front] = useState(null);
  const [aadhar_back, setAadhar_back] = useState(null);
  const [gender, setGender] = useState("");
  const [basic_salary, setBasic_salary] = useState("");
  const [date_of_joining, setDate_of_joining] = useState("");
  const [date_of_exit, setDate_of_exit] = useState("");
  const [department, setDepartment] = useState("");
  const [section_name, setSection_name] = useState("");
  const [plant_name, setPlant_name] = useState("");
  const [bank_name, setBank_name] = useState("");
  const [ifsc_code, setIfsc_code] = useState("");
  const [account_number, setAccount_number] = useState("");
  const [passbook_photo, setPassbook_photo] = useState(null);
  const [salary, setSalary] = useState("");
  const [pf, setPf] = useState("");
  const [esi_number, setEsi_number] = useState("");
  const [uan_number, setUan_number] = useState("");
  const [allowance, setAllowance] = useState("");
  const [ncp, setNcp] = useState("");
  const [incharge_commision, setIncharge_commision] = useState("");
  const sections = useSelector((state) => state.sections);

  const handleemployeepassbook = async (e) => {
    const form = new FormData();
    // console.log(e.target.files[0]);
    form.append("image", e.target.files[0]);
    const employeephoto = await axios
      .post(
        "https://saizoous.com/zoousbackend/employee/Passbook/Passbook.php",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((result) => {
        // console.log(result.data.image);
        setPassbook_photo(result.data.image);
      });
    console.log(employeephoto);
  };

  const handleemployeefrontaadhar = async (e) => {
    const form = new FormData();
    console.log(e.target.files[0]);
    form.append("image", e.target.files[0]);
    const employeephoto = await axios
      .post(
        "https://saizoous.com/zoousbackend/employee/Aadhar/EmployeeAadhar.php",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((result) => {
        console.log(result.data.image);
        setAadhar_front(result.data.image);
      });
    console.log(employeephoto);
  };

  const handleemployeebackaadhar = async (e) => {
    const form = new FormData();
    form.append("image", e.target.files[0]);
    const employeephoto = await axios
      .post(
        "https://saizoous.com/zoousbackend/employee/Aadhar/EmployeeAadhar.php",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((result) => {
        console.log(result.data.image);
        setAadhar_back(result.data.image);
      });
    console.log(employeephoto);
  };

  const handleemployeephoto = async (e) => {
    const form = new FormData();
    form.append("image", e.target.files[0]);
    const employeephoto = await axios
      .post(
        "https://saizoous.com/zoousbackend/employee/Photo/EmployeePhoto.php",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((result) => {
        console.log(result.data.image);
        setEmployee_photo(result.data.image);
        setPhotourl(result.data.image);
      });
    console.log(employeephoto);
  };
  const handlerequest = async () => {
    const formData = new FormData();

    let sec = document.getElementsByClassName("section_container")[0];
    console.log(sec.value);
    // Append the form data to the FormData instance
    formData.append("employee_name", employee_name);
    formData.append("employee_id", employee_id);
    formData.append("designation", designation);
    formData.append("employee_photo", employee_photo);
    formData.append("care_of", care_of);
    formData.append("address", address);
    formData.append("phone_number", phone_number);
    formData.append("aadhar_number", aadhar_number);
    formData.append("aadhar_front", aadhar_front);
    formData.append("aadhar_back", aadhar_back);
    formData.append("gender", gender);
    formData.append("date_of_joining", date_of_joining);
    formData.append("date_of_exit", date_of_exit);
    formData.append("department", department);
    formData.append("section_name", sec.value);
    formData.append("plant_name", plant_name);
    formData.append("basic_salary", basic_salary);
    formData.append("bank_name", bank_name);
    formData.append("ifsc_code", ifsc_code);
    formData.append("account_number", account_number);
    formData.append("passbook_photo", passbook_photo);
    formData.append("salary", salary);
    formData.append("pf", pf);
    formData.append("esi_number", esi_number);
    formData.append("uan_number", uan_number);
    formData.append("allowance", allowance);
    formData.append("ncp", ncp);
    formData.append("incharge_commision", incharge_commision);
    setLoading(true);

    const request = await axios
      .post(
        "https://saizoous.com/zoousbackend/employee/Addemployee.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((result) => {
        setLoading(false);
        navigate(`/${plant}/Biodata`);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div
      className=" min-h-screen flex items-center flex-col "
      style={{
        maxWidth: "100vw",
        zIndex: "100000",
        backgroundColor: "white",
        fontFamily: `'Rubik', sans-serif`,
        paddingBottom: 30,
      }}
    >
      <div className="w-2/3">
        <div className="flex items-center justify-between w-full border-b border-b-black/30 py-4 px-2 sticky top-0 bg-white">
          <div
            className="text-lg"
            style={{
              fontFamily: `'Bruno Ace SC', cursive`,
            }}
          >
            Zoous
          </div>
          <div style={{}}>Logout</div>
        </div>
        <div className="px-20">
          <div className=" py-4 text-xl capitalize">Add your employee</div>
          <div className="text-sm text-black/80">Employee Photo</div>
          <div className="py-3 px-2 flex items-center">
            <div
              className="w-16 h-16 bg-cover rounded-md"
              style={{
                backgroundImage: `url(https://saizoous.com/zoousbackend/employee/Photo/Upload/${photourl})`,
              }}
            ></div>
            <div className="mx-3">
              <div className="relative py-1 px-2 flex items-center justify-center border border-black/20 rounded-md text-sm w-32">
                Upload Image
                <input
                  type="file"
                  onChange={handleemployeephoto}
                  className="absolute left-0 right-0 top-0 bottom-0 opacity-0"
                />
              </div>
              <div className="text-xs text-black/70 mt-1 mx-1">
                .png ,.jpg file require{" "}
              </div>
            </div>
          </div>
          <div className="py-4">
            <div className="text-sm text-black/90 my-2">Employee Name</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                onChange={(e) => {
                  setEmployee_name(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Employee ID</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                onChange={(e) => {
                  setEmployee_id(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Designation</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                value={designation}
                onChange={(e) => {
                  setDesignation(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Phone Number</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                value={phone_number}
                onChange={(e) => {
                  setPhone_number(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Gender</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <select
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                className="text-sm w-full h-full  border-none bg-transparent  px-2 rounded-sm outline-none"
              >
                <option value="Male" className="font-serif">
                  Male
                </option>
                <option value="Female" className="font-serif">
                  Female
                </option>
                <option value="Other" className="font-serif">
                  Other
                </option>
              </select>
            </div>
            <div className="text-sm text-black/90 my-2">Care of</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                value={care_of}
                onChange={(e) => {
                  setCare_of(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Address</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Aadhar Number</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md  flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                onChange={(e) => {
                  setAadhar_number(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Aadhar Photo</div>
            <div className="text-sm  w-3/3 rounded-md flex">
              <div className="py-3 px-2 flex items-center mx-8">
                <div
                  className="w-16 h-16 bg-cover rounded-md"
                  style={{
                    backgroundImage: `url(https://saizoous.com/zoousbackend/employee/Aadhar/Upload/${aadhar_front})`,
                  }}
                ></div>
                <div className="mx-3">
                  <div className="relative py-1 px-2 flex items-center justify-center border border-black/20 rounded-md text-sm w-32">
                    Aadhar front
                    <input
                      type="file"
                      onChange={handleemployeefrontaadhar}
                      className="absolute left-0 right-0 top-0 bottom-0 opacity-0"
                    />
                  </div>
                  <div className="text-xs text-black/70 mt-1 mx-1">
                    .png ,.jpg file require{" "}
                  </div>
                </div>
              </div>
              <div className="py-3 px-2 flex items-center mx-8">
                <div
                  className="w-16 h-16 bg-cover rounded-md"
                  style={{
                    backgroundImage: `url(https://saizoous.com/zoousbackend/employee/Aadhar/Upload/${aadhar_back})`,
                  }}
                ></div>
                <div className="mx-3">
                  <div className="relative py-1 px-2 flex items-center justify-center border border-black/20 rounded-md text-sm w-32">
                    Aadhar back
                    <input
                      type="file"
                      onChange={handleemployeebackaadhar}
                      className="absolute left-0 right-0 top-0 bottom-0 opacity-0"
                    />
                  </div>
                  <div className="text-xs text-black/70 mt-1 mx-1">
                    .png ,.jpg file require{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-sm text-black/90 mb-2 mt-3">
              Date of Joining
            </div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                onChange={(e) => {
                  setDate_of_joining(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Date of Exit</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                onChange={(e) => {
                  setDate_of_exit(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Department</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                onChange={(e) => {
                  setDepartment(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Section Name</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <select
                value={section_name}
                onChange={(e) => {
                  setSection_name(e.target.value);
                }}
                className="text-sm w-full h-full section_container border-none bg-transparent  px-2 rounded-sm outline-none"
              >
                {sections.map((sections) => {
                  return (
                    <option
                      value={sections?.section_name}
                      // onClick={() => handleemployeebysection(sections)}
                      className={`
                      font-serif select-none text-sm py-2 px-4 border border-black/60 my-1 mx-1 rounded-md hover:text-white hover:bg-black cursor-pointer`}
                    >
                      {sections?.section_name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="text-sm text-black/90 my-2">Plant Name</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                onChange={(e) => {
                  setPlant_name(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Salary Per day</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                onChange={(e) => {
                  setBasic_salary(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Account Number</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                onChange={(e) => {
                  setAccount_number(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/80 mt-2">Passbook Photo</div>
            <div className="py-3 px-2 flex items-center">
              <div
                className="w-16 h-16 bg-cover rounded-md"
                style={{
                  backgroundImage: `url(https://saizoous.com/zoousbackend/employee/Passbook/Upload/${passbook_photo})`,
                }}
              ></div>
              <div className="mx-3">
                <div className="relative py-1 px-2 flex items-center justify-center border border-black/20 rounded-md text-sm w-32">
                  Upload Image
                  <input
                    type="file"
                    onChange={handleemployeepassbook}
                    className="w-full h-full absolute left-0 right-0 top-0 bottom-0 opacity-0"
                  />
                </div>
                <div className="text-xs text-black/70 mt-1 mx-1">
                  .png ,.jpg file require{" "}
                </div>
              </div>
            </div>
            <div className="text-sm text-black/90 my-2">Bank Name</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                onChange={(e) => {
                  setBank_name(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">IFSC Code</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                onChange={(e) => {
                  setIfsc_code(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Salary</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                onChange={(e) => {
                  setSalary(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">PF</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                onChange={(e) => {
                  setPf(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">ESI Number</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                onChange={(e) => {
                  setEsi_number(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">UAN Number</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                onChange={(e) => {
                  setUan_number(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Allowance</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                onChange={(e) => {
                  setAllowance(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">NCP</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                onChange={(e) => {
                  setNcp(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Incharge commision</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                onChange={(e) => {
                  setIncharge_commision(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
          </div>
          <div className="flex items-center justify-end">
            {loading ? (
              <div className="w-24 flex items-center justify-center h-11 border rounded-md border-black">
                <div className="loadingspinner border-4 border-t-black w-6 h-6 rounded-full"></div>
              </div>
            ) : (
              <div
                onClick={handlerequest}
                className="py-2 px-4 bg-black text-white text-sm rounded-md"
              >
                Save changes
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Addemployee;
