import React, { useEffect } from "react";
import Logo from "../../assets/image/SAI.png";
import { IoMdArrowDropdown } from "react-icons/io";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import {
  handleforminputs,
  handlepermission,
  handleppermission,
} from "./handlepermission";
import axios from "axios";
import { useParams } from "react-router";

function Adduser() {
  const dispatch = useDispatch();
  const { plant } = useParams();
  const user = useSelector((state) => state.user);
  const plants = useSelector((state) => state?.plants);
  const [username, setUsername] = useState("");
  const [useremail, setUseremail] = useState("");
  const [userphone, setUserphone] = useState("");
  const [userpassword, setUserpassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [validemail, setValidemail] = useState(false);
  const [employeeid, setEmployeeid] = useState("");

  const handlesubmit = async () => {
    let forminputs = await handleforminputs(
      username,
      useremail,
      userphone,
      userpassword,
      employeeid
    );
    if (forminputs) {
      let menupermissions = await handlepermission();
      let plantpermission = await handleppermission();
      let plantselection = document.getElementById("plantselection").value;
      let roleselection = document.getElementById("roleselection").value;

      if (validemail) {
        const form = new FormData();

        setLoading(true);
        form.append("username", username);
        form.append("email", useremail);
        form.append("id", employeeid);
        form.append("phone", userphone);
        form.append("role", roleselection);
        form.append("password", userpassword);
        form.append("plant", plantselection);
        form.append("plant_access", plantpermission.join(","));
        form.append("changedBy", user.Email);
        form.append("category", "useradding");
        form.append("detail", `trying to add ${username}`);
        form.append("menu_permission", menupermissions.join(","));

        const request = await axios
          .post("https://saizoous.com/zoousbackend/user/adduser.php", form)
          .then((result) => {
            setLoading(false);
            console.log(result);
            window.location.assign(`${plant}/user`);
          })
          .catch(() => {
            setLoading(false);
          });
        return request;
      }
    }
  };

  return (
    <div
      className="py-2 min-h-screen flex items-center flex-col"
      style={{
        maxWidth: "100vw",
        zIndex: "100000",
        backgroundColor: "white",
      }}
    >
      <div className="w-2/3">
        <div className="flex items-center justify-between w-full border-b border-b-black/30 py-4 px-2 sticky top-0 bg-white">
          <div
            className="text-lg"
            style={{
              fontFamily: `'Bruno Ace SC', cursive`,
            }}
          >
            Zoous
          </div>
          <div style={{}}>Logout</div>
        </div>{" "}
        <div className="px-20">
          <div className="py-4">
            <div
              className="font-semibold text-lg"
              style={{
                fontFamily: `'Rubik', sans-serif`,
              }}
            >
              Add User
            </div>
            <div className="text-sm text-black/90 mt-4 mb-2">Employee Id</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                onChange={(e) => {
                  setEmployeeid(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Name</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Email</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                onChange={async (e) => {
                  const emailvalid = await validator.isEmail(e.target.value);
                  if (emailvalid) {
                    console.log("yes");
                    setValidemail(true);
                  }
                  setUseremail(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Password</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                onChange={(e) => {
                  setUserpassword(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="text-sm text-black/90 my-2">Phone Number</div>
            <div
              className="text-sm border-black/70 w-3/3 rounded-md flex items-center justify-center"
              style={{
                height: "40px",
                borderWidth: 1.5,
              }}
            >
              <input
                onChange={(e) => {
                  setUserphone(e.target.value);
                }}
                type="text"
                className="h-full w-full rounded-md px-3 outline-none"
              />
            </div>
            <div className="flex items-start justify-start">
              <div className="w-1/2 mr-2">
                <div className="text-sm text-black/90 my-2">Role</div>
                <select
                  name=""
                  id="roleselection"
                  className="w-full border-solid border border-black/90 outline-none  rounded-md text-sm px-2"
                  style={{
                    height: "40px",
                  }}
                >
                  <option value="">Select Role</option>
                  <option value="Admin">Admin</option>
                  <option value="HR Admin">HR Admin</option>
                  <option value="Employee">Employee</option>
                  <option value="Accountant">Accountant</option>
                </select>
              </div>
              <div className="w-1/2 ml-2">
                <div className="text-sm text-black/90 my-2">Plant</div>
                <select
                  id="plantselection"
                  className="w-full border-solid border border-black/90 outline-none  rounded-md text-sm px-2"
                  style={{
                    height: "40px",
                  }}
                >
                  <option value="">Select Plant</option>
                  {/* {plants.map((plant) => ( */}
                  <option value="Mudhivarthi">Mudhivarthi</option>
                  {/* ))} */}
                </select>
              </div>
            </div>
          </div>
          <div className="my-2 border-y border-solid border-black/20 py-2">
            <div className="text-sm font-semibold font-[Inter] mt-2">
              Menu Permissions
            </div>
            <div className="w-full py-2 my-1 rounded-md relative select-none flex items-center flex-wrap text-xs font-semibold text-black border-black">
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input type="checkbox" id="ebiodata" className="mr-2 w-4 h-4" />
                <label htmlFor="ebiodata">EMPLOYEE BIODATA</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input
                  type="checkbox"
                  id="inchargebiodata"
                  className="mr-2 w-4 h-4"
                />
                <label htmlFor="inchargebiodata">INCHARGE BIODATA</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input
                  type="checkbox"
                  id="guestbiodata"
                  className="mr-2 w-4 h-4"
                />
                <label htmlFor="guestbiodata">GUEST BIODATA</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input
                  type="checkbox"
                  id="giveattend"
                  className="mr-2 w-4 h-4"
                />
                <label htmlFor="giveattend">GIVE ATTENDANCE</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input
                  type="checkbox"
                  id="reportattend"
                  className="mr-2 w-4 h-4"
                />
                <label htmlFor="reportattend">ATTENDNACE REPORT</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input type="checkbox" id="WBSTATE" className="mr-2 w-4 h-4" />
                <label htmlFor="WBSTATE">WORK & BANK STATEMENT</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input type="checkbox" id="iwreport" className="mr-2 w-4 h-4" />
                <label htmlFor="iwreport">INDIVIDUAL WORK REPORT</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input type="checkbox" id="salary" className="mr-2 w-4 h-4" />
                <label htmlFor="salary">Salary</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input type="checkbox" id="pf" className="mr-2 w-4 h-4" />
                <label htmlFor="pf">PF</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input type="checkbox" id="adduser" className="mr-2 w-4 h-4" />
                <label htmlFor="adduser">ADD USER</label>
              </div>

              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input
                  type="checkbox"
                  id="searchuser"
                  className="mr-2 w-4 h-4"
                />
                <label htmlFor="searchuser">SEARCH USER</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input type="checkbox" id="addprice" className="mr-2 w-4 h-4" />
                <label htmlFor="addprice">ADD PRICE</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input type="checkbox" id="addplant" className="mr-2 w-4 h-4" />
                <label htmlFor="addplant">ADD PLANT</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input type="checkbox" id="npfuser" className="mr-2 w-4 h-4" />
                <label htmlFor="npfuser"> NON PF USERS</label>
              </div>
            </div>
          </div>
          <div className="my-2 border-b border-solid border-black/20 py-2">
            <div className="text-sm font-semibold font-[Inter]">
              Plant Permissions
            </div>
            <div className="w-full py-2 my-1 rounded-md relative select-none flex items-center flex-wrap text-xs font-semibold text-black border-black">
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input
                  type="checkbox"
                  id="MUDHIVARTHI"
                  className="mr-2 w-4 h-4"
                />
                <label htmlFor="MUDHIVARTHI">MUDHIVARTHI</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input
                  type="checkbox"
                  id="Krishnapurama"
                  className="mr-2 w-4 h-4"
                />
                <label htmlFor="Krishnapurama">Krishnapuram</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input
                  type="checkbox"
                  id="Iskapalli"
                  className="mr-2 w-4 h-4"
                />
                <label htmlFor="Iskapalli">Iskapalli</label>
              </div>
              <div className="flex items-center w-1/2 py-2 px-1 text-sm">
                <input type="checkbox" id="Vizag" className="mr-2 w-4 h-4" />
                <label htmlFor="Vizag">Vizag</label>
              </div>
            </div>
          </div>
          {loading ? (
            <div className="flex items-center justify-end pb-3 pt-2">
              <div className="loadingspinner border-b-4 border-b-black w-8 h-8 rounded-full border-4"></div>
            </div>
          ) : (
            <div className="flex items-center justify-end pb-3 pt-2">
              <div
                onClick={handlesubmit}
                className="py-2 px-4 bg-black text-white text-sm rounded-md"
              >
                Save changes
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Adduser;
